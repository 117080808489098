import { useUser } from 'contexts/user';
import { userCanAccessProviderEndpoint } from 'utils/roles';
import {
  DataImportFile,
  DataImportFileType,
  DataImportStatus,
} from 'types/data-import';
import { TFunction, useTranslation } from 'react-i18next';
import React from 'react';
import { Label, Loader, SemanticCOLORS } from 'semantic-ui-react';
import { FeatureFlags, useFeatures } from 'contexts/features';
import styled from 'styled-components';
import { Icon } from 'semantic';
import {
  DataImportFileRow,
  DataImportFileRowStatus,
} from 'screens/DataImports/Form/DataImportFileRow';

/** FE paths */
export const DATA_IMPORTS_FE_PATH = `/data-imports`;
export const ACCOUNTS_FE_PATH = `/accounts`;
export const USERS_FE_PATH = `/accounts/users`;
export const CPO_LOCATIONS_FE_PATH = `/charging-stations/locations`;
export const CHARGING_STATIONS_FE_PATH = `/charging-stations`;
export const TOKENS_FE_PATH = `/cards/tokens`;

/** API paths */
export const DATA_IMPORTS_BE_PATH = `/1/data-imports`;
export const DATA_IMPORTS_BE_SEARCH_PATH = `/1/data-imports/search`;

/** Permissions */
export const DATA_IMPORTS_PERMISSION = 'dataImport';

export const hasDataImportPermissions = (
  requiredLevel: 'read' | 'write'
): boolean => {
  const { hasFeature } = useFeatures();
  const { user, provider } = useUser();

  if (!user) {
    return false;
  }

  return (
    hasFeature(FeatureFlags.DataImports) &&
    userCanAccessProviderEndpoint(
      user,
      provider,
      DATA_IMPORTS_PERMISSION,
      requiredLevel
    )
  );
};

export const renderDataImportFileType = (
  t: TFunction,
  fileType: DataImportFileType
): string => {
  switch (fileType) {
    case 'accounts':
      return t('dataImports.fileTypeAccounts', 'Accounts');
    case 'users':
      return t('dataImports.fileTypeUsers', 'Users');
    case 'locations':
      return t('dataImports.fileTypeLocations', 'Locations');
    case 'evses':
      return t('dataImports.fileTypeEvses', 'Charging Stations');
    case 'connectors':
      return t('dataImports.fileTypeConnectors', 'Connectors');
    case 'virtual-cards':
      return t('dataImports.fileTypeVirtualCards', 'Virtual Cards');
    default:
      return fileType;
  }
};

export const StatusLabel: React.FC<{ status: string }> = ({ status }) => {
  const { t } = useTranslation();

  let labelColor: SemanticCOLORS = 'grey';
  let labelText = t('dataImports.statusUnknown', 'Unknown');

  switch (status) {
    case DataImportStatus.Completed:
      labelColor = 'olive';
      labelText = t('dataImports.statusCompleted', 'Completed');
      break;
    case DataImportStatus.InProgress:
      labelColor = 'blue';
      labelText = t('dataImports.statusInProgress', 'In Progress');
      break;
    case DataImportStatus.Pending:
      labelColor = 'yellow';
      labelText = t('dataImports.statusPending', 'Pending');
      break;
    case DataImportStatus.PartlyCompleted:
      labelColor = 'orange';
      labelText = t('dataImports.statusPartlyCompleted', 'Partly Completed');
      break;
  }

  return <Label color={labelColor}>{labelText}</Label>;
};

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: #2a2a2a;
  background-color: #f2f7fe;
  padding: 12px;
`;

export function DataImportFileLabel({
  children,
}: {
  children: React.ReactNode;
}) {
  return <StyledLabel>{children}</StyledLabel>;
}

export const DataImportFileStatus: React.FC<{
  status?: DataImportFileRowStatus;
}> = ({ status }) => {
  switch (status) {
    case 'pending':
    case 'validating':
      return <Loader active inline size="small" />;
    case 'completed':
      return <Icon size="big" name="circle-check regular" color="green" />;
    case 'failed':
      return <Icon size="big" name="circle-cancel regular" color="red" />;
    default:
      return null;
  }
};

export const dataTypeTitle = (
  t: TFunction,
  type: DataImportFileType
): string | undefined => {
  switch (type) {
    case 'accounts':
      return t('dataImports.objectTypeAccounts', 'Accounts');
    case 'users':
      return t('dataImports.objectTypeUsers', 'Users');
    case 'locations':
      return t('dataImports.objectTypeLocations', 'Locations');
    case 'evses':
      return t('dataImports.objectTypeChargingStations', 'Charging Stations');
    case 'connectors':
      return t('dataImports.objectTypeConnectors', 'EVSE Connectors');
    case 'virtual-cards':
      return t('dataImports.objectTypeVirtualCards', 'Virtual Cards');
  }
};

export const dataImportFileComputedStatus = (file: DataImportFile): string => {
  if (
    file.fileMetrics?.statusesCount?.validated === file.fileMetrics?.totalCount
  ) {
    return 'completed';
  } else {
    return 'failed';
  }
};
