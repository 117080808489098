import React from 'react';

import { formatCurrency } from 'utils/formatting';
import InvoiceTypeLabel from 'components/InvoiceTypeLabel';
import InvoiceStatusLabel from 'components/InvoiceStatusLabel';

import { Table, Button } from 'semantic';
import { request, getFilePath } from 'utils/api';

import { LoadButton, Search } from 'components';
import { withTranslation } from 'react-i18next';

class MyCreditInvoicesTable extends React.Component {
  static contextType = Search.Context;

  render() {
    const { t } = this.props;
    const { items, loading } = this.context;

    if (!items.length || loading) return null;

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>
              {t('invoices.tableColumnType', 'Type')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('invoices.tableColumnDate', 'Date')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('invoices.tableColumnTotal', 'Total')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('invoices.tableColumnStatus', 'Status')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('invoices.tableColumnAction', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <InvoiceTypeLabel invoice={item} />
                </Table.Cell>
                <Table.Cell>
                  {item.year} / {item.month}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {item.type === 'cpo-credit'
                    ? formatCurrency(item.totalCreditAmount, {
                        currency: item.currency,
                      })
                    : formatCurrency(item.totalAmountWithVat, {
                        currency: item.currency,
                      })}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <InvoiceStatusLabel invoice={item} />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    icon="file-pdf"
                    title="Download"
                    basic
                    href={getFilePath(
                      `/1/invoices/${item.id}/pdf?accessToken=${item.accessToken}`
                    )}
                    as="a"
                    disabled={!item.isReady}
                  />
                  <LoadButton
                    basic
                    onClick={async () => {
                      return request({
                        path: `/1/invoices/mine/${item.id}/sessions/download`,
                        method: 'GET',
                        params: {
                          filename: `${item.identifier}-sessions.csv`,
                        },
                      });
                    }}
                    icon="file-csv"
                    title="Download Sessions"
                    disabled={item.type === 'cpo-usage'}
                  />
                  <LoadButton
                    basic
                    onClick={async () => {
                      return request({
                        path: `/1/invoices/${item.id}/billable-items/export`,
                        method: 'GET',
                        params: {
                          filename: `${item.identifier}-sessions.csv`,
                        },
                      });
                    }}
                    icon="file-csv"
                    title="Download Billable Items"
                    disabled={item.billingEngineVersion < 2}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

export default withTranslation()(MyCreditInvoicesTable);
