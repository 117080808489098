import React, { CSSProperties, useMemo, useState } from 'react';
import { Breadcrumbs, ListHeader } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DATA_IMPORTS_BE_PATH,
  DATA_IMPORTS_FE_PATH,
  dataImportFileComputedStatus,
} from 'screens/DataImports/utils';
import {
  ActionButton,
  Button,
  Divider,
  Header,
  HeaderSubheader,
  Icon,
} from 'semantic';
import Segment from 'components/semantic/Segment';
import Dropzone, { FileError, FileRejection } from 'react-dropzone';
import {
  DataImport,
  DataImportFile,
  DataImportFileType,
} from 'types/data-import';
import { Form, FormInput, Message, Table, TableBody } from 'semantic-ui-react';
import DataImportFileRow, {
  DataImportFileRowItem,
} from 'screens/DataImports/Form/DataImportFileRow';
import { request } from 'utils/api';
import DataImportFileValidatedRow from 'screens/DataImports/Form/DataImportFileValidatedRow';
import { useToast } from 'components/Toast';

const dropzoneStyles: CSSProperties = {
  cursor: 'pointer',
  outline: 0,
  textAlign: 'center',
  height: '10em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const dropzoneErrorStyles: CSSProperties = {
  border: '1px dashed red',
};

const maxFileSize = 100 * 1024 * 1024; // 100MB

type DataImportFormProps = {
  dataImport: DataImport;
  refresh: () => void;
};

enum StateStepDefinition {
  PreValidation = 'pre-validation',
  ReadyForValidation = 'ready-for-validation',
  InProgressValidation = 'in-progress-validation',
  ValidationFailed = 'validation-failed',
  ValidationCompleted = 'validation-completed',
  InProgressImport = 'in-progress-import',
}

export default function DataImportForm({
  dataImport,
  refresh,
}: DataImportFormProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const [files, setFiles] = useState<DataImportFileRowItem[]>(
    dataImport.files
      ?.filter((file) => !isDataFileValidationPerformedFilter(file))
      .map(
        (file) =>
          ({
            id: file.id,
            type: file.type,
            originalFilename: file.originalFilename,
            status: 'completed', // if file is already attached, we assume it's completed for this stage
          }) as DataImportFileRowItem
      ) || []
  );
  const [filesValidated, setFilesValidated] = useState<DataImportFileRowItem[]>(
    dataImport.files?.filter(isDataFileValidationPerformedFilter).map(
      (file) =>
        ({
          id: file.id,
          type: file.type,
          originalFilename: file.originalFilename,
          fileMetrics: file.fileMetrics,
          status: dataImportFileComputedStatus(file),
        }) as DataImportFileRowItem
    ) || []
  );
  const [showDropzone, setShowDropzone] = useState<boolean>(
    files.length === 0 && filesValidated.length === 0
  );
  const [fileErrors, setFileErrors] = useState<FileError[]>([]);
  const [note, setNote] = useState<string>(dataImport.note || '');

  /** Update data import note */
  const updateDataImport = async () => {
    if (note === dataImport.note) return; // nothing changed

    try {
      await request({
        method: 'PATCH',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}`,
        body: { note },
      });

      dataImport.note = note;
    } catch (err: any) {
      toast.error(
        <div style={{ paddingRight: 10, lineHeight: '1em' }}>
          <Trans
            i18nKey="dataImports.errorUpdateDataImportToastDesc"
            defaults="Failed to update Data Import: {{error}}."
            values={{ error: err.message }}
          />
        </div>
      );
    }
  };

  /**
   * Determine the current state step based on files and filesValidated
   */
  const stateStep = useMemo<StateStepDefinition>(() => {
    if (files.length > 0) {
      if (files.every((file) => file.status === 'completed' && file.id)) {
        return StateStepDefinition.ReadyForValidation;
      } else if (files.some((file) => file.status === 'validating')) {
        return StateStepDefinition.InProgressValidation;
      } else {
        return StateStepDefinition.PreValidation;
      }
    } else if (filesValidated.length > 0) {
      if (filesValidated.every((f) => f.status === 'completed')) {
        return StateStepDefinition.ValidationCompleted;
      } else if (filesValidated.some((f) => f.status === 'pending')) {
        return StateStepDefinition.InProgressImport;
      } else {
        return StateStepDefinition.ValidationFailed;
      }
    } else {
      return StateStepDefinition.PreValidation;
    }
  }, [files, filesValidated]);

  /**
   * Handle file dropzpne event
   * @param acceptedFiles
   * @param rejectedFiles
   */
  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    setFiles([
      ...files,
      ...acceptedFiles.map((file: File) => ({
        rawFile: file,
        originalFilename: file.name,
      })),
    ]);
    setFileErrors(rejectedFiles.map((file) => file.errors).flat());
    setShowDropzone(acceptedFiles.length < 1 || rejectedFiles.length > 0);
  };

  /**
   * Handle file open event when local validation fails
   * @param index
   */
  const onFileOpen = (index: number) => {
    setFiles(
      files.map((file, i) => {
        if (i !== index) return file;

        return {
          ...file,
          isOpen: !file.isOpen,
        };
      })
    );
  };

  /**
   * Handle file delete event
   * @param index
   * @param items
   * @param setItems
   */
  // Reusable delete function
  const onFileDelete = async (
    index: number,
    items: DataImportFileRowItem[],
    setItems: React.Dispatch<React.SetStateAction<DataImportFileRowItem[]>>
  ) => {
    const updatedFile = items[index];

    if (!updatedFile) return; // shouldn't happen, added for safety

    try {
      // set status to pending to show that the file is being deleting/detaching from import
      if (updatedFile.id) {
        setItems((prevItems) =>
          prevItems.map((file) => {
            if (file.id !== updatedFile.id) return file;

            return {
              ...file,
              status: 'pending',
            };
          })
        );

        await request({
          method: 'DELETE',
          path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}/files/${updatedFile.id}`,
        });
      }

      // we get fresh state after API call
      setItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    } catch (err: any) {
      toast.error(
        <div style={{ paddingRight: 10, lineHeight: '1em' }}>
          <Trans
            i18nKey="dataImports.errorDeleteDataImportFileToastDesc"
            defaults="Failed to delete Data Import File: {{error}}."
            values={{ error: err.message }}
          />
        </div>
      );
    }
  };

  /**
   * Handle object type change event and send request to perform local validation
   * @param index
   * @param type
   */
  const onObjectTypeChange = async (
    index: number,
    type: DataImportFileType
  ) => {
    const updatedFile = files[index];

    if (!updatedFile) return; // shouldn't happen, added for safety

    // set status to pending to show that the file is being validated
    updateFileByIndex(index, {
      type,
      status: 'pending',
    });

    try {
      const { data } = await request({
        method: 'POST',
        path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}/files`,
        body: {
          type,
        },
        files: [updatedFile.rawFile],
      });

      updateFileByIndex(index, {
        id: data?.id,
        status: 'completed',
      });
    } catch (err: any) {
      updateFileByIndex(index, {
        status: 'failed',
        localValidationError: err.message,
      });
    }
  };

  /**
   * Handle data validation of files
   */
  const onDataValidation = async () => {
    // set in pending state
    setFiles(
      files.map((file) => {
        return {
          ...file,
          status: 'validating',
        };
      })
    );

    await request({
      method: 'POST',
      path: `${DATA_IMPORTS_BE_PATH}/${dataImport.id}/validate`,
    })
      .catch(() => {}) // ignore errors as page will be reloaded with new data
      .finally(refresh);
  };

  /**
   * Handle data validation of files
   */
  const onDataImport = async () => {
    // set in pending state
    setFilesValidated(
      filesValidated.map((file) => {
        return {
          ...file,
          status: 'pending',
        };
      })
    );

    // TODO implement real import call
    setTimeout(refresh, 3000);
  };

  /**
   * Update file by index
   * @param index
   * @param data
   */
  const updateFileByIndex = (
    index: number,
    data: Partial<DataImportFileRowItem>
  ) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = {
        ...updatedFiles[index],
        ...data,
      };
      return updatedFiles;
    });
  };

  return (
    <>
      <Breadcrumbs
        path={[
          <Link to={DATA_IMPORTS_FE_PATH}>
            {t('dataImports.breadcrumbs', 'Data Import')}
          </Link>,
        ]}
        active={t('dataImports.breadcrumbsNewDataImport', 'New Data Import')}
      />
      <div style={{ marginTop: 0, marginBottom: 20 }}>
        <ListHeader
          title={t('dataImports.titleNewDataImport', 'New Data Import')}
        />
      </div>
      <Divider hidden />
      <Header as="h4">
        {t('dataImports.inputNote', '1. Add import description')}
        <HeaderSubheader style={{ marginTop: '0.8em' }}>
          {t(
            'dataImports.inputNoteDescription',
            'Write a optional description to show together with the details of this data import  to easily identify it later.'
          )}
        </HeaderSubheader>
      </Header>
      <Segment>
        <Form>
          <FormInput
            placeholder={t(
              'dataImports.descriptionPlaceholder',
              'Type here...'
            )}
            type="input"
            label={t('dataImports.descriptionLabel', 'Description')}
            value={note}
            onChange={(e, { value }) => setNote(value)}
            onBlur={updateDataImport}
          />
        </Form>
      </Segment>
      <Divider hidden />
      <Header as="h4">
        {t('dataImports.uploadFile', '2. Upload data import file')}
        <HeaderSubheader style={{ marginTop: '0.8em' }}>
          {t(
            'dataImports.uploadFileDescription',
            'Choose the CSV files you want to use for data import. You can add multiple files in one go.'
          )}
        </HeaderSubheader>
      </Header>
      <Segment>
        {filesValidated.length > 0 && (
          <Table padded basic>
            <TableBody>
              {filesValidated.map((file, index) => (
                <DataImportFileValidatedRow
                  key={index}
                  index={index}
                  file={file}
                  onOpen={() => onFileOpen(index)}
                  onDelete={() =>
                    onFileDelete(index, filesValidated, setFilesValidated)
                  }
                />
              ))}
            </TableBody>
          </Table>
        )}
        {files.length > 0 && (
          <Table padded basic>
            <TableBody>
              {files.map((file, index) => (
                <DataImportFileRow
                  key={index}
                  index={index}
                  file={file}
                  onOpen={() => onFileOpen(index)}
                  onDelete={() => onFileDelete(index, files, setFiles)}
                  onObjectTypeChange={(type: DataImportFileType) =>
                    onObjectTypeChange(index, type)
                  }
                />
              ))}
            </TableBody>
          </Table>
        )}
        {(showDropzone ||
          (files.length === 0 && filesValidated.length === 0)) && (
          <Dropzone
            maxSize={maxFileSize}
            onDrop={onDrop}
            accept={['text/csv', 'application/vnd.ms-excel']}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={
                    isDragActive
                      ? 'ui icon blue message upload-dropzone-active'
                      : 'ui icon message upload-dropzone'
                  }
                  style={{
                    ...dropzoneStyles,
                    ...(fileErrors.length > 0 && dropzoneErrorStyles),
                  }}>
                  <input {...getInputProps()} />
                  <div className="content">
                    <Icon name="upload" />
                    <span>
                      {t(
                        'dataImports.dropzone',
                        'Drag and drop your files here, or click to select files to upload.'
                      )}
                    </span>
                  </div>
                </div>
              );
            }}
          </Dropzone>
        )}
        {fileErrors.length > 0 && (
          <Message error>
            {fileErrors.map((error) => error.message).join(', ')}
          </Message>
        )}
        {!showDropzone && (
          <>
            <Divider hidden />
            <ActionButton
              style={{ paddingLeft: 0 }}
              primary
              compact
              onClick={() => setShowDropzone(true)}>
              <>
                <Icon name="plus" />
                {t('dataImports.btnUploadMoreFiles', 'Upload more files')}
              </>
            </ActionButton>
          </>
        )}
        {[
          StateStepDefinition.ReadyForValidation,
          StateStepDefinition.PreValidation,
        ].includes(stateStep) && (
          <>
            <Divider />
            {stateStep === StateStepDefinition.ReadyForValidation && (
              <Message success>
                <p>
                  <b>
                    {t(
                      'dataImports.dataValidationReadyTitle',
                      'Files are ready for Data validation!'
                    )}
                  </b>
                </p>
                <p>
                  {t(
                    'dataImports.dataValidationReadyDesc',
                    'Uploaded files passed the check for the file and content format. You can start the data validation.'
                  )}
                </p>
              </Message>
            )}
            {stateStep === StateStepDefinition.PreValidation && (
              <Message
                size="tiny"
                info
                content={t(
                  'dataImports.dataValidationDesc',
                  'To perform data validation, upload you import files, define the object type for each file, then update or remove any files that did not pass the format check.'
                )}
              />
            )}

            <Button
              style={{ marginLeft: 0 }}
              content={t('dataImports.btnDataValidation', 'Data Validation')}
              icon="play"
              type="submit"
              disabled={stateStep !== StateStepDefinition.ReadyForValidation}
              onClick={onDataValidation}
              loading={stateStep === StateStepDefinition.InProgressValidation}
            />
          </>
        )}
      </Segment>
      {stateStep === StateStepDefinition.InProgressValidation && (
        <Message
          warning
          content={t(
            'dataImports.dataValidationInProgress',
            'Data validation has been started. This may take a few moments. Please stay on this page until the process is complete.'
          )}
        />
      )}
      {[
        StateStepDefinition.ValidationCompleted,
        StateStepDefinition.InProgressImport,
      ].includes(stateStep) && (
        <>
          <Message success>
            <p>
              <b>
                {t(
                  'dataImports.dataValidationCompletedTitle',
                  'Validation completed!'
                )}
              </b>
            </p>
            <p>
              {t(
                'dataImports.dataValidationCompletedDesc',
                'Data validation for uploaded files successfully accomplished. The data can now be imported.'
              )}
            </p>
          </Message>
          <Divider />
          <Button
            style={{ marginLeft: 0 }}
            content={t('dataImports.btnImportData', 'ImportData')}
            type="submit"
            onClick={onDataImport}
            loading={stateStep === StateStepDefinition.InProgressImport}
          />
          <Link to={DATA_IMPORTS_FE_PATH}>
            <Button
              content={t('dataImports.cancelButton', 'Cancel')}
              basic
              type="button"
            />
          </Link>
        </>
      )}
      {stateStep === StateStepDefinition.ValidationFailed && (
        <Message info>
          <Trans key="dataImports.dataValidationFailed">
            <p>
              To proceed with a data import you should remove all files with
              invalid data first. Also, you can upload more files and run the
              data check again.
            </p>
            <p>
              To review the errors you can export each file with a status per
              item or export errors only - errors can be exported for all
              uploaded files or per single file.
            </p>
          </Trans>
        </Message>
      )}
      {![
        StateStepDefinition.ValidationCompleted,
        StateStepDefinition.InProgressImport,
      ].includes(stateStep) && (
        <>
          <Divider />
          <Link to={DATA_IMPORTS_FE_PATH}>
            <Button
              content={t('dataImports.cancelButton', 'Cancel')}
              basic
              type="button"
            />
          </Link>
        </>
      )}
    </>
  );
}

const isDataFileValidationPerformedFilter = (file: DataImportFile) => {
  return (
    file.fileMetrics.totalCount > 0 &&
    file.fileMetrics.statusesCount.pending === 0
  );
};
