import React from 'react';
import { request } from 'utils/api';

import { Form, Message, Header, Segment, Label, Button } from 'semantic';

import {
  connectorTypeOptions,
  connectorPowerTypeOptions,
  connectorFormatOptions,
} from 'utils/locations';

import { currentUserCanAccessProviderEndpoint } from 'utils/roles';

function determineDefaultsByConfiguration(configuration = [], connectorId) {
  const defaults = {};
  const config = {};
  configuration.forEach((item) => {
    config[item.key] = item.value;
  });
  const connectorType = config[`Connector${connectorId}-Type`];
  if (connectorType === 'Fixed cable') {
    defaults.standard = 'IEC_62196_T2';
    defaults.format = 'CABLE';
  }
  if (connectorType === 'Type 2 socket') {
    defaults.standard = 'IEC_62196_T2';
    defaults.format = 'SOCKET';
  }
  if (connectorType === 'Type 3 socket') {
    defaults.format = 'SOCKET';
  }
  if (connectorType === 'Type-1 fixed cable') {
    defaults.standard = 'IEC_62196_T1';
    defaults.format = 'CABLE';
  }
  if (connectorType === 'Type-2 fixed cable') {
    defaults.standard = 'IEC_62196_T2';
    defaults.format = 'CABLE';
  }
  if (connectorType === 'Type-3 fixed cable') {
    defaults.format = 'CABLE';
  }
  if (connectorType === 'Schuko socket') {
    defaults.standard = 'DOMESTIC_F';
    defaults.format = 'SOCKET';
  }
  const connectorMaxCurrent = config[`Connector${connectorId}-MaxCurrent`];
  if (connectorMaxCurrent) {
    defaults.maxAmperage = parseFloat(connectorMaxCurrent, 10);
  }
  if (defaults.standard === 'IEC_62196_T2' && !defaults.powerType) {
    defaults.powerType = 'AC_3_PHASE';
  }
  if (defaults.standard === 'IEC_62196_T2' && !defaults.maxVoltage) {
    defaults.maxVoltage = 230;
  }
  if (defaults.standard === 'IEC_62196_T2_COMBO' && !defaults.powerType) {
    defaults.powerType = 'DC';
  }
  if (defaults.standard === 'IEC_62196_T2_COMBO' && !defaults.maxVoltage) {
    defaults.maxVoltage = 250;
  }
  return defaults;
}

const rules = [
  {
    query: {
      powerType: 'AC_1_PHASE',
    },
    values: {
      maxVoltage: {
        max: 230,
        min: 0,
      },
      maxAmperage: {
        max: 32,
        min: 0,
      },
      standard: [
        'IEC_62196_T1',
        'IEC_62196_T2',
        'DOMESTIC_F',
        'IEC_62196_T3A',
        'IEC_62196_T3C',
      ],
    },
  },
  {
    query: {
      powerType: 'AC_1_PHASE',
      standard: 'IEC_62196_T1',
    },
    values: {
      format: ['CABLE'],
      maxVoltage: {
        max: 230,
        min: 100,
      },
    },
  },
  {
    query: {
      powerType: 'AC_1_PHASE',
      standard: 'IEC_62196_T2',
    },
    values: {
      format: ['CABLE', 'SOCKET'],
    },
  },
  {
    query: {
      powerType: 'AC_1_PHASE',
      standard: 'DOMESTIC_F',
    },
    values: {
      format: ['SOCKET'],
      maxVoltage: {
        max: 230,
        min: 0,
      },
      maxAmperage: {
        max: 32,
        min: 0,
      },
    },
  },
  {
    query: {
      powerType: 'AC_1_PHASE',
      standard: 'IEC_62196_T3A',
    },
    values: {
      format: ['CABLE', 'SOCKET'],
      maxVoltage: {
        max: 230,
        min: 100,
      },
      maxAmperage: {
        max: 64,
        min: 0,
      },
    },
  },
  {
    query: {
      powerType: 'AC_1_PHASE',
      standard: 'IEC_62196_T3C',
    },
    values: {
      format: ['CABLE', 'SOCKET'],
      maxVoltage: {
        max: 230,
        min: 100,
      },
      maxAmperage: {
        max: 64,
        min: 0,
      },
    },
  },
  {
    query: {
      powerType: 'AC_3_PHASE',
    },
    values: {
      standard: ['IEC_62196_T1', 'IEC_62196_T2', 'IEC_62196_T3C'],
    },
  },
  {
    query: {
      powerType: 'AC_3_PHASE',
      standard: 'IEC_62196_T1',
    },
    values: {
      format: ['CABLE'],
      maxVoltage: {
        max: 230,
        min: 100,
      },
      maxAmperage: {
        max: 64,
        min: 0,
      },
    },
  },
  {
    query: {
      powerType: 'AC_3_PHASE',
      standard: 'IEC_62196_T2',
    },
    values: {
      format: ['CABLE', 'SOCKET'],
      maxVoltage: {
        max: 230,
        min: 100,
      },
      maxAmperage: {
        max: 64,
        min: 0,
      },
    },
  },
  {
    query: {
      powerType: 'AC_3_PHASE',
      standard: 'IEC_62196_T3C',
    },
    values: {
      format: ['CABLE', 'SOCKET'],
      maxVoltage: {
        max: 230,
        min: 100,
      },
      maxAmperage: {
        max: 64,
        min: 0,
      },
    },
  },
  {
    query: {
      powerType: 'DC',
    },
    values: {
      standard: ['CHADEMO', 'IEC_62196_T2_COMBO'], //missing MCS which i dont know what is?
      format: ['CABLE'],
      maxVoltage: {
        min: 400,
        max: 900,
      },
      maxAmperage: {
        max: 440,
        min: 0,
      },
    },
  },
];

const connectorValidOptions = {
  standard: connectorTypeOptions,
  format: connectorFormatOptions,
  powerType: connectorPowerTypeOptions,
};

function applyRules(values) {
  let result = values;
  let validOptions = { ...connectorValidOptions };
  rules.forEach((rule) => {
    const match = Object.keys(rule.query).every((key) => {
      return result[key] === rule.query[key];
    });
    if (!match) return;

    const applied = { ...values };
    Object.keys(rule.values).forEach((key) => {
      // filter out any multiple choice, setting the field to undefined
      if (Array.isArray(rule.values[key])) {
        if (rule.values[key].length == 0) {
          applied[key] = rule.values[key][0];
        }
        if (!rule.values[key].includes(result[key])) {
          applied[key] = null;
        }
        validOptions[key] = validOptions[key].filter(({ value }) =>
          rule.values[key].includes(value)
        );
        // deal with min/max values
      } else if (rule.values[key].min || rule.values[key].max) {
        validOptions[key] = rule.values[key];
      } else {
        applied[key] = rule.values[key];
      }
    });
    result = { ...result, ...applied };
  });
  return { validOptions, values: result };
}

export default class ConfigureConnectors extends React.Component {
  static defaultProps = {
    onSave: () => {},
    onLoading: () => {},
    submit: () => {},
    formId: 'ConfigureConnectors',
  };

  state = {
    formValues: this.getFormValues(this.props.evseController),
    loading: false,
    validOptions: {},
  };

  componentDidMount() {
    const formValues = this.getFormValues(this.props.evseController);
    const validOptions = {};
    formValues.connectors.forEach((connector, index) => {
      const ruleResult = applyRules(formValues.connectors[index]);
      validOptions[index] = ruleResult.validOptions;
    }, {});
    this.setState({
      formValues,
      validOptions,
    });
  }

  getFormValues(evseController) {
    const connectors = (evseController.connectors || []).map((connector) => {
      return {
        ...connector,
      };
    });
    const { connectorStatus } = evseController;
    if (connectorStatus) {
      const statusBasedConnectorIds = Object.keys(connectorStatus).filter(
        (connectorId) => connectorId !== '0'
      );
      connectors.forEach((connector, index) => {
        connector.connectorId = parseInt(statusBasedConnectorIds[index], 10);
      });
    }
    connectors.forEach((connector) => {
      const detectedDefaults = determineDefaultsByConfiguration(
        evseController.configuration,
        connector.connectorId
      );
      Object.keys(detectedDefaults).forEach((key) => {
        if (!connector[key]) {
          connector[key] = detectedDefaults[key];
        }
      });
    });
    return {
      evseId: evseController.evseId,
      connectors,
    };
  }

  onSubmit = async () => {
    const { evseController } = this.props;
    this.props.onLoading(true);
    this.setState({
      error: null,
    });

    const body = this.state.formValues;

    try {
      await request({
        method: 'PATCH',
        path: `/1/evse-controllers/${evseController.id}/connectors`,
        body: {
          evseId: body.evseId,
          connectors: body.connectors.map((connector) => ({
            ...connector,
            connectorId: connector.connectorId.toString(),
          })),
        },
      });
      this.props.onSave();
    } catch (error) {
      this.setState({ error });
    } finally {
      this.props.onLoading(false);
    }
  };

  setField(index, name, value) {
    const { formValues } = this.state;
    formValues.connectors[index][name] = value;
    const { values, validOptions } = applyRules(formValues.connectors[index]);

    formValues.connectors[index] = values;

    this.setState({
      submitted: false,
      formValues: formValues,
      validOptions: {
        [index]: validOptions,
      },
    });
  }

  getNumberMessage(options = {}, value, label) {
    if (!options.min && !options.max) {
      return null;
    }

    //is value within range?
    const isWithInRange = options.min <= value && value <= options.max;

    const message = [
      `${label} should be between ${options.min} and ${options.max}`,
    ];

    return <Message error={!isWithInRange}>{message}</Message>;
  }

  renderConnector(connector, index, connectorStatus) {
    const connectorIdOptions = Object.keys(connectorStatus).map(
      (connectorId) => {
        return {
          key: connectorId,
          value: parseInt(connectorId, 10),
          text: `Connector ${connectorId}`,
        };
      }
    );

    const {
      connectorId,
      evseId,
      standard,
      format,
      powerType,
      maxVoltage,
      maxAmperage,
    } = connector;

    const validOptions =
      this.state.validOptions[index] || connectorValidOptions;

    return (
      <div key={connectorId} style={{ marginBottom: '20px' }}>
        <Header as="h3" content={`Connector ${connectorId}`} />
        <Segment>
          {!this.props.hideEvseId && (
            <Form.Input
              value={evseId}
              name="evseId"
              label="Connector EVSE ID"
              readOnly={true}
              type="text">
              <input style={{ backgroundColor: 'rgba(192,192,192,.25)' }} />
            </Form.Input>
          )}

          <Form.Select
            value={connectorId}
            options={connectorIdOptions}
            name="connectorId"
            label="Connector ID"
            type="text"
            onChange={(e, { name, value }) => this.setField(index, name, value)}
          />

          <Form.Select
            value={powerType}
            options={validOptions.powerType}
            required
            name="powerType"
            label="Power Type"
            type="text"
            onChange={(e, { name, value }) => this.setField(index, name, value)}
          />

          <Form.Select
            value={standard}
            options={validOptions.standard}
            name="standard"
            label="Connector Type / Standard"
            type="text"
            onChange={(e, { name, value }) => this.setField(index, name, value)}
          />

          <Form.Select
            value={format}
            options={validOptions.format}
            name="format"
            label="Format"
            type="text"
            onChange={(e, { name, value }) => this.setField(index, name, value)}
          />

          <Form.Input
            label="Max Voltage"
            name="maxVoltage"
            style={{ marginBottom: '4px' }}
            labelPosition="right"
            type="text"
            fluid
            value={maxVoltage}
            onChange={(e, { name, value }) =>
              this.setField(index, name, value)
            }>
            <input />
            <Label basic>V</Label>
          </Form.Input>
          {this.getNumberMessage(
            validOptions.maxVoltage,
            maxVoltage,
            'Max Voltage'
          )}

          <Form.Input
            label="Max Amperage"
            name="maxAmperage"
            style={{ marginBottom: '4px' }}
            labelPosition="right"
            type="text"
            fluid
            value={maxAmperage}
            onChange={(e, { name, value }) =>
              this.setField(index, name, value)
            }>
            <input />
            <Label basic>A</Label>
          </Form.Input>
          {this.getNumberMessage(
            validOptions.maxAmperage,
            maxAmperage,
            'Max Amperage'
          )}
        </Segment>
      </div>
    );
  }

  renderConnectors() {
    const { evseController } = this.props;
    const { formValues = {}, overrideConnectorStatus } = this.state;

    if (!formValues.connectors.length) {
      return (
        <Message content="Please configure number of connectors first (or use Auto Configuration)" />
      );
    }

    let { connectorStatus } = evseController;
    if (!overrideConnectorStatus && !connectorStatus) {
      return (
        <Message>
          No connector status received yet, we need a status notification from
          the EVSE before we can configure connectors. Please note that this can
          happen when a duplicate EVSE controller exists with the same OCPP
          Identity. Make sure there is no other active connection of this OCPP
          identity for a different provider.
          <br />
          <br />
          <Button
            primary
            content="Ignore Connector Status"
            style={{ margin: 0 }}
            onClick={() => this.setState({ overrideConnectorStatus: true })}
          />
        </Message>
      );
    }

    if (overrideConnectorStatus && !connectorStatus) {
      connectorStatus = {};
      formValues.connectors.forEach(
        (connector) => (connectorStatus[connector.connectorId] = {})
      );
    }

    const statusBasedConnectorIds = Object.keys(connectorStatus).filter(
      (connectorId) => connectorId.toString() !== '0'
    );
    if (statusBasedConnectorIds.length < formValues.connectors.length) {
      return (
        <Message content="No status has been received for all sockets yet. We need a status notification for all sockets from the EVSE before we can configure connectors. (Are the right number of connectors configured?)" />
      );
    }

    return (
      <div>
        {formValues.connectors.map((connector, index) =>
          this.renderConnector(connector, index, connectorStatus)
        )}
      </div>
    );
  }

  async autoPickEvseId() {
    this.props.onLoading(true);
    this.setState({ autopickLoading: true });

    try {
      const { data } = await request({
        method: 'POST',
        path: `/1/evse-ids/search`,
        body: {
          hideUsed: true,
          enableAutoPick: true,
        },
      });
      const { formValues } = this.state;
      this.props.onLoading(false);
      if (!data?.length) {
        return this.setState({
          error: new Error('No auto pick evse IDs available'),
          autopickLoading: false,
        });
      }

      formValues.evseId = data[0].identifier;
      this.setState({
        formValues,
        autopickCompleted: true,
        autopickLoading: false,
      });
    } catch (error) {
      this.props.onLoading(false);
      this.setState({ error, autopickLoading: false });
    }
  }

  render() {
    const { error, formValues, autopickCompleted } = this.state;

    const providerEvseControllersAccess = currentUserCanAccessProviderEndpoint(
      'evseControllers',
      'write'
    );

    return (
      <Form
        id={this.props.formId}
        error={Boolean(error)}
        onSubmit={this.onSubmit}>
        {error && <Message error content={error.message} />}
        {!this.props.hideEvseId && (
          <Form.Input
            value={formValues.evseId}
            name="evseId"
            label="EVSE ID"
            autoComplete="disabled"
            readOnly
            type="text">
            <input style={{ backgroundColor: 'rgba(192,192,192,.25)' }} />
          </Form.Input>
        )}

        {autopickCompleted && (
          <Message
            info
            content="New EVSE ID allocated, please make sure to hit Save in order to make these changes permanent"
          />
        )}

        {formValues.evseId && providerEvseControllersAccess && (
          <div>
            <Button
              basic
              icon="arrow-rotate-left"
              content="Reset and autopick EVSE ID"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.autoPickEvseId();
              }}
              loading={this.state.autopickLoading}
            />
          </div>
        )}

        {this.renderConnectors()}
      </Form>
    );
  }
}
