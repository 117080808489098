import React, { useContext } from 'react';
import { Confirm, Search } from 'components';
import { Button, Table } from 'semantic';
import { Trans, useTranslation } from 'react-i18next';
import { EvseConfigPreset } from 'types/evse-config-preset';
import { formatDateTime } from 'utils/date';
import { Link, useHistory } from 'react-router-dom';
import {
  EVSE_CONFIG_PRESET_BE_PATH,
  EVSE_CONFIG_PRESETS_FE_PATH,
  hasEvseConfigPresetPermissions,
  USERS_FE_PATH,
} from 'screens/EvseConfigPreset/utils';
import { request } from 'utils/api';

export default function TableView() {
  const { items, loading, reload } = useContext(Search.Context);
  const { t } = useTranslation();
  const history = useHistory();

  if (!items.length || loading) return null;

  const presets: EvseConfigPreset[] = items;
  const hasWriteAccess = hasEvseConfigPresetPermissions('write');

  const handleDelete = async (id: string) => {
    await request({
      method: 'DELETE',
      path: `${EVSE_CONFIG_PRESET_BE_PATH}/${id}`,
    });

    reload();
  };

  const handleDuplicate = async (id: string) => {
    const response = await request({
      method: 'POST',
      path: `${EVSE_CONFIG_PRESET_BE_PATH}/${id}/duplicate`,
    });

    if (response?.data?.id) {
      history.push(`${EVSE_CONFIG_PRESETS_FE_PATH}/${response.data.id}/edit`);
    }
  };

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>
              {t('evseConfigPresets.columnName', 'Preset Name')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('evseConfigPresets.columnCreatedAt', 'Created At')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('evseConfigPresets.columnCreatedBy', 'Created By')}
            </Table.HeaderCell>
            {hasWriteAccess && (
              <Table.HeaderCell width={3}>
                {t('evseConfigPresets.columnActions', 'Actions')}
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {presets.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <p
                    style={
                      item.isDefaultAutoConfiguration ? { marginBottom: 0 } : {}
                    }>
                    {item.name}
                  </p>
                  {item.isDefaultAutoConfiguration && (
                    <p style={{ fontSize: 12, color: 'grey', paddingTop: 5 }}>
                      {t(
                        'evseConfigPresets.autoConfigOverride',
                        'Auto-configuration override'
                      )}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                <Table.Cell>
                  {item.creatorUser && (
                    <Link
                      to={`${USERS_FE_PATH}/${item.creatorUser.id}`}
                      target="_blank">
                      {item.creatorUser.contact?.firstName}{' '}
                      {item.creatorUser.contact?.lastName}
                    </Link>
                  )}
                </Table.Cell>
                {hasWriteAccess && (
                  <Table.Cell textAlign="center">
                    <Button
                      basic
                      icon="pen-to-square"
                      title="Edit"
                      as={Link}
                      to={`${EVSE_CONFIG_PRESETS_FE_PATH}/${item.id}/edit`}
                    />
                    <Confirm
                      size="tiny"
                      header={t(
                        'evseConfigPresets.confirmDuplicateTitle',
                        'Are you sure you want to duplicate this preset?'
                      )}
                      content={
                        <Trans i18nKey="evseConfigPresets.confirmDuplicateDescription">
                          <p>
                            The duplicated preset can be renamed and its
                            parameters adjusted as needed.
                          </p>
                        </Trans>
                      }
                      trigger={<Button basic icon="copy" title="Duplicate" />}
                      onConfirm={() => handleDuplicate(item.id)}
                    />
                    <Confirm
                      size="tiny"
                      header={t(
                        'evseConfigPresets.confirmDeleteTitle',
                        'Are you sure you want to delete this preset?'
                      )}
                      content={
                        item.isDefaultAutoConfiguration ? (
                          <Trans i18nKey="evseConfigPresets.confirmDeleteAutoConfigurationDescription">
                            <>
                              <p>
                                The preset “Custom params BYES” is currently
                                being used as auto-configuration override
                                preset.
                              </p>
                              <p>
                                Deleting this preset will apply the default
                                system settings to all following new station
                                auto-configurations.
                              </p>
                              <p>This action cannot be undone.</p>
                            </>
                          </Trans>
                        ) : (
                          <Trans i18nKey="evseConfigPresets.confirmDeleteDescription">
                            <>
                              <p>
                                This will permanently remove the preset “
                                {item.name}” from Configuration Presets.
                              </p>
                              <p>This action cannot be undone.</p>
                            </>
                          </Trans>
                        )
                      }
                      trigger={<Button basic icon="trash" title="Delete" />}
                      onConfirm={() => handleDelete(item.id)}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
