import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { useCanAccess } from 'hooks/useCanAccess';
import RoamingActivityItem, {
  RoamingActivityItemProps,
} from './RoamingActivityItem';
import styles from './RoamingActivity.module.css';
import { Icon, Label } from 'semantic';
import { FeatureFlag } from 'components';
import { formatDistance } from 'date-fns';
import { Form, Loader } from 'semantic-ui-react';

interface Entity {
  type: 'location' | 'token' | 'cdr' | 'session';
  id: string;
}

const entityActions = [
  {
    value: 'all',
    text: 'All activity',
  },
  {
    value: 'tokenAuthorize',
    text: 'Token authorization',
  },
  {
    value: 'broadcast',
    text: 'Change broadcast',
  },
  {
    value: 'cpoCommand',
    text: 'Remote commands',
  },
];

const fetchActivity = async (
  actions: string[],
  entities: Entity[],
  onSuccess: (data: any[]) => void,
  onError: (message: string) => void,
  setLoading: (loading: boolean) => void
) => {
  if (!entities || entities.length === 0) {
    return;
  }
  setLoading(true);
  try {
    const response = await request({
      method: 'POST',
      body: {
        actions: actions.filter((action) => action !== 'all'),
        limit: 50,
        entities,
        offset: 0,
      },
      path: `/1/roaming/activity`,
    });
    if (response?.data) {
      onSuccess(response.data);
    }
  } catch (error) {
    onError(`Error fetching roaming activity: ${error}`);
  } finally {
    setLoading(false);
  }
};

const requestBroadcast = (
  entities: Entity[],
  onSuccess: (data: any[]) => void,
  onError: (message: string) => void
) => {
  if (!entities || entities.length === 0) {
    return;
  }
  request({
    method: 'POST',
    body: {
      entities,
    },
    path: `/1/roaming/broadcast`,
  })
    .then((response) => {
      if (response?.data) {
        onSuccess(response.data);
      }
    })
    .catch((error) => {
      onError(`Error fetching roaming activity: ${error}`);
    });
};

interface Props {
  entities: Entity[];
  live?: boolean;
  text?: string;
}

const RoamingActivity: React.FC<Props> = ({ entities, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [entityType, setEntityType] = useState<string>('all');
  const [live, setLive] = useState(props.live || false);
  const [action, setAction] = useState<string>('all');
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [activity, setActivity] = useState<RoamingActivityItemProps[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const fetchActivityMemo = useCallback(() => {
    fetchActivity(
      [action],
      entities.filter(
        (entity) => entityType === 'all' || entity.type === entityType
      ),
      (activity) => {
        setLastUpdated(new Date());
        setActivity(activity);
      },
      setError,
      setLoading
    );
  }, [live, action, entities, entityType]);

  const entitiesToShow = [
    {
      key: 'all',
      text: t('roamingActivity.entityTypeAll', 'All entities'),
      value: 'all',
    },
    ...[...(new Set(entities.map((entity) => entity.type)) as any)].map(
      (entity) => ({
        key: entity,
        text: t(`roamingActivity.entityType${entity}`, entity?.toUpperCase()),
        value: entity,
      })
    ),
  ];

  const canAccessRoamingManagement = useCanAccess({
    endpoint: 'roamingManagement',
    // TODO(ggordan): This should be false, but there may be a bug in the canAccess method
    // need to come back to it
    allowAccountContext: true,
    requiredLevel: 'read',
  });

  useEffect(() => {
    if (canAccessRoamingManagement) {
      fetchActivityMemo();
    }
  }, [canAccessRoamingManagement, fetchActivityMemo]);

  useEffect(() => {
    if (!canAccessRoamingManagement) {
      return;
    }
    const timeout = setInterval(() => {
      if (live) {
        fetchActivityMemo();
      }
    }, 3000);
    return () => {
      clearInterval(timeout);
    };
  }, [canAccessRoamingManagement, live, fetchActivityMemo]);

  return (
    <div>
      <div className={styles.containerHeader}>
        <h3>
          {t('roamingActivity.title', 'Roaming activity')}
          {live && lastUpdated && (
            <span className={styles.liveNote}>
              (
              {t('roamingActivity.live', 'Last updated: {{date}}', {
                date: formatDistance(lastUpdated || new Date(), new Date()),
              })}
              )
            </span>
          )}
        </h3>
        <p className={styles.actionsBar}>
          {loading && (
            <Loader active inline size="tiny" className={styles.loader} />
          )}
          <FeatureFlag feature="roaming_activity_request_broadcast">
            <Label
              className={styles.playPauseLabel}
              title={t(
                'roamingActivity.sync',
                'Trigger a sync of this entity with roaming partners'
              )}>
              <Icon
                size="tiny"
                color="white"
                name="sync"
                onClick={() => {
                  setLive(true);
                  requestBroadcast(
                    entities,
                    () => {},
                    () => {}
                  );
                }}
              />
            </Label>
          </FeatureFlag>
          <Label
            className={styles.playPauseLabel}
            title={t(
              'roamingActivity.realtime',
              'Refresh roaming activity for this entity every 3s'
            )}>
            {live ? (
              <Icon
                size="tiny"
                color="white"
                name="pause"
                onClick={() => setLive(false)}
              />
            ) : (
              <Icon
                size="tiny"
                color="white"
                name="play"
                onClick={() => setLive(true)}
              />
            )}
          </Label>
          <FeatureFlag feature="roaming_activity_request_broadcast">
            <>
              <Form.Select
                className={styles.dropdown}
                value={action}
                onChange={(e, value) =>
                  setAction((value?.value as string) || 'all')
                }
                options={entityActions}
              />
              <Form.Select
                className={styles.dropdown}
                value={entityType}
                onChange={(e, value) =>
                  setEntityType((value?.value as string) || 'all')
                }
                options={entitiesToShow}
              />
            </>
          </FeatureFlag>
        </p>
      </div>
      <div className={styles.container}>
        {error && <p>{error}</p>}
        {!error && (
          <>
            {!loading && activity.length === 0 ? (
              <p>
                {t(
                  'roamingActivity.nodata',
                  'No roaming activity found for the last 30 days.'
                )}
              </p>
            ) : (
              <div className={styles.activityList}>
                {activity.map((item, index) => (
                  <RoamingActivityItem key={index} item={item} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RoamingActivity;
