import { useBillingInfoFormikValidationSchema } from 'components/BillingInfo/formik-validation';
import { BillingInfo } from 'components/BillingInfo/types';
import { useCardHolderInfoFormikValidationSchema } from 'components/modals/RequestCard/CardHolderAndBilling/CardHolderInfo';
import { useDeliveryAddressFormikValidationSchema } from 'components/modals/RequestCard/CardHolderAndBilling/DeliveryAddress';
import { useChargeCardBillingPlans } from 'components/modals/RequestCard/TypeAndPlan/useBillingPlans';
import { useAccount } from 'contexts/account';
import { useUser } from 'contexts/user';
import { useMemo } from 'react';
import { User } from 'types/user';
import * as Yup from 'yup';

export type CardHolderInfo =
  | {
      type: 'existing';
      userId: string;
      user?: User;
    }
  | {
      type: 'new';
      firstName: string;
      lastName: string;
      email: string;
    };

export type CardDeliveryInfo =
  | {
      useSameAsBilling: true;
    }
  | {
      useSameAsBilling: false;
      firstName: string;
      lastName: string;
      street: string;
      number: string;
      postalCode: string;
      city: string;
      countryCode: string;
    };

export type CardInfo = {
  type: 'card' | 'tag';
  billingPlanId: string;
};

export type RequestCardFormValues = {
  billing: BillingInfo;
  delivery: CardDeliveryInfo;
  cardHolder: CardHolderInfo;
  card: CardInfo;
};

export type RequestCardFormInitialState = {
  formData: RequestCardFormValues;
  showBillingInfo: boolean;
};

export function useRequestCardFormInitialState(): RequestCardFormInitialState {
  const { user } = useUser();
  const { account, isBillingUpdateRequired } = useAccount();
  const billingPlanData = useChargeCardBillingPlans();

  const formData = useMemo<RequestCardFormValues>(() => {
    return {
      billing: {
        accountHolderName: account?.billing?.accountHolderName || '',
        city: account?.billing?.city || '',
        countryCode: account?.billing?.countryCode?.toLowerCase() || '',
        ibanNumber: account?.billing?.ibanNo || '',
        bicNumber: account?.billing?.bicNo || '',
        number: account?.billing?.addressLine2 || '',
        paymentMethod: account?.billing?.paymentMethod || 'manual',
        postalCode: account?.billing?.postalCode || '',
        sepaAccepted: account?.billing?.sepaAccepted || false,
        street: account?.billing?.address || '',
        vatNumber: account?.vatNo || '',
        vatNotApplicable: account?.hasNoVatNo || false,
        chamberOfCommerceNumber: account?.chamberOfCommerceNo || '',
      },
      delivery: {
        useSameAsBilling: true,
        countryCode: account?.billing?.countryCode?.toUpperCase() || '',
      },
      cardHolder: {
        type: 'existing',
        userId: user?.id || '',
        user: user || null,
      },
      card: {
        type: 'card',
        billingPlanId: billingPlanData.data?.[0]?.id || '',
      },
    };
  }, [account, billingPlanData.data]);

  return { formData, showBillingInfo: !!isBillingUpdateRequired };
}

export function useRequestCardFormValidationSchema(
  enableBicCollection: boolean
) {
  const { account } = useAccount();

  const billingInfoValidationSchema = useBillingInfoFormikValidationSchema(
    account?.type || 'individual',
    enableBicCollection
  );

  const cardHolderValidationSchema = useCardHolderInfoFormikValidationSchema();
  const deliveryValidationSchema = useDeliveryAddressFormikValidationSchema();

  return Yup.object({
    billing: Yup.object(billingInfoValidationSchema),
    cardHolder: Yup.object(cardHolderValidationSchema),
    delivery: Yup.object(deliveryValidationSchema),
  });
}
