import { useTranslation } from 'react-i18next';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import {
  DATA_IMPORTS_BE_PATH,
  DATA_IMPORTS_FE_PATH,
} from 'screens/DataImports/utils';
import { DataImport, DataImportStatus } from 'types/data-import';
import DataImportForm from 'screens/DataImports/Form';
import DataImportDetails from 'screens/DataImports/Details';
import { Loader } from 'semantic-ui-react';
import { Breadcrumbs, ErrorMessage, ListHeader } from 'components';

export default function DataImportManager() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error, refresh } = useFetch<DataImport>({
    path: `${DATA_IMPORTS_BE_PATH}/${id}`,
  });

  if (loading) {
    return <Loader active style={{ marginTop: '10%' }} />;
  }

  if (error) {
    return <ErrorInfo error={error} />;
  }

  if (data?.status === DataImportStatus.Pending) {
    return <DataImportForm dataImport={data} refresh={refresh} />;
  }

  return <DataImportDetails />;
}

const ErrorInfo: React.FC<{ error: Error }> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        path={[
          <Link to={DATA_IMPORTS_FE_PATH}>
            {t('dataImports.breadcrumbs', 'Data Import')}
          </Link>,
        ]}
        active="..."
      />
      <div style={{ marginTop: 0, marginBottom: 20 }}>
        <ListHeader title="..." />
      </div>
      <ErrorMessage error={error} />
    </>
  );
};
