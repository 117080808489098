import React from 'react';
import { SimpleTariffCostSettings } from 'components/modals/EditTariffProfile/SimpleTariffCostSettings';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { TariffProfile } from 'types/tariffprofile';
import { AdvancedTariffCostSettings } from 'components/modals/EditTariffProfile/AdvancedTariffCostSettings';
import { EnergyMarketTariffProvider } from 'components/MarketEnergyTariffs/Context';

type Props = {
  sameForAcDc: boolean;
  formValues: TariffProfile;
  setField: (field: string, value: any) => void;
  setActiveTariffSystem: (e: { ac: string; dc: string }) => void;
  hideHeader?: boolean;
};

export function TariffCostSettings({
  sameForAcDc,
  formValues,
  setField,
  setActiveTariffSystem,
  hideHeader,
}: Props) {
  const advancedTariffsFeatures = useAdvancedTariffFeatures();

  if (advancedTariffsFeatures.advancedTariffs) {
    return (
      <>
        {/*
        Area hardcoded to NL for now. This is a product decision and it means that
        NL dynamic pricing will be applied to stations that use this tariff profile
        even if they are not located in NL.

        This might be changed in the future making the area selectable.
      */}
        <EnergyMarketTariffProvider energyDeliveryArea={'NL'}>
          <AdvancedTariffCostSettings
            sameForAcDc={sameForAcDc}
            formValues={formValues}
            setField={setField}
            setActiveTariffSystem={setActiveTariffSystem}
            hideHeader={hideHeader}
          />
        </EnergyMarketTariffProvider>
      </>
    );
  }

  return (
    <SimpleTariffCostSettings
      sameForAcDc={sameForAcDc}
      formValues={formValues}
      setField={setField}
    />
  );
}
