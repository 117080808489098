interface CreatorUser {
  id: string;
  contact?: {
    firstName?: string;
    lastName?: string;
  };
}

export type DataImportFileType =
  | 'accounts'
  | 'users'
  | 'locations'
  | 'evses'
  | 'connectors'
  | 'virtual-cards';
export type DataImportFilePurpose = 'import' | 'update';
export enum DataImportStatus {
  Pending = 'pending',
  InProgress = 'in-progress',
  PartlyCompleted = 'partly-completed',
  Completed = 'completed',
  Failed = 'failed',
}
export enum DataImportFileRecordStatus {
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed',
  Validated = 'validated',
  Invalid = 'invalid',
}

export interface DataImportFileMetrics {
  statusesCount: Record<DataImportFileRecordStatus, number>;
  totalCount: number;
}

export interface DataImportFile {
  id: string;
  type: DataImportFileType;
  purpose: DataImportFilePurpose;
  originalFilename: string;
  creatorUser?: CreatorUser;
  fileMetrics: DataImportFileMetrics;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface DataImport {
  id: string;
  creatorUser?: CreatorUser;
  createdBy?: string; // Deprecated, was used for CLI imports
  note: string;
  status: DataImportStatus;
  files: DataImportFile[];
  createdAt: Date;
  updatedAt: Date;
}
