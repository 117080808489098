import React from 'react';

import { getFilePath } from 'utils/api';
import { Link } from 'react-router-dom';

import { currentUserCanAccess } from 'utils/roles';

import InvoiceTypeLabel from 'components/InvoiceTypeLabel';

import { Button, Table, Label, Dropdown } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Search } from 'components';
import Actions from '../Actions';
import NetSuiteSyncStatus from '../../../components/NetSuiteSyncStatus';
import Currency from 'components/Currency';
import InvoiceStatusLabel from 'components/InvoiceStatusLabel';
import { useUser } from 'contexts/user';
import ResourceLink from 'components/Link/ResourceLink';

export default function InvoiceTable({ prefixPath = '/finance' }) {
  const { items, loading, reload, setSort, getSorted } = React.useContext(
    Search.Context
  );
  const { t } = useTranslation();
  const { provider } = useUser();

  const writeAccess = currentUserCanAccess('invoices', 'write');

  if (!items.length || loading) return null;

  return (
    <>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('invoices.columnNumber', 'Number')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('invoices.columnAccount', 'Account')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('invoices.columnDate', 'Date')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t('invoices.columnType', 'Type')}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              sorted={getSorted('totalAmount')}
              onClick={() => setSort('totalAmount')}>
              {t('invoices.columnPrice', 'Price')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t('invoices.columnStatus', 'Status')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t('invoices.columnERP', 'ERP')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('invoices.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            const itemInvoicePath = `${prefixPath}/invoices/${item.id}`;
            const externalLinkSearchItemProviderId = item.providerId;

            // Account route and link name
            const externalLinkAccountRoute = `/accounts/${item?.account?.id}`;
            const externalLinkAccountName = item?.account?.name;

            // User route and link name
            const externalLinkUserRoute = `/accounts/users/${item?.user?.id}`;
            const externalLinkUserName = item?.user?.name;

            // we render the enterprise account name if the enterprise account is different from the provider of the logged in user
            let enterpriseAccount;
            if (item.provider) {
              enterpriseAccount =
                item.provider.id !== provider.id ? item.provider : null;
            }

            const totalAmount =
              item.billingEngineVersion === 2
                ? item.type === 'cpo-credit' ||
                  item.type === 'cpo-credit-delayed-sessions'
                  ? item.bookableCreditAmountWithVat
                  : item.bookableAmountWithVat
                : item.type === 'cpo-credit' ||
                    item.type === 'cpo-credit-delayed-sessions'
                  ? item.totalCreditAmountWithVat
                  : item.totalAmountWithVat;

            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Link to={itemInvoicePath}>{item.identifier}</Link>
                </Table.Cell>
                <Table.Cell>
                  {item.account ? (
                    <>
                      <div>
                        <ResourceLink
                          route={externalLinkAccountRoute}
                          searchItemProviderId={
                            externalLinkSearchItemProviderId
                          }
                          linkName={externalLinkAccountName}
                        />
                        {enterpriseAccount && (
                          <>
                            <small>{' - '}</small>
                            <small>({enterpriseAccount.name})</small>
                          </>
                        )}
                      </div>
                      {item.user && (
                        <p>
                          <small>
                            (
                            <ResourceLink
                              route={externalLinkUserRoute}
                              searchItemProviderId={
                                externalLinkSearchItemProviderId
                              }
                              linkName={externalLinkUserName}
                            />
                            )
                          </small>
                        </p>
                      )}
                    </>
                  ) : (
                    <span>{t('invoices.noAccount', 'No Account')}</span>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {item.year} / {item.month}
                </Table.Cell>
                <Table.Cell>
                  <InvoiceTypeLabel invoice={item} />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Currency currency={item.currency} value={totalAmount} />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <InvoiceStatusLabel invoice={item} />
                </Table.Cell>
                <Table.Cell style={{ padding: '5px' }} textAlign="center">
                  {item.exactReferences && !!item.exactReferences.length && (
                    <Label
                      as={'a'}
                      color="olive"
                      title="Exact"
                      icon="database"
                      content={`${item.exactReferences.length}`}
                      rel="noopener"
                      target="_blank"
                      href={`https://start.exactonline.nl/docs/DocEdit.aspx?FilterDocumentTypes=1&_Division_=1926660&ID=${
                        item.exactReferences[item.exactReferences.length - 1]
                          .documentId
                      }`}
                    />
                  )}
                  <NetSuiteSyncStatus
                    invoiceId={item?.id}
                    status={item?.netSuiteStatus}
                  />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    basic
                    icon="download"
                    title="Download"
                    href={getFilePath(
                      `/1/invoices/${item.id}/pdf?accessToken=${item.accessToken}`
                    )}
                    as="a"
                    disabled={!item.isReady}
                  />
                  <Dropdown button basic text={t('common.more', 'More')}>
                    <Dropdown.Menu direction="left">
                      <Actions
                        writeAccess={writeAccess}
                        item={item}
                        onReload={reload}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
