import React from 'react';
import AppWrapper from 'components/AppWrapper';

import { Divider, Header, Button, Menu, Grid, FormField } from 'semantic';
import AnalyticsToken from 'components/AnalyticsToken';
import { TectonicProvider, TimeRangePicker } from 'react-tectonic';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import { request } from 'utils/api';
import Breadcrumbs from 'components/Breadcrumbs';

import { TECTONIC_URL } from 'utils/env';
import { withTranslation } from 'react-i18next';

import Overview from 'components/analytics-screens/cpo/Overview';
import Transactions from 'components/analytics-screens/cpo/Transactions';
import Financials from 'components/analytics-screens/cpo/Financials';
import Equipment from 'components/analytics-screens/cpo/Equipment';
import { mergeFilters } from '../../../utils/visualizations';

class Analytics extends React.Component {
  state = {
    accountId: null,
    locationId: null,
  };
  render() {
    const { t } = this.props;
    const { accountId, locationId } = this.state;

    const screens = [
      {
        id: 'overview',
        name: t('common.overview', 'Overview'),
        component: Overview,
        props: {
          includeLocations: true,
        },
      },
      {
        id: 'transactions',
        name: t('common.transactions', 'Transactions'),
        component: Transactions,
      },
      {
        id: 'financials',
        name: t('common.financials', 'Financials'),
        component: Financials,
        props: {
          includeBillingPlans: false,
          includeAccounts: false,
        },
      },
      {
        id: 'equipment',
        name: t('common.equipment', 'Equipment'),
        component: Equipment,
        props: {
          hasUsersReference: false,
          includeEvseIssues: false,
        },
      },
    ];

    return (
      <AppWrapper>
        <AnalyticsToken path="/2/locations/cpo/facilitator/analytics-token">
          <TectonicProvider
            debug
            baseUrl={TECTONIC_URL}
            primaryColor="#ff3d00"
            collection="sessions"
            dateField="endedAt"
            timeRangeMode="all">
            <React.Fragment>
              <Breadcrumbs
                path={[<Link to="/facilitator/locations">Facilitator</Link>]}
                active={t(
                  'facilitatorAnalytics.title',
                  'Facilitator Analytics'
                )}
              />
              <Grid reversed="computer">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={10}
                  textAlign="right"
                  verticalAlign="middle">
                  <TimeRangePicker
                    align="right"
                    renderButton={(label, handleOnClick) => (
                      <Button
                        primary
                        icon="clock"
                        content={label}
                        onClick={handleOnClick}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  <Header as="h2" style={{ margin: '0' }}>
                    {t('facilitatorAnalytics.title', 'Facilitator Analytics')}
                  </Header>
                </Grid.Column>
              </Grid>
              <Divider hidden />
              <div style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                <FormField>
                  <label>
                    {t('analytics.filterByAccounts', 'Filter by accounts')}:
                  </label>
                  <SearchDropdown
                    value={accountId}
                    placeholder={t('analytics.allAccounts', 'All accounts')}
                    clearable
                    objectMode={false}
                    onDataNeeded={(body) => {
                      return request({
                        path: '/1/accounts/facilitator/search',
                        method: 'POST',
                        body,
                      });
                    }}
                    onChange={(e, { value: accountId }) =>
                      this.setState({ accountId, locationId: null })
                    }
                  />
                </FormField>
                <FormField>
                  <label>
                    {t('myUsage.filterByLocation', 'Filter by locations')}:
                  </label>
                  <SearchDropdown
                    value={locationId}
                    placeholder={t('myUsage.allLocations', 'All locations')}
                    clearable
                    objectMode={false}
                    onDataNeeded={(body) => {
                      return request({
                        path: '/2/locations/cpo/facilitator/search',
                        method: 'POST',
                        body: {
                          ...body,
                          ...(accountId ? { searchId: accountId } : {}),
                          sort: {
                            order: 'desc',
                            field: 'createdAt',
                          },
                        },
                      });
                    }}
                    onChange={(e, { value: locationId }) =>
                      this.setState({ locationId })
                    }
                  />
                </FormField>
              </div>
              <Divider hidden />
              {this.renderMenu(screens)}
              <Divider hidden />
              <Switch>
                {screens.map((screen) => {
                  return (
                    <Route
                      key={screen.id}
                      exact
                      path={
                        screen.id === 'overview'
                          ? `/facilitator/analytics`
                          : `/facilitator/analytics/${screen.id}`
                      }
                      component={(props) => (
                        <screen.component
                          {...(screen.props || {})}
                          baseFilter={mergeFilters(
                            accountId ? { terms: [{ accountId }] } : {},
                            locationId ? { terms: [{ locationId }] } : {}
                          )}
                        />
                      )}
                    />
                  );
                })}
              </Switch>
            </React.Fragment>
          </TectonicProvider>
        </AnalyticsToken>
      </AppWrapper>
    );
  }

  renderMenu(screens) {
    return (
      <Menu fluid pointing secondary>
        {screens.map(({ id, name }) => {
          return (
            <Menu.Item
              key={id}
              exact
              content={name}
              to={
                id === 'overview'
                  ? `/facilitator/analytics`
                  : `/facilitator/analytics/${id}`
              }
              as={NavLink}
            />
          );
        })}
      </Menu>
    );
  }
}

export default withTranslation()(Analytics);
