import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  Modal,
  Input,
  List,
  MenuItem,
  ListItem,
  ListContent,
  ListHeader,
} from 'semantic-ui-react';
import { Icon } from 'semantic';
import { Group, Space, Stack } from './Layout/helpers';
import { buildMenu, menuDefinitions } from 'utils/navigation';
import { useUser } from '../contexts/user';
import { useFeatures } from '../contexts/features';
import { usePlatformModules } from '../contexts/platformModules';
import { useHistory } from 'react-router-dom';

type MenuItem = {
  title?: string;
  content: string;
  description?: string;
  icon: string;
  to: string;
  slug: string;
  subItems?: MenuSubItem[];
  endpoints?: string[];
  hidden?: boolean;
};

type MenuSubItem = {
  content: string;
  slug: string;
  description?: string;
  icon: string;
  to: string;
  endpoint: string;
};

const RESULTS_LIMIT = 8;
const POPULAR_SEARCHES = [
  'accounts',
  'users',
  'cards',
  'msp-tokens',
  'cpo-locations',
  'billing-plans',
  'invoices',
];

export default function SpotlightContainer() {
  const { hasFeature } = useFeatures();
  const isSpotlightMenuEnabled = hasFeature('spotlight_search');

  return isSpotlightMenuEnabled && <SpotlightMenu />;
}

function SpotlightMenu() {
  const { t } = useTranslation();
  const history = useHistory();

  const { provider, user, hasPlatformFeature } = useUser();
  const { hasFeature } = useFeatures();
  const { hasPlatformModule } = usePlatformModules();

  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [defaultResults, setDefaultResults] = useState<MenuItem[]>([]);

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<MenuItem[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    if (provider && user) {
      const items: MenuItem[] = [];
      menuDefinitions.forEach((definition) => {
        const menu = buildMenu(
          provider,
          user,
          definition.items.map((item) => {
            if (typeof item.to === 'function') {
              item.to = item.to(provider);
            }
            return item;
          }),
          t,
          hasFeature,
          hasPlatformFeature,
          hasPlatformModule
        );

        menu.forEach((menuItem: MenuItem) => {
          items.push({
            ...menuItem,
            title: menuItem.content,
          });
          if (menuItem.subItems && menuItem.subItems.length > 0) {
            menuItem.subItems.forEach((subItem: MenuSubItem) => {
              items.push({
                ...subItem,
                title: subItem.content,
              });
            });
          }
        });
      });

      const defaults = items
        .filter((item) =>
          POPULAR_SEARCHES.includes((item?.slug || '').toLowerCase())
        )
        .slice(0, RESULTS_LIMIT);

      setMenuItems(items);
      setDefaultResults(defaults);
      setResults(defaultResults);
    }
  }, [user, provider, t, hasFeature, hasPlatformFeature, hasPlatformModule]);

  useEffect(() => {
    if (query) {
      const filteredResults = menuItems.filter((item) => {
        return item?.content?.toLowerCase().includes(query.toLowerCase());
      });
      setResults(filteredResults.slice(0, RESULTS_LIMIT));
      setSelectedIndex(filteredResults.length > 0 ? 0 : -1);
    } else {
      setResults(defaultResults);
      setSelectedIndex(-1);
    }
  }, [query, menuItems]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        setOpen((prevOpen) => !prevOpen);
      } else if (open) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            setSelectedIndex((prevIndex) =>
              Math.min(prevIndex + 1, results.length - 1)
            );
            break;
          case 'ArrowUp':
            event.preventDefault();
            setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            break;
          case 'Enter':
            if (selectedIndex >= 0) {
              event.preventDefault();
              const selectedItem = results[selectedIndex];
              if (selectedItem) {
                history.push(selectedItem.to);
                setOpen(false); // 👈 change this line to say handleClose() to clear the query between searches
              }
            }
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [open, results, selectedIndex, history]);

  const handleClose = () => {
    setOpen(false);
    setQuery('');
  };

  return (
    <Modal
      size={'tiny'}
      open={open}
      centered={false}
      dimmer={'blurring'}
      onClose={handleClose}>
      <Modal.Content>
        <Input
          autoFocus
          fluid
          placeholder="Type to search..."
          value={query}
          icon="search"
          iconPosition="left"
          onChange={(e) => setQuery(e.target.value)}
          style={{
            marginTop: '1em',
          }}
        />
        <List relaxed selection>
          {results.length === 0 && <NoResultsFallback />}
          {results.length > 0 &&
            results.map((item, index) => (
              <ListItem key={index} active={index === selectedIndex}>
                <ListContent>
                  <Stack
                    align="flex-start"
                    justify="flex-start"
                    spacing={8}
                    style={{ marginTop: '12px' }}>
                    <NavLink
                      to={item.to}
                      onClick={handleClose}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}>
                      <Group position="center" align="center" spacing={1}>
                        <Icon name={item.icon} size="small" color={'grey'} />
                        <ListHeader>{item.title}</ListHeader>
                      </Group>
                    </NavLink>
                    {item?.description || ''}
                  </Stack>
                </ListContent>
              </ListItem>
            ))}
        </List>
      </Modal.Content>
    </Modal>
  );
}

function NoResultsFallback() {
  const { t } = useTranslation();

  return (
    <ListItem style={{ marginTop: 5 }}>
      <ListContent>
        <MenuItem>
          <ListHeader>
            {t('spotlightMenu.noResultsMessage', 'Nothing found...')}
          </ListHeader>
        </MenuItem>
      </ListContent>
    </ListItem>
  );
}
