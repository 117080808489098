import React from 'react';
import { set, get } from 'lodash-es';
import { request } from 'utils/api';

import { Form, Message, Modal, Header, Segment, Button } from 'semantic';
import { simpleOptions } from 'utils/form';
import CountriesField from 'components/form-fields/Countries';
import PointerMap from 'components/maps/Pointer';

import SearchDropdown from 'components/form-fields/SearchDropdown';
import { getParkingTypeOptions, getFacilityOptions } from 'utils/locations';

import { SplitReimbusementConfigration } from './SplitReimburseConfiguration';
import { getAccessPoliciesOptions } from 'utils/locations';
import { PlatformFeature } from 'components';
import AsyncModal from 'helpers/async-modal';
import useAccountHasFeature from 'hooks/useAccountHasFeature';
import { useFeatures } from 'contexts/features';
import { useTranslation } from 'react-i18next';
import { HOAConfiguration } from './HOAConfiguration';

const depopulateLocationBillingPolicy = (data) => {
  const transformedResult = data;
  // Depopulating billingPolicy entity accountId/userId (cuz of the way we use it in HOA and SplitBilling forms)
  // I didn't want to cause a breaking change on the API so I'm overriding the response
  // shape here
  // This is done to prevent the bug of open dialog -> press update and it says error
  // caused by (accountId/userId must be string)
  if (transformedResult.billingPolicy) {
    transformedResult.billingPolicy.usageReimbursement =
      transformedResult.billingPolicy.usageReimbursement.map((e) => {
        if (e.entity?.accountId?._id) {
          const acc = e.entity?.accountId;
          e.entity.accountId = acc?._id;
          e.entity.id = acc?._id;
          e.entity.name = acc?.name;
          return e;
        }
        if (e.entity?.userId?._id) {
          const user = e.entity.userId;
          e.entity.userId = user?._id;
          e.entity.id = user?._id;
          e.entity.name = user?.name;
          return e;
        }
        return e;
      });

    transformedResult.billingPolicy.subscriptionBilling =
      transformedResult.billingPolicy.subscriptionBilling.map((e) => {
        if (e.entity?.accountId?._id) {
          const acc = e.entity?.accountId;
          e.entity.accountId = acc?._id;
          e.entity.id = acc?._id;
          e.entity.name = acc?.name;
          return e;
        }
        if (e.entity?.userId?._id) {
          const user = e.entity.userId;
          e.entity.userId = user?._id;
          e.entity.id = user?._id;
          e.entity.name = user?.name;
          return e;
        }
        return e;
      });
  }
  return transformedResult;
};

/**
 *
 * @param {string} accountId
 * @param {*[]} elements
 * @returns {[{percentage: number, type: 'credit', entity: {type: 'account', accountId: string | undefined, id: string | undefined, name: string | undefined}}]}
 */
const getSpiltBillingPolicy = (account, elements) => {
  if (!elements || elements?.length < 2) {
    return [
      {
        percentage: 50,
        type: 'credit',
        entity: {
          type: 'account',
          id: account?.id,
          accountId: account?.id,
          name: account?.name,
        },
      },
      { percentage: 50, type: 'credit' },
    ];
  }

  return elements;
};

function EditLocation({
  data = {
    accessPolicy: 'businessReimburse',
    country: 'NLD',
    publishingMode: 'private',
  },
  close,
}) {
  const [formValues, setFormValues] = React.useState(
    depopulateLocationBillingPolicy(data)
  );
  const [supportedCountries, setSupportedCountries] = React.useState([]);
  const [defaultCountry, setDefaultCountry] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { hasFeature, isReady } = useFeatures();

  const { t } = useTranslation();

  React.useEffect(() => {
    request({
      path: '/2/locations/cpo/supported-countries',
      method: 'GET',
    }).then(({ data }) => {
      setSupportedCountries(data.supportedCountries);
      setDefaultCountry(data.defaultCountry);
    });
  }, []);

  const splitReimburseEnabled = useAccountHasFeature(
    'split-billing:split-reimburse',
    formValues.account?.id
  );

  const communityReimburseEnabled = useAccountHasFeature(
    'community-billing:community-reimburse',
    formValues.account?.id
  );
  const availableBillingPolicies = getAccessPoliciesOptions(
    isReady &&
      hasFeature('billing_engine_v2__split_billing') &&
      splitReimburseEnabled,
    isReady &&
      hasFeature('billing_engine_v2__split_billing') &&
      communityReimburseEnabled
  );

  const isUpdate = !!data?.id;

  const handleSubmit = () => {
    setSubmitted(true);
    setLoading(true);
    setError(null);

    let body = formValues;

    if (formValues.accessPolicy === 'splitReimburse') {
      if (formValues.billingPolicy.usageReimbursement.length < 2) {
        setError(
          new Error(
            'At least 2 users/accounts are required for Split Reimbursement'
          )
        );
        setLoading(false);
        return;
      }
      body = {
        ...formValues,
        accountId: formValues.account?.id,
        billingPolicy: {
          ...formValues.billingPolicy,
          subscriptionBilling: [
            {
              percentage: 100,
              entity: {
                type: 'account',
                accountId: formValues.account?.id,
              },
            },
          ],
        },
      };
    } else if (formValues.accessPolicy === 'communityReimburse') {
      body = {
        ...formValues,
        accountId: formValues.account?.id,
        billingPolicy: {
          ...formValues.billingPolicy,
          usageReimbursement: [
            {
              percentage: 100,
              type: 'credit',
              entity: {
                type: 'account',
                accountId: formValues.account?.id,
              },
            },
          ],
        },
      };
    } else {
      body = {
        ...formValues,
        accountId: formValues.account?.id,
        billingPolicy: undefined,
      };
    }

    const isUpdate = !!data?.id;
    const method = isUpdate ? 'PATCH' : 'POST';
    const path = isUpdate ? `/2/locations/cpo/${data.id}` : '/2/locations/cpo';
    request({
      method,
      path,
      body,
    })
      .then(() => {
        setLoading(false);

        close();

        setFormValues(data);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues(set({ ...formValues }, name, value));
  };

  const getMapProps = () => {
    const address = [
      formValues.address,
      formValues.postal_code,
      formValues.city,
      formValues.country,
    ];
    const { coordinates } = formValues.geoLocation || {};
    const hasCompleteAddress = address.every((c) => !!c);

    const isValidLocation =
      coordinates && coordinates[0] !== 135 && coordinates[1] !== 90;

    const geoPosition = isValidLocation && {
      longitude: coordinates[0],
      latitude: coordinates[1],
    };

    if (geoPosition) {
      return {
        value: geoPosition,
      };
    }
    return { address: hasCompleteAddress ? address.join(', ') : undefined };
  };

  return (
    <>
      <Modal.Header>{isUpdate ? `Edit Location` : 'New Location'}</Modal.Header>
      <Modal.Content>
        <Form
          id="EditLocation-form"
          error={submitted && Boolean(error)}
          onSubmit={() => handleSubmit()}>
          {error && <Message error content={error.message} />}
          <Form.Input
            value={formValues.name || ''}
            name="name"
            required
            label="Name"
            type="text"
            onChange={(_, { name, value }) => setField(name, value)}
          />
          <Form.Field required>
            <label>{t('common.account', 'Account')}</label>
            <SearchDropdown
              value={formValues.account?.id}
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: body,
                });
              }}
              onChange={(_, { value, options }) => {
                const selectedOption = options?.find((e) => e.value === value);
                setField('account', {
                  id: selectedOption?.value,
                  name: selectedOption?.text,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>
              {t('common.facilitatorAccount', 'Facilitator Account')}
            </label>
            <SearchDropdown
              clearable
              value={formValues.facilitatorAccountId}
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: body,
                });
              }}
              onChange={(_, { value }) =>
                setField('facilitatorAccountId', value.length ? value : null)
              }
            />
          </Form.Field>
          <Form.Field>
            <label>{t('common.user', 'User')}</label>
            <SearchDropdown
              keywordField="searchPhrase"
              key={formValues.account?.id}
              value={formValues.userId}
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/users/search/fast',
                  method: 'POST',
                  body: {
                    ...body,
                    accountId: formValues.account?.id,
                  },
                });
              }}
              onChange={(_, { value }) =>
                setField('userId', value.length ? value : null)
              }
            />
          </Form.Field>
          {formValues.accessPolicy === 'employeeReimburse' &&
            (!formValues.account?.id ? (
              <Message content="Please select an account" />
            ) : (
              <Form.Field>
                <label>{t('common.employee', 'Employee')}</label>
                <SearchDropdown
                  keywordField="searchPhrase"
                  value={formValues.userId}
                  clearable
                  objectMode={false}
                  getOptionLabel={(item) => `${item.name} (${item.email})`}
                  onDataNeeded={(body) => {
                    return request({
                      path: '/1/users/search/fast',
                      method: 'POST',
                      body: {
                        accountId: formValues.account?.id,
                        ...body,
                      },
                    });
                  }}
                  onChange={(_, { value }) =>
                    setField('userId', value.length ? value : null)
                  }
                />
              </Form.Field>
            ))}
          <Header as="h3">{t('common.address', 'Address')}</Header>
          <Segment>
            <Form.Input
              value={formValues.address || ''}
              name="address"
              required
              label="Address / Street"
              type="text"
              autoComplete="disabled"
              onChange={(_, { name, value }) => setField(name, value)}
            />
            <Form.Input
              value={formValues.postal_code || ''}
              name="postal_code"
              required
              label="Postal Code / Zip"
              type="text"
              autoComplete="disabled"
              onChange={(_, { name, value }) => setField(name, value)}
            />
            <Form.Input
              value={formValues.city || ''}
              name="city"
              required
              label="City"
              type="text"
              autoComplete="disabled"
              onChange={(_, { name, value }) => setField(name, value)}
            />
            <CountriesField
              label="Country"
              name="country"
              required
              standard="alpha-3"
              lowerCase={false}
              whitelist={supportedCountries}
              autoComplete="disabled"
              value={formValues.country}
              onChange={(value) => setField('country', value)}
            />
          </Segment>
          <Form.Select
            value={formValues?.accessPolicy}
            options={availableBillingPolicies}
            name="accessPolicy"
            label="Access &amp; Billing Policy"
            required
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
          {formValues.accessPolicy === 'splitReimburse' && (
            <SplitReimbusementConfigration
              accessPolicy={formValues.accessPolicy}
              onChange={(field, value) => setField(field, value)}
              account={formValues?.account}
              value={getSpiltBillingPolicy(
                formValues?.account,
                formValues?.billingPolicy?.usageReimbursement
              )}
            />
          )}
          {formValues.accessPolicy === 'communityReimburse' && (
            <HOAConfiguration
              onChange={(field, value) => setField(field, value)}
              subscriptionBillingItems={
                formValues?.billingPolicy?.subscriptionBilling
              }
            />
          )}
          <Header as="h3">
            {t('editLocation.publicationSettings', 'Publication Settings')}
          </Header>
          <Segment>
            <p>
              {t(
                'editLocation.publicationSettingsNote',
                'Public locations will be shared with the international roaming system so that it can be found on maps.'
              )}
            </p>
            <Form.Select
              value={formValues.publishingMode}
              options={simpleOptions(['private', 'public'])}
              name="publishingMode"
              label="Visibility"
              required
              type="text"
              onChange={(_, { name, value }) => setField(name, value)}
            />
            {formValues.publishingMode === 'public' && (
              <>
                <PlatformFeature feature={'roaming:suboperator-locations'}>
                  <Form.Input
                    value={get(formValues, 'suboperator.name', '')}
                    name="suboperator.name"
                    label={'Suboperator Name'}
                    type="text"
                    onChange={(_, { name, value }) => setField(name, value)}
                  />
                </PlatformFeature>
                <Form.Select
                  value={formValues.parking_type}
                  options={getParkingTypeOptions()}
                  name="parking_type"
                  label="Parking Type"
                  type="text"
                  onChange={(_, { name, value }) => setField(name, value)}
                />
                <Form.Select
                  multiple
                  value={formValues.facilities}
                  options={getFacilityOptions()}
                  name="facilities"
                  label="Available Facilities"
                  type="text"
                  onChange={(_, { name, value }) => {
                    setField(name, value);
                  }}
                />
              </>
            )}

            <div style={{ height: '300px', marginBottom: '5px' }}>
              <PointerMap
                onChange={(position) => {
                  setField('geoLocation', {
                    type: 'Point',
                    coordinates: [position.longitude, position.latitude],
                  });
                }}
                {...getMapProps()}
              />
            </div>

            <a
              onClick={() => {
                setField('geoLocation', undefined);
              }}>
              {t(
                'editLocation.setPositionToAddress',
                'Set Position to Address'
              )}
            </a>
          </Segment>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={isUpdate ? 'Update' : 'Create'}
          form="EditLocation-form"
        />
      </Modal.Actions>
    </>
  );
}

export default AsyncModal(EditLocation);
