import React, { useEffect, useState } from 'react';
import { isEqual, set } from 'lodash-es';
import { request } from 'utils/api';
import { currencyToSymbol } from 'utils/formatting';
import {
  Button,
  Checkbox,
  Form,
  Header,
  Input,
  Message,
  Modal,
  Table,
  Divider,
} from 'semantic';
import { simpleOptions } from 'utils/form';
import { useTranslation } from 'react-i18next';
import modal from 'helpers/modal';
import { useFeatures } from 'contexts/features';
import { TariffCostSettings } from 'components/modals/EditTariffProfile/TariffCostSettings';
import {
  TariffProfile,
  TariffProfileCostSettings,
  TariffProfileCustomIssuerTariff,
} from 'types/tariffprofile';
import { TariffProfileFeatureProvider } from 'components/modals/EditTariffProfile/TariffProfileFeatureProvider';
import { AccountPlatformFeatureProvider } from 'components/account-platform-features/context';
import { useUser } from 'contexts/user';
import SpecialMspCardBillingPlanTariffs from './SpecialMspCardBillingPlanTariffs';

const defaultProps = {
  initialValues: {
    type: 'static',
    costSettings: {
      ac: {
        pricePerKwh: 0.2,
        pricePerSession: 0,
        pricePerHour: 0,
      },
      dc: {
        pricePerKwh: 0.2,
        pricePerSession: 0,
        pricePerHour: 0,
      },
    },

    customIssuerTariffs: [
      {
        costSettings: {
          ac: {
            pricePerKwh: 0.2,
            pricePerSession: 0,
            pricePerHour: 0,
          },
          dc: {
            pricePerKwh: 0.2,
            pricePerSession: 0,
            pricePerHour: 0,
          },
        },
      },
    ],
    customInfraTokenProviderTariffs: [],
    allowEvseControllerOverride: false,
    enabledCustomTariffs: 'none',
  },
};

type EditTariffProfileProps = {
  initialValues?: TariffProfile;
  currencies: string[];
  onClose?: () => void;
  onDone?: () => void;
  close: () => void;
};

function EditTariffProfile({
  initialValues = defaultProps.initialValues,
  currencies,
  onClose,
  onDone,
  close,
}: EditTariffProfileProps) {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  const { user } = useUser();

  // TODO: Remove this once tariffProfiles v2 migration is complete.
  const supportNewTariffProfile = hasFeature('tariff-profiles-v2');
  if (!initialValues.costSettings?.ac) {
    initialValues.costSettings.ac = {
      pricePerKwh: initialValues.costSettings.pricePerKwh,
      pricePerHour: initialValues.costSettings.pricePerHour,
      pricePerSession: initialValues.costSettings.pricePerSession,
      pricePerIdleMinute: initialValues.costSettings.pricePerIdleMinute,
      idleGracePeriodMinutes: initialValues.costSettings.idleGracePeriodMinutes,
    };
  }
  if (!initialValues.costSettings?.dc) {
    initialValues.costSettings.dc = {
      pricePerKwh: initialValues.costSettings.pricePerKwh,
      pricePerHour: initialValues.costSettings.pricePerHour,
      pricePerSession: initialValues.costSettings.pricePerSession,
      pricePerIdleMinute: initialValues.costSettings.pricePerIdleMinute,
      idleGracePeriodMinutes: initialValues.costSettings.idleGracePeriodMinutes,
    };
  }
  initialValues?.customIssuerTariffs.map((e) => {
    if (e.costSettings) {
      return e;
    }
    e.costSettings.ac = {
      pricePerKwh: e.costSettings.pricePerKwh,
      pricePerHour: e.costSettings.pricePerHour,
      pricePerSession: e.costSettings.pricePerSession,
      _id: e._id,
    };
    e.costSettings.dc = {
      pricePerKwh: e.costSettings.pricePerKwh,
      pricePerHour: e.costSettings.pricePerHour,
      pricePerSession: e.costSettings.pricePerSession,
      _id: e._id,
    };
  });
  initialValues?.customInfraTokenProviderTariffs.map((e) => {
    if (e.costSettings) {
      return e;
    }
    e.costSettings.ac = {
      pricePerKwh: e.costSettings.pricePerKwh,
      pricePerHour: e.costSettings.pricePerHour,
      pricePerSession: e.costSettings.pricePerSession,
      _id: e._id,
    };
    e.costSettings.dc = {
      pricePerKwh: e.costSettings.pricePerKwh,
      pricePerHour: e.costSettings.pricePerHour,
      pricePerSession: e.costSettings.pricePerSession,
      _id: e._id,
    };
  });

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [formValues, setFormValues] = React.useState({
    currency: currencies[0] ?? 'EUR',
    ...initialValues,
  });
  const [allowMSPTokenBillingPlanTariffs, setAllowMSPTokenBillingPlanTariffs] =
    useState(initialValues.mspTokenBillingPlanTariffs?.length > 0);

  useEffect(() => {
    if (!allowMSPTokenBillingPlanTariffs) {
      setFormValues({
        ...formValues,
        mspTokenBillingPlanTariffs: [],
      });
    }
  }, [allowMSPTokenBillingPlanTariffs]);

  let acActiveTariffSystem = 'basic';
  let dcActiveTariffSystem = 'basic';

  const onSubmit = () => {
    setSubmitted(false);
    setLoading(false);
    setError(null);

    const body = formValues;

    if (body.enabledCustomTariffs !== 'issuer') {
      body.customIssuerTariffs = [];
    }
    if (body.enabledCustomTariffs !== 'infra-token-provider') {
      body.customInfraTokenProviderTariffs = [];
    }

    // This shouldn't fire, like ever, but just in case
    if (!body.costSettings) {
      return;
    }

    if (sameForAcDc) {
      body.costSettings.ac = body.costSettings.dc;
    }

    // TODO: Remove when TariffProfile v2 feature is fully migrated.
    body.pricePerKwh = body.costSettings.dc.pricePerKwh;
    body.pricePerSession = body.costSettings.dc.pricePerSession;
    body.pricePerHour = body.costSettings.dc.pricePerHour;

    body.customIssuerTariffs =
      body?.customIssuerTariffs.map((e): TariffProfileCustomIssuerTariff => {
        return {
          ...e,
          pricePerKwh: e.costSettings.dc.pricePerKwh,
          pricePerHour: e.costSettings.dc.pricePerHour,
          pricePerSession: e.costSettings.dc.pricePerSession,
        };
      }) ?? [];
    body.customInfraTokenProviderTariffs =
      body?.customInfraTokenProviderTariffs.map(
        (e): TariffProfileCustomIssuerTariff => {
          return {
            ...e,
            pricePerKwh: e.costSettings?.dc?.pricePerKwh ?? 0,
            pricePerHour: e.costSettings?.dc?.pricePerHour ?? 0,
            pricePerSession: e.costSettings?.dc?.pricePerSession ?? 0,
          };
        }
      ) ?? [];

    // Cleaning Fields on submit
    if (acActiveTariffSystem !== 'scheduled') {
      body.costSettings.ac.components = [];
    }
    if (dcActiveTariffSystem !== 'scheduled') {
      body.costSettings.dc.components = [];
    }

    body.costSettings.ac.enableEnergyMarketTracking =
      acActiveTariffSystem === 'dynamic';
    body.costSettings.dc.enableEnergyMarketTracking =
      dcActiveTariffSystem === 'dynamic';

    if (acActiveTariffSystem !== 'dynamic') {
      body.costSettings.ac.surchargePerKwh = 0;
    }

    if (dcActiveTariffSystem !== 'dynamic') {
      body.costSettings.dc.surchargePerKwh = 0;
    }

    if (body.mspTokenBillingPlanTariffs?.length) {
      let invalidMspTokenBillingPlanId = body.mspTokenBillingPlanTariffs.filter(
        (t) => !t.mspTokenBillingPlanId
      );
      if (invalidMspTokenBillingPlanId?.length) {
        setError(
          new Error(
            t(
              'editTariffProfile.invalidPlanError',
              'All special tariffs should have a valid billing plan'
            )
          )
        );
        return;
      }

      const mspTokenBillingPlanIds = new Set();
      for (const { mspTokenBillingPlanId } of body.mspTokenBillingPlanTariffs) {
        if (mspTokenBillingPlanIds.has(mspTokenBillingPlanId.toString())) {
          setError(
            new Error(
              t(
                'editTariffProfile.duplicatePlanError',
                'Only one special tariff per billing plan can be defined'
              )
            )
          );
          return;
        }
        mspTokenBillingPlanIds.add(mspTokenBillingPlanId.toString());
      }
    }

    const isUpdate = !!initialValues.id;
    const method = isUpdate ? 'PATCH' : 'POST';
    const path = isUpdate
      ? `/1/tariff-profiles/${initialValues.id}`
      : '/1/tariff-profiles';
    request({
      method,
      path,
      body,
    })
      .then(() => {
        onClose?.();
        onDone?.();
        close();
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const setField = (name: string, value: any) => {
    set(formValues, name, value);
    setFormValues({ ...formValues });
    setSubmitted(false);
  };

  const isUpdate = !!initialValues.id;

  const [sameForAcDc, setSameForAcDc] = useState(
    !supportNewTariffProfile || isSameForAcDc(formValues.costSettings)
  );

  return (
    <>
      <Modal.Header>
        {isUpdate ? `Edit Tariff Profile` : 'New Tariff Profile'}
      </Modal.Header>
      <Modal.Content>
        <Form id="EditTariffProfile-form" error={submitted && Boolean(error)}>
          {error && <Message error content={error.message} />}

          <Form.Select
            value={formValues.currency}
            options={simpleOptions(currencies, { upperCase: true })}
            name="currency"
            label="Currency"
            required
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Form.Input
            value={formValues.name || ''}
            name="name"
            required
            label="Name"
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />

          {supportNewTariffProfile && (
            <Checkbox
              toggle
              label={t(
                'editTariffProfile.sameForAcDc',
                'Use same tariff for AC & DC connectors'
              )}
              checked={sameForAcDc}
              onChange={() => {
                setSameForAcDc(!sameForAcDc);
              }}
            />
          )}

          <AccountPlatformFeatureProvider accountId={user.accountId}>
            <TariffProfileFeatureProvider>
              <div style={{ marginBottom: '1rem' }}>
                <TariffCostSettings
                  sameForAcDc={sameForAcDc}
                  formValues={formValues}
                  setField={setField}
                  setActiveTariffSystem={(e) => {
                    acActiveTariffSystem = e.ac;
                    dcActiveTariffSystem = e.dc;
                  }}
                />
              </div>
            </TariffProfileFeatureProvider>
          </AccountPlatformFeatureProvider>

          <Form.Checkbox
            label="Allow cost settings at the EVSE controller to be set and override the above base tariff"
            name="allowEvseControllerOverride"
            checked={formValues.allowEvseControllerOverride}
            onChange={(e, { name, checked }) => setField(name, checked)}
          />
          <Form.Checkbox
            label="Enable custom tariffs for certain card issuers"
            name="enableCustomTariffs"
            checked={formValues.enabledCustomTariffs !== 'none'}
            onChange={(e, { checked }) =>
              setField('enabledCustomTariffs', checked ? 'issuer' : 'none')
            }
          />
          {hasFeature('tariff_profiles_by_infra_token_provider') &&
            formValues.enabledCustomTariffs !== 'none' && (
              <>
                <Form.Radio
                  style={{ marginLeft: '20px' }}
                  label="Issuer Name"
                  name="enabledCustomTariffs"
                  value="issuer"
                  checked={formValues.enabledCustomTariffs === 'issuer'}
                  onChange={(e, { value }) =>
                    setField('enabledCustomTariffs', value)
                  }
                />
                <Form.Radio
                  style={{ marginLeft: '20px' }}
                  label="Infra Token Provider"
                  name="enabledCustomTariffs"
                  value="infra-token-provider"
                  checked={
                    formValues.enabledCustomTariffs === 'infra-token-provider'
                  }
                  onChange={(e, { value }) =>
                    setField('enabledCustomTariffs', value)
                  }
                />
              </>
            )}
          {formValues.enabledCustomTariffs === 'issuer' && (
            <EditCustomTariffs
              currency={formValues.currency}
              nameField={'issuerName'}
              nameHeader={'Issuer Name'}
              customTariffs={formValues.customIssuerTariffs}
              sameForAcDc={sameForAcDc}
              onChange={(next) => setField('customIssuerTariffs', next)}
            />
          )}
          {formValues.enabledCustomTariffs === 'infra-token-provider' && (
            <EditCustomTariffs
              currency={formValues.currency}
              nameField={'infraTokenProvider'}
              nameHeader={'Infra Token Provider'}
              customTariffs={formValues.customInfraTokenProviderTariffs}
              sameForAcDc={sameForAcDc}
              maxLength={1}
              onChange={(next) =>
                setField('customInfraTokenProviderTariffs', next)
              }
            />
          )}
          {hasFeature('tariff_profile_msp_card_billing_plan_tariff') && (
            <>
              <Form.Checkbox
                label={t(
                  'editTariffProfile.allowMSPTokenBillingPlanTariffs',
                  'Apply special tariff for certain consumer card billing plans'
                )}
                name="allowMSPTokenBillingPlanTariffs"
                checked={
                  allowMSPTokenBillingPlanTariffs ||
                  !!formValues.mspTokenBillingPlanTariffs?.length
                }
                onChange={(e, { checked }) =>
                  setAllowMSPTokenBillingPlanTariffs(!!checked)
                }
              />
              {(allowMSPTokenBillingPlanTariffs ||
                formValues.mspTokenBillingPlanTariffs?.length > 0) && (
                <SpecialMspCardBillingPlanTariffs
                  formValues={formValues}
                  onChange={(next) =>
                    setField('mspTokenBillingPlanTariffs', next)
                  }
                  sameForAcDc={sameForAcDc}
                />
              )}
            </>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={isUpdate ? 'Update' : 'Create'}
          form="EditTariffProfile-form"
          onClick={onSubmit}
        />
      </Modal.Actions>
    </>
  );
}

function isSameForAcDc(costSettings: TariffProfileCostSettings) {
  // try settings the id to same value, so we can compare the other values in the object

  // For basic
  if (costSettings.ac.pricePerKwh !== costSettings.dc.pricePerKwh) {
    return false;
  }
  if (costSettings.ac.pricePerSession !== costSettings.dc.pricePerSession) {
    return false;
  }
  if (costSettings.ac.pricePerHour !== costSettings.dc.pricePerHour) {
    return false;
  }

  if (
    costSettings.ac.components?.length !== costSettings.dc.components?.length
  ) {
    return false;
  }

  // If scheduled
  if (costSettings.ac.components && costSettings.dc.components) {
    for (let i = 0; i < costSettings.ac.components.length; i++) {
      const ac = costSettings.ac.components[i];
      const dc = costSettings.dc.components[i];

      if (!isEqual(ac.costSettings, dc.costSettings)) {
        return false;
      }

      if (!isEqual(ac.restrictions, dc.restrictions)) {
        return false;
      }
    }
  }

  // if dynamic
  if (
    costSettings.ac.idleGracePeriodMinutes !==
    costSettings.dc.idleGracePeriodMinutes
  ) {
    return false;
  }

  if (
    costSettings.ac.pricePerIdleMinute !== costSettings.dc.pricePerIdleMinute
  ) {
    return false;
  }

  if (costSettings.ac.surchargePerKwh !== costSettings.dc.surchargePerKwh) {
    return false;
  }

  return true;
}

export default modal(EditTariffProfile);

/**
 *
 * @param {Array<TariffProfileCustomIssuerTariff>} customTariffs
 * @param nameField
 * @param nameHeader
 * @param maxLength
 * @param currency
 * @param onChange
 * @param {boolean} sameForAcDc
 * @return {Element}
 * @constructor
 */

type EditCustomTariffsProps = {
  customTariffs: any[];
  nameField: string;
  nameHeader: string;
  maxLength?: number;
  currency: string;
  onChange: (tariffs: TariffProfileCustomIssuerTariff[]) => void;
  sameForAcDc: boolean;
};

function EditCustomTariffs({
  customTariffs,
  nameField,
  nameHeader,
  maxLength,
  currency,
  onChange,
  sameForAcDc,
}: EditCustomTariffsProps) {
  const { t } = useTranslation();
  const setCustomTariffField = (index: number, name: string, value: any) => {
    if (!customTariffs[index]) return;
    customTariffs[index][name] = value;
    onChange(customTariffs);
  };

  const { hasFeature } = useFeatures();

  // TODO: Remove this once TariffProfile v2 feature is fully migrated.
  const supportNewTariffProfile = hasFeature('tariff-profiles-v2');

  const addNewCustomTariff = () => {
    onChange([
      ...customTariffs,
      {
        costSettings: {
          ac: {
            pricePerKwh: 0.2,
            pricePerHour: 0,
            pricePerSession: 0,
          },
          dc: {
            pricePerKwh: 0.2,
            pricePerHour: 0,
            pricePerSession: 0,
          },
        },
      },
    ]);
  };

  const removeCustomTariff = (index: number) => {
    const tariff = customTariffs[index];
    const filtered = customTariffs.filter((item) => {
      return item !== tariff;
    });
    onChange(filtered);
  };

  return (
    <React.Fragment>
      <Header>{t('editTariffProfile.customTariffs', 'Custom Tariffs')}</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{nameHeader}</Table.HeaderCell>
            {supportNewTariffProfile && (
              <Table.HeaderCell width={1}>
                {t('common.connectorType', 'Connector Type')}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell width={3}>
              {t('common.pricePerKwh', 'Price per kWh')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('common.pricePerSession', 'Price per Session')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('common.pricePerHour', 'Price per Hour')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t('common.actions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {customTariffs.map((tariff, index) => {
            return (
              <CustomTariffItem
                nameField={nameField}
                index={index}
                tariff={tariff}
                sameForAcDc={sameForAcDc}
                currency={currency}
                setCustomTariffField={setCustomTariffField}
                removeCustomTariff={removeCustomTariff}
              />
            );
          })}
        </Table.Body>
      </Table>
      <div>
        {(!maxLength || maxLength > customTariffs.length) && (
          <Button basic compact floated onClick={(e) => addNewCustomTariff()}>
            + Add Custom Tariff
          </Button>
        )}
      </div>
      <Divider horizontal />
    </React.Fragment>
  );
}

type CustomTariffItemProps = {
  index: number;
  nameField: string;
  tariff: TariffProfileCustomIssuerTariff;
  sameForAcDc: boolean;
  currency: string;
  setCustomTariffField: (index: number, name: string, value: any) => void;
  removeCustomTariff: (index: number) => void;
};

function CustomTariffItem({
  index,
  currency,
  tariff,
  sameForAcDc,
  setCustomTariffField,
  removeCustomTariff,
  nameField,
}: CustomTariffItemProps) {
  const [acPrices, setAcPrices] = useState({
    pricePerKwh: tariff.costSettings?.ac?.pricePerKwh?.toString() ?? '',
    pricePerSession: tariff.costSettings?.ac?.pricePerSession?.toString() ?? '',
    pricePerHour: tariff.costSettings?.ac?.pricePerHour?.toString() ?? '',
  });
  const [dcPrices, setDcPrices] = useState({
    pricePerKwh: tariff.costSettings?.dc?.pricePerKwh?.toString() ?? '',
    pricePerSession: tariff.costSettings?.dc?.pricePerSession?.toString() ?? '',
    pricePerHour: tariff.costSettings?.dc?.pricePerHour?.toString() ?? '',
  });

  const { hasFeature } = useFeatures();
  const supportNewTariffProfiles = hasFeature('tariff-profiles-v2');

  if (sameForAcDc) {
    return (
      <Table.Row key={'custom-tariff-row-ac-' + index}>
        <Table.Cell>
          <Input
            fluid
            value={tariff[nameField]}
            onChange={(e, { value }) =>
              setCustomTariffField(index, nameField, value)
            }
          />
        </Table.Cell>
        {supportNewTariffProfiles && <Table.Cell>AC/DC</Table.Cell>}
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(currency)}
            fluid
            value={dcPrices.pricePerKwh}
            onChange={(e, { value }) => {
              setDcPrices({ ...dcPrices, pricePerKwh: value });
              setAcPrices({ ...acPrices, pricePerKwh: value });

              const next = tariff.costSettings;
              if (!next) {
                return;
              }

              next.ac.pricePerKwh = +value;
              next.dc.pricePerKwh = +value;
              setCustomTariffField(index, 'costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(currency)}
            fluid
            value={dcPrices.pricePerSession}
            onChange={(e, { value }) => {
              setAcPrices({ ...acPrices, pricePerSession: value });
              setDcPrices({ ...dcPrices, pricePerSession: value });

              const next = tariff.costSettings;
              if (!next) {
                return;
              }
              next.ac.pricePerSession = +value;
              next.dc.pricePerSession = +value;
              setCustomTariffField(index, 'costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(currency)}
            fluid
            value={dcPrices.pricePerHour}
            onChange={(e, { value }) => {
              setDcPrices({ ...dcPrices, pricePerHour: value });
              setAcPrices({ ...acPrices, pricePerHour: value });

              const next = tariff.costSettings;

              if (!next) {
                return;
              }

              next.ac.pricePerHour = +value;
              next.dc.pricePerHour = +value;

              setCustomTariffField(index, 'costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Button
            icon="trash"
            basic
            onClick={() => removeCustomTariff(index)}
          />
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      <Table.Row key={'custom-tariff-row-ac-' + index}>
        <Table.Cell rowSpan={'2'}>
          <Input
            fluid
            value={tariff[nameField]}
            onChange={(e, { value }) =>
              setCustomTariffField(index, nameField, value)
            }
          />
        </Table.Cell>
        {supportNewTariffProfiles && <Table.Cell>AC</Table.Cell>}
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(currency)}
            fluid
            value={acPrices.pricePerKwh}
            onChange={(e, { value }) => {
              setAcPrices({ ...acPrices, pricePerKwh: value });

              const next = tariff.costSettings;

              if (!next) {
                return;
              }

              next.ac.pricePerKwh = +value;
              setCustomTariffField(index, 'costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(currency)}
            fluid
            value={acPrices.pricePerSession}
            onChange={(e, { value }) => {
              setAcPrices({ ...acPrices, pricePerSession: value });

              const next = tariff.costSettings;

              if (!next) {
                return;
              }

              next.ac.pricePerSession = +value;
              setCustomTariffField(index, 'costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(currency)}
            fluid
            value={acPrices.pricePerHour}
            onChange={(e, { value }) => {
              setAcPrices({ ...acPrices, pricePerHour: value });

              const next = tariff.costSettings;

              if (!next) {
                return;
              }

              next.ac.pricePerHour = +value;
              setCustomTariffField(index, 'costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell rowSpan={'2'}>
          <Button
            icon="trash"
            basic
            onClick={() => removeCustomTariff(index)}
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row key={'custom-tariff-row-dc-' + index}>
        {supportNewTariffProfiles && (
          <>
            <Table.Cell style={{ display: 'none' }} />
            <Table.Cell>DC</Table.Cell>
          </>
        )}
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(currency)}
            fluid
            value={dcPrices.pricePerKwh}
            onChange={(e, { value }) => {
              setDcPrices({ ...dcPrices, pricePerKwh: value });

              const next = tariff.costSettings;
              if (!next) {
                return;
              }
              next.dc.pricePerKwh = +value;
              setCustomTariffField(index, 'costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(currency)}
            fluid
            value={dcPrices.pricePerSession}
            onChange={(e, { value }) => {
              setDcPrices({ ...dcPrices, pricePerSession: value });

              const next = tariff.costSettings;
              if (!next) {
                return;
              }
              next.dc.pricePerSession = +value;
              setCustomTariffField(index, 'costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(currency)}
            fluid
            value={dcPrices.pricePerHour}
            onChange={(e, { value }) => {
              setDcPrices({ ...dcPrices, pricePerHour: value });

              const next = tariff.costSettings;
              if (!next) {
                return;
              }
              next.dc.pricePerHour = +value;
              setCustomTariffField(index, 'costSettings', next);
            }}
          />
        </Table.Cell>
      </Table.Row>
    </>
  );
}
