import React, { useCallback, useEffect, useState } from 'react';
import { compact, set, size } from 'lodash-es';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, Redirect, useHistory, Link } from 'react-router-dom';
import {
  Container,
  Form,
  Table,
  Header,
  Divider,
  HeaderSubheader,
  Label,
  Button,
  Message,
  Segment,
} from 'semantic';
import { Breadcrumbs, ErrorMessage, FeatureFlag } from 'components';
import { request } from 'utils/api';
import SelectionReviewModal from 'components/BulkActionsIsland/SelectionReviewModal';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH,
  EVSE_BACKGROUND_JOBS_BE_CHANGE_CONFIGURATION_PATH,
} from '../utils';
import { useToast, ToastButtonLayout } from 'components/Toast';
import { getEvseProtocols } from 'utils/evse-controllers';
import { EvseController, OCPPProtocolVersion } from 'types/evse-controller';
import ConfigurationInputList, {
  buildChangeConfigurationItem,
  ConfigurationItemForm,
  isConfigEmpty,
  validateConfig,
  newConfigurationItemId,
} from 'screens/EvseControllersBackgroundJobs/ChangeConfiguration/ConfigurationInputList';
import SelectPreset from './SelectPreset';
import { EvseConfigPreset } from 'types/evse-config-preset';
import useFetch from 'hooks/useFetch';
import {
  buildStandardConfigurationKeyOcpp16,
  buildStandardConfigurationKeyOcpp201,
  ProtocolResponse16,
  ProtocolResponse201,
} from './protocol';
import { ConfigurationInputType } from './ConfigurationInput';
import { FeatureFlags } from 'contexts/features';
import { EVSE_CONFIG_PRESET_BE_PATH } from 'screens/EvseConfigPreset/utils';

const selectionReviewEqual = (a: any, b: any) => a.id === b.id;

const selectionReviewColumns = [
  {
    title: <Table.HeaderCell>OCPP ID</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppIdentity}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Account</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.account?.name || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>OCPP Version</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppProtocolVersion || '-'}</Table.Cell>
    ),
  },
];

type LocationState = {
  evseControllers?: EvseController[];
  predefinedValues?: {
    parameters?: { configurationItems?: ConfigurationItemForm[] };
    note: string;
  };
};

type ViewState = {
  loading: boolean;
  apiError: Error | null;
  formError: Error | null;
};

type BulkChangeConfigurationForm = {
  chargingStationIds: string[];
  note: string;
};

export default function EvseControllersBackgroundJobsChangeConfiguration() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [viewState, setViewState] = useState<ViewState>({
    loading: false,
    apiError: null,
    formError: null,
  });

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const { evseControllers, predefinedValues } = (location.state ||
    {}) as LocationState;

  const [selectedItems, setSelectedItems] = useState(evseControllers || []);

  const [ocppProtocols, setOcppProtocols] = useState<OCPPProtocolVersion[]>(
    getEvseProtocols(selectedItems)
  );

  const [saveAsPreset, setSaveAsPreset] = useState(false);
  const [presetName, setPresetName] = useState('');

  const [configurationItems, setConfigurationItems] = useState<
    ConfigurationItemForm[]
  >(
    predefinedValues?.parameters?.configurationItems || [
      buildChangeConfigurationItem(),
    ]
  );

  const [bulkChangeConfiguration, setBulkChangeConfiguration] =
    useState<BulkChangeConfigurationForm>({
      note: predefinedValues?.note || '',
      chargingStationIds: selectedItems.map(
        (item) => item.ocppChargingStationId || ''
      ),
    });

  const setField = (name: string, value: any, nullable = false) => {
    const parsedValue = value === '' && nullable ? null : value;
    setBulkChangeConfiguration(
      set({ ...bulkChangeConfiguration }, name, parsedValue)
    );
  };

  const updatedSelectedItems = (items: EvseController[]) => {
    setSelectedItems(items);
    setField(
      'chargingStationIds',
      items.map((item) => item.ocppChargingStationId)
    );
    setOcppProtocols(getEvseProtocols(items));
  };

  const submitForm = useCallback(async () => {
    setViewState({ loading: true, apiError: null, formError: null });

    if (!bulkChangeConfiguration.chargingStationIds.length) {
      setViewState({
        loading: false,
        apiError: null,
        formError: new Error('At least one charging station is required'),
      });
      return;
    }
    if (!configurationItems.length) {
      setViewState({
        loading: false,
        apiError: null,
        formError: new Error('At least one configuration item is required'),
      });
      return;
    }

    const configValidationErrors = compact(
      configurationItems.map((cfgItem) =>
        validateConfig(cfgItem, ocppProtocols)
      )
    );
    if (configValidationErrors.length > 0) {
      setViewState({
        loading: false,
        apiError: null,
        formError: configValidationErrors[0],
      });
      return;
    }

    try {
      // Remove non-API fields
      const configurationItemsBody = configurationItems.map((item) => ({
        ...item,
        id: undefined,
        label: undefined,
        input: undefined,
        standardKeyId: undefined,
        edit: undefined,
      }));

      const [{ data }, _] = await Promise.all([
        request({
          method: 'POST',
          path: EVSE_BACKGROUND_JOBS_BE_CHANGE_CONFIGURATION_PATH,
          body: {
            ...bulkChangeConfiguration,
            configurationItems: configurationItemsBody,
          },
        }),
        saveAsPreset
          ? request({
              method: 'POST',
              path: EVSE_CONFIG_PRESET_BE_PATH,
              body: {
                isDefaultAutoConfiguration: false,
                name: presetName,
                configurationItems: configurationItemsBody,
              },
            })
          : Promise.resolve(),
      ]);

      toast.info(
        <ToastButtonLayout
          buttonTo={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}/${data.id}`}
          buttonTitle={t(
            'evseControllersBackgroundJobs.successToastView',
            'View'
          )}>
          <Trans
            i18nKey="evseControllersBackgroundJobsChangeConfiguration.toastSuccess"
            defaults="Job {{jobId}} <strong>Change Configuration</strong> is in progress. Go to Background Jobs page for details."
            values={{ jobId: data.id }}
          />
        </ToastButtonLayout>
      );
      setViewState({ loading: false, apiError: null, formError: null });
      history.push(CHARGING_STATIONS_FE_PATH);
    } catch (e: any) {
      setViewState({ loading: false, apiError: e, formError: null });
    }
  }, [bulkChangeConfiguration, configurationItems, saveAsPreset, presetName]);

  const { data: protocol16 } = useFetch<ProtocolResponse16>({
    path: '1/ocpp/1.6/protocol',
  });

  const { data: protocol201 } = useFetch<ProtocolResponse201>({
    path: '1/ocpp/2.0.1/protocol',
  });

  const handleSelectPresetOnChange = useCallback(
    (value?: EvseConfigPreset) => {
      // if preset is removed, we remove all configuration items from the preset
      if (!value) {
        setConfigurationItems(configurationItems.filter((c) => !c.presetId));
        return;
      }

      let configurationItemId = newConfigurationItemId(configurationItems);

      // we build the configuration items from the preset
      // and include only the ones that are valid for the selected charging stations ocpp version
      const presetConfigurationItems: ConfigurationItemForm[] =
        value.configurationItems
          .map((item) => {
            configurationItemId += 1;

            const fields = {
              edit: false,
              id: `${configurationItemId}`,
              label: item.name,
              name: item.name,
              value: item.value,
              component: item.component,
              presetId: value.id,
            };

            if (
              [OCPPProtocolVersion.OCPP16, OCPPProtocolVersion.OCPP15].includes(
                item.ocppProtocolVersion
              )
            ) {
              const config = protocol16?.CONFIGURATION_KEYS[item.name];
              if (config) {
                return {
                  ...fields,
                  input: ConfigurationInputType.STANDARD,
                  ocppProtocolVersion: OCPPProtocolVersion.OCPP16,
                  standardKeyId: buildStandardConfigurationKeyOcpp16(item.name),
                };
              }
            }

            if (item.ocppProtocolVersion === OCPPProtocolVersion.OCPP201) {
              const config = protocol201?.CONFIGURATION.find(
                (conf) =>
                  conf.variableName === item.name &&
                  conf.component === item.component?.name
              );
              if (config) {
                return {
                  ...fields,
                  input: ConfigurationInputType.STANDARD,
                  ocppProtocolVersion: OCPPProtocolVersion.OCPP201,
                  standardKeyId: buildStandardConfigurationKeyOcpp201(config),
                };
              }
            }

            return {
              ...fields,
              input: ConfigurationInputType.CUSTOM,
              ocppProtocolVersion: item.ocppProtocolVersion,
              standardKeyId: undefined,
            };
          })
          .filter((item) => ocppProtocols.includes(item.ocppProtocolVersion));

      // we remove first configuration item if it's empty when adding a preset
      if (
        size(presetConfigurationItems) > 0 &&
        size(configurationItems) > 0 &&
        isConfigEmpty(configurationItems[0])
      ) {
        configurationItems.shift();
      }

      setConfigurationItems([
        ...configurationItems,
        ...presetConfigurationItems,
      ]);
    },
    [configurationItems, protocol16, protocol201]
  );

  const saveAsPresetDisabled =
    size(configurationItems) === 0 || isConfigEmpty(configurationItems?.[0]);

  return selectedItems.length === 0 ? (
    <Redirect to={CHARGING_STATIONS_FE_PATH} />
  ) : (
    <Container>
      <Breadcrumbs
        path={[
          <Link key="backgroundjobs" to={CHARGING_STATIONS_FE_PATH}>
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.breadcrumbsChargingStations',
              'Charging Stations'
            )}
          </Link>,
        ]}
        active={t(
          'evseControllersBackgroundJobsChangeConfiguration.title',
          'Change OCPP Configuration'
        )}
      />
      <Header as="h2">
        {t(
          'evseControllersBackgroundJobsChangeConfiguration.title',
          'Change OCPP Configuration'
        )}
      </Header>
      <Divider hidden />

      <Form error={Boolean(viewState.formError)} onSubmit={submitForm}>
        <div>
          <SelectionReviewModal
            isOpen={isReviewModalOpen}
            onSetIsOpen={setIsReviewModalOpen}
            selectedItems={selectedItems}
            onSetSelectedItems={updatedSelectedItems}
            selectionReviewColumns={selectionReviewColumns}
            equal={selectionReviewEqual}
          />
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.reviewChargingStationTitle',
              '1. Review your selected charging station'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.reviewChargingStationSubTitle',
                'Once saved, the changes cannot be cancelled or undone.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Label
              size="large"
              content={`${selectedItems?.length} ${t(
                'evseControllersBackgroundJobsChangeConfiguration.chargingStations',
                'charging stations'
              )}`}
            />
            <Button
              basic
              content={t(
                'evseControllersBackgroundJobsChangeConfiguration.showSelectedChargingStations',
                'Show Selected'
              )}
              onClick={() => setIsReviewModalOpen(true)}
              type="button"
              icon="list"
            />
          </Segment>

          {ocppProtocols.length > 1 && (
            <Message>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.multiProtocolWarning',
                'Note: Different OCPP versions for charging stations detected. Only the variables (keys) for the relevant OCPP version will be updated.'
              )}
            </Message>
          )}
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.defineConfigurationTitle',
              '2. Define OCPP Configuration'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.defineConfigurationSubTitle',
                'Choose an OCPP Configuration variable you want to change, or create a new one. You can add and create multiple variables (keys).'
              )}
            </HeaderSubheader>
          </Header>

          <FeatureFlag feature={FeatureFlags.EvseConfigPresets}>
            <Segment>
              <SelectPreset onChange={handleSelectPresetOnChange} />
            </Segment>
          </FeatureFlag>

          <ConfigurationInputList
            items={configurationItems}
            ocppProtocols={ocppProtocols as OCPPProtocolVersion[]}
            onChange={(items: ConfigurationItemForm[]) =>
              setConfigurationItems(items)
            }
          />
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.addCustomNoteTitle',
              '3. Add custom note'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.addCustomNoteSubTitle',
                'Write a note to show together with the details of this command at the Background Jobs page.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Form.Input
              name="note"
              placeholder={t(
                'evseControllersBackgroundJobsChangeConfiguration.addCustomNotePlaceholder',
                'Type here...'
              )}
              label={t(
                'evseControllersBackgroundJobsChangeConfiguration.addCustomNoteInputLabel',
                'Note'
              )}
              value={bulkChangeConfiguration.note}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          </Segment>
        </div>

        <Divider />

        <FeatureFlag feature={FeatureFlags.EvseConfigPresets}>
          {!saveAsPresetDisabled && (
            <Segment style={{ background: '#f8f8f9' }}>
              <Form.Checkbox
                toggle
                label={t(
                  'evseControllersBackgroundJobsChangeConfiguration.saveAsPreset',
                  'Save as Configuration Preset.'
                )}
                style={{ fontWeight: 'normal' }}
                checked={saveAsPreset}
                onChange={(_, { checked }) => {
                  const checkedValue = !!checked;
                  setSaveAsPreset(checkedValue);
                  if (!checkedValue) {
                    setPresetName('');
                  }
                }}
              />
              {saveAsPreset && (
                <div style={{ marginTop: 20 }}>
                  <Form.Input
                    onChange={(e, { name, value }) => setPresetName(value)}
                    value={presetName}
                    required
                    placeholder={t(
                      'evseControllersBackgroundJobsChangeConfiguration.presetNamePlaceholder',
                      'Type here'
                    )}
                    label={t(
                      'evseControllersBackgroundJobsChangeConfiguration.presetNameLabel',
                      'Preset Name'
                    )}
                  />
                </div>
              )}
            </Segment>
          )}
        </FeatureFlag>

        <ErrorMessage error={viewState.apiError} />
        <ErrorMessage error={viewState.formError} />

        <Button
          loading={viewState.loading}
          disabled={viewState.loading}
          content={t(
            'evseControllersBackgroundJobsChangeConfiguration.executeChangeConfiguration',
            'Change Configuration'
          )}
          type="submit"
        />

        <Link to={viewState.loading ? '#' : '/charging-stations'}>
          <Button
            disabled={viewState.loading}
            content={t(
              'evseControllersBackgroundJobsChangeConfiguration.cancelUpdate',
              'Cancel'
            )}
            basic
            type="button"
          />
        </Link>
      </Form>
    </Container>
  );
}
