import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic';
import { LabelItem } from '.';
import { useSearchContext } from 'components/search/Context';
import ResourceLink from 'components/Link/ResourceLink';
import { utf8ToBase64 } from '../../../utils/url';
import LabelActions from './Actions';

interface EvseControllerLabelTableProps {
  writeAccess: boolean;
}

export default function LabelTable({
  writeAccess,
}: EvseControllerLabelTableProps) {
  const { t } = useTranslation();
  const { items, loading, reload } = useSearchContext<LabelItem>();

  if (!items.length || loading) return null;

  const displayType = (objectType: string) => {
    if (objectType === 'evse-controller') {
      return 'charging stations';
    }
    if (objectType === 'account') {
      return 'accounts';
    }
    if (objectType === 'user') {
      return 'users';
    }
    return objectType;
  };

  const linkRoute = (item: LabelItem) => {
    if (item.objectType === 'evse-controller') {
      return `/charging-stations?labels=${utf8ToBase64(item.label)}`;
    }
    if (item.objectType === 'account') {
      return `/accounts?labels=${utf8ToBase64(item.label)}`;
    }
    if (item.objectType === 'user') {
      return `/accounts/users?labels=${utf8ToBase64(item.label)}`;
    }
    throw new Error('invalid objectType');
  };

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>
            {t('globalManageLabels.tableColumns.label', 'Label')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('globalManageLabels.tableColumns.objectType', 'Object type')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('globalManageLabels.tableColumns.noOfObjects', 'No. of objects')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('globalManageLabels.tabel.actions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item: LabelItem) => {
          return (
            <Table.Row key={item.label}>
              <Table.Cell width={4}>{item.label}</Table.Cell>
              <Table.Cell width={4}>{displayType(item.objectType)}</Table.Cell>
              <Table.Cell width={4}>
                <ResourceLink
                  route={linkRoute(item)}
                  linkName={item.usage.toString()}
                  target="_blank"
                />
              </Table.Cell>
              <Table.Cell width={2} textAlign="center">
                <LabelActions
                  item={item}
                  writeAccess={writeAccess}
                  onSave={() => {
                    reload();
                  }}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
