import React, { useCallback, useEffect, useState } from 'react';
import { Message, Segment } from 'semantic-ui-react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import { Breadcrumbs, ErrorMessage } from 'components';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';
import {
  Container,
  Form,
  Table,
  Header,
  Divider,
  HeaderSubheader,
  Label,
  Button,
} from 'semantic';
import SelectionReviewModal from 'components/BulkActionsIsland/SelectionReviewModal';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH,
  EVSE_BACKGROUND_JOBS_BE_EXECUTE_COMMAND_PATH,
} from '../utils';
import { useToast, ToastButtonLayout } from 'components/Toast';
import useFetch from 'hooks/useFetch';
import { EvseController } from 'types/evse-controller';
import { OCPP_VERSION_16, OCPP_VERSION_201 } from 'utils/ocpp';
import JSONSchemaInputs from 'components/JSONSchemaInputs';
import { OCPPProtocolResponse } from 'types/ocpp-protocol';

type PredefinedParameters = {
  chargingStationId: string;
  call: {
    action: string;
    payload: string;
  };
};

type LocationState = {
  evseControllers?: EvseController[];
  predefinedValues?: {
    parameters?: { items?: PredefinedParameters[] };
    note: string;
  };
};

type ViewState = {
  loading: boolean;
  apiError: Error | null;
  formError: Error | null;
};

type BulkItemCommand = {
  chargingStationId: string | undefined;
  action: string;
  payload: Record<string, unknown>;
};

type BulkExecuteCommandForm = {
  commands: BulkItemCommand[];
  note: string;
};

function getSchema<T>(
  protocol: OCPPProtocolResponse | undefined,
  method: string | null
) {
  let schema = null;
  if (protocol && method) {
    const command = protocol.CENTRAL_SYSTEM_COMMANDS.find(
      (c) => c.name === method
    );
    schema = command ? command.requestDefinition : null;
  }
  return schema;
}

const selectionReviewEqual = (a: EvseController, b: EvseController) =>
  a.id === b.id;

const selectionReviewColumns = [
  {
    title: <Table.HeaderCell>OCPP ID</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppIdentity}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Account</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.account?.name || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>OCPP Version</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppProtocolVersion || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Firmware Version</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell textAlign="center">
        {item.bootInfo?.firmwareVersion || '-'}
      </Table.Cell>
    ),
  },
];

function decodeBase64Payload(base64String: string): Record<string, unknown> {
  try {
    const jsonString = atob(base64String);
    const parsedObject = JSON.parse(jsonString) as Record<string, unknown>;
    return parsedObject;
  } catch (error: any) {
    throw new Error('Invalid Base64 or JSON format');
  }
}

export default function EvseControllersBackgroundJobsExecuteCommand() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [viewState, setViewState] = useState<ViewState>({
    loading: false,
    apiError: null,
    formError: null,
  });

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const { evseControllers, predefinedValues } = (location.state ||
    {}) as LocationState;

  const [selectedItems, setSelectedItems] = useState(evseControllers || []);
  const [protocol, setProtocol] = useState<OCPPProtocolResponse>();
  const [bulkExecuteCommand, setBulkExecuteCommand] =
    useState<BulkExecuteCommandForm>({
      commands:
        predefinedValues?.parameters?.items?.map((item) => ({
          chargingStationId: item.chargingStationId,
          action: item.call.action || '',
          payload: decodeBase64Payload(item.call.payload),
        })) ||
        selectedItems?.map((evse) => ({
          chargingStationId: evse.ocppChargingStationId,
          action: '',
          payload: {} as Record<string, unknown>,
        })) ||
        [],
      note: predefinedValues?.note || '',
    });

  const selectedProtocolVersion = evseControllers?.[0]?.ocppProtocolVersion;
  if (selectedProtocolVersion) {
    switch (selectedProtocolVersion) {
      case OCPP_VERSION_16: {
        useFetch({
          path: '1/ocpp/1.6/protocol',
          onCompleted: ({ data }) => {
            setProtocol(data as OCPPProtocolResponse);
          },
        });
        break;
      }
      case OCPP_VERSION_201: {
        useFetch({
          path: '1/ocpp/2.0.1/protocol',
          onCompleted: ({ data }) => {
            setProtocol(data as OCPPProtocolResponse);
          },
        });
        break;
      }
    }
  }

  const selectedMethod = bulkExecuteCommand.commands?.[0]?.action || null;
  const selectedMethodParams = bulkExecuteCommand.commands?.[0]?.payload || {};
  const selectedSchema = getSchema(protocol, selectedMethod);

  const setField = (name: string, value: any, nullable = false) => {
    const parsedValue = value === '' && nullable ? null : value;

    switch (name) {
      case 'method':
        setBulkExecuteCommand((prevBulkExecuteCommand) => {
          return {
            ...prevBulkExecuteCommand,
            commands: prevBulkExecuteCommand.commands.map((command) => ({
              ...command,
              action: parsedValue,
              payload: {},
            })),
          };
        });
        break;
      case 'params':
        setBulkExecuteCommand((prevBulkExecuteCommand) => {
          return {
            ...prevBulkExecuteCommand,
            commands: prevBulkExecuteCommand.commands.map((command) => ({
              ...command,
              payload: parsedValue,
            })),
          };
        });
        break;
      default:
        setBulkExecuteCommand((prevBulkExecuteCommand) => {
          return {
            ...prevBulkExecuteCommand,
            [name]: parsedValue,
          };
        });
    }
  };

  const updateSelectedItems = (items: EvseController[]) => {
    setSelectedItems(items);
    setField(
      'commands',
      items.map((evse) => ({
        chargingStationId: evse.ocppChargingStationId,
        action: selectedMethod,
        payload: selectedMethodParams,
      }))
    );
  };

  const submitForm = useCallback(async () => {
    setViewState({ loading: true, apiError: null, formError: null });

    if (!selectedMethod) {
      setViewState({
        loading: false,
        apiError: null,
        formError: new Error('Selecting a command is required.'),
      });
      return;
    }

    try {
      const { data } = await request({
        method: 'POST',
        path: EVSE_BACKGROUND_JOBS_BE_EXECUTE_COMMAND_PATH,
        body: {
          ...bulkExecuteCommand,
          commands: bulkExecuteCommand.commands.map((command) => ({
            ...command,
            payload: JSONSchemaInputs.normalize(
              getSchema(protocol, command.action),
              command.payload
            ),
          })),
        },
      });

      toast.info(
        <ToastButtonLayout
          buttonTo={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}/${data.id}`}
          buttonTitle={t(
            'evseControllersBackgroundJobs.successToastView',
            'View'
          )}>
          <Trans
            i18nKey="evseControllersBackgroundJobsExecuteCommand.toastSuccess"
            defaults="Job {{jobId}} <strong>SetChargingProfile</strong> is in progress. Go to Background Jobs page for details."
            values={{ jobId: data.id }}
          />
        </ToastButtonLayout>
      );
      setViewState({ loading: false, apiError: null, formError: null });
      history.push(CHARGING_STATIONS_FE_PATH);
    } catch (e) {
      setViewState({ loading: false, apiError: e as Error, formError: null });
    }
  }, [bulkExecuteCommand]);

  return !selectedItems?.length ? (
    <Redirect to={CHARGING_STATIONS_FE_PATH} />
  ) : (
    <Container>
      <Breadcrumbs
        path={[
          <Link key="backgroundjobs" to={CHARGING_STATIONS_FE_PATH}>
            {t(
              'evseControllersBackgroundJobsExecuteCommand.breadcrumbsChargingStations',
              'Charging Stations'
            )}
          </Link>,
        ]}
        active={t(
          'evseControllersBackgroundJobsExecuteCommand.title',
          'Execute Commands'
        )}
      />
      <Header as="h2">
        {t(
          'evseControllersBackgroundJobsExecuteCommand.title',
          'Execute Commands'
        )}
      </Header>
      <Divider hidden />

      <Form error={Boolean(viewState.formError)} onSubmit={submitForm}>
        <div>
          <SelectionReviewModal
            isOpen={isReviewModalOpen}
            onSetIsOpen={setIsReviewModalOpen}
            selectedItems={selectedItems}
            onSetSelectedItems={updateSelectedItems}
            selectionReviewColumns={selectionReviewColumns}
            equal={selectionReviewEqual}
          />
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsExecuteCommand.reviewChargingStationTitle',
              '1. Review your selected charging station'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsExecuteCommand.reviewChargingStationSubTitle',
                'The executed commands cannot be reversed.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Label
              style={{ padding: '0.95em', margin: 0 }}
              content={`${selectedItems?.length} ${t(
                'evseControllersBackgroundJobsExecuteCommand.chargingStations',
                'charging stations'
              )}`}
            />
            <Button
              basic
              content={t(
                'evseControllersBackgroundJobsExecuteCommand.showSelectedChargingStations',
                'Show Selected'
              )}
              onClick={() => setIsReviewModalOpen(true)}
              type="button"
              icon="list"
            />
          </Segment>
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsExecuteCommand.selectCommandTitle',
              '2. Select command'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsExecuteCommand.selectCommandSubTitle',
                `Choose a command to execute for the ${selectedItems?.length} selected charging ${selectedItems?.length > 1 ? 'stations' : 'station'}.`
              )}
            </HeaderSubheader>
          </Header>

          <Segment>
            {protocol && (
              <Form.Select
                search
                value={selectedMethod}
                options={protocol.CENTRAL_SYSTEM_COMMANDS.map((command) => {
                  return {
                    key: command.name,
                    text: command.name,
                    value: command.name,
                  };
                })}
                name="method"
                label="Command"
                required
                onChange={(e, { name, value }) => setField(name, value)}
              />
            )}

            {protocol &&
              selectedMethod &&
              protocol.CENTRAL_SYSTEM_COMMAND_DESCRIPTIONS[selectedMethod] && (
                <Message
                  info
                  header="Command Information"
                  content={protocol.CENTRAL_SYSTEM_COMMAND_DESCRIPTIONS[
                    selectedMethod
                  ]
                    .split('\n')
                    .map((line) => {
                      return <p key={line}>{line}</p>;
                    })}
                />
              )}

            {selectedSchema && (
              <JSONSchemaInputs
                schema={selectedSchema}
                params={selectedMethodParams}
                onChange={(params) => setField('params', params)}
              />
            )}
          </Segment>
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsExecuteCommand.addCustomNoteTitle',
              '3. Add custom note'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsExecuteCommand.addCustomNoteSubTitle',
                'Write a note to show together with the details of this command at the Background Jobs page.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Form.Input
              style={{ width: '50%' }}
              name="note"
              placeholder={t(
                'evseControllersBackgroundJobsExecuteCommand.addCustomNotePlaceholder',
                'Type here...'
              )}
              label={t(
                'evseControllersBackgroundJobsExecuteCommand.addCustomNoteInputLabel',
                'Note'
              )}
              value={bulkExecuteCommand.note}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          </Segment>
        </div>

        <Divider />

        <ErrorMessage error={viewState.apiError} />
        <ErrorMessage error={viewState.formError} />

        <Button
          loading={viewState.loading}
          disabled={viewState.loading}
          content={t(
            'evseControllersBackgroundJobsExecuteCommand.executeExecuteCommand',
            'Run Command'
          )}
          type="submit"
        />

        <Link to={viewState.loading ? '#' : '/charging-stations'}>
          <Button
            disabled={viewState.loading}
            content={t(
              'evseControllersBackgroundJobsExecuteCommand.cancelExecuteCommand',
              'Cancel'
            )}
            basic
            type="button"
          />
        </Link>
      </Form>
    </Container>
  );
}
