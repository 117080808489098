import React from 'react';
import { Label, Table, Message } from 'semantic';
import { startCase } from 'lodash-es';
import countries from './countries';
import { formatDateTime } from './date';
import styled from 'styled-components';
import { CdrSources, CdrStatuses } from 'utils/constants';

/**
 * @typedef {import("../types/tariffprofile.d.ts").TariffProfile} TariffProfile
 * @typedef {import("react-i18next").UseTranslationResponse['t']} TFunction
 *
 */

export function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

function ceil(value, decimals) {
  return Number(Math.ceil(value + 'e' + decimals) + 'e-' + decimals);
}

export function formatNumber(value, locale) {
  if (!value && value !== 0) return '-'; // Otherwise its 'NaN'
  const formatter = new Intl.NumberFormat(locale);
  return formatter.format(value);
}

export function formatDecimalNumber(value, locale) {
  if (!value && value !== 0) return '-'; // Otherwise its 'NaN'
  const formatter = new Intl.NumberFormat(locale, { minimumFractionDigits: 2 });
  return formatter.format(value);
}

export const roundUpZeroDigits = (value) => {
  return ceil(value, 0);
};

export const roundUpTwoDigits = (value) => {
  return ceil(value, 2);
};

export const roundFourDigits = (value) => {
  return round(value || 0, 4);
};

export const numberWithCommas = (x) => {
  if (!x) return '0';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberWithDots = (x) => {
  if (!x) return '0';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const currencyToSymbolMap = {
  EUR: '\u20ac',
  USD: '$',
  DKK: 'Dkr',
  CHF: 'Fr.',
  GBP: '£',
  SEK: 'sk.',
  NOK: 'nk',
};

export const currencyToSymbol = (currency) => {
  if (!currency) {
    return currency;
  }
  return currencyToSymbolMap[currency.toUpperCase()] || `${currency} `;
};

/**
 * @param {number} value
 * @param {Object} [options]
 * @param {number} [options.precision = 2]
 * @param {boolean} [options.exact = false]
 * @param {boolean} [options.thousandSep = false]
 * @param {string} [options.currency = '']
 */
export const formatCurrency = (
  value,
  { precision = 2, exact = false, thousandSep = false, currency = '' } = {}
) => {
  if (isNaN(value)) return '-';

  let formatted;
  if (exact) {
    formatted = value.toString().replace('.', ',');
  } else {
    formatted = round(value, precision).toFixed(precision).replace('.', ',');
  }
  if (thousandSep) {
    formatted = numberWithDots(formatted);
  }
  currency = currency || '';
  return `${currencyToSymbol(currency)}${formatted}`;
};

export const formatEuro = (value, precision = 2, thousandSep = false) => {
  return formatCurrency(value, { precision, thousandSep, currency: 'EUR' });
};

const VAT_PERCENTAGE = 0.21;

export function calculateVat(amount, vatPercentage = VAT_PERCENTAGE) {
  return amount + amount * vatPercentage;
}

function autoFormatPrecision(number) {
  if (number > 1000) {
    return numberWithCommas(Math.round(number));
  }
  return number.toFixed(2);
}

export const calculateKwh = (value) => {
  if (!value) {
    return {
      kwhUnit: 'kWh',
      value: '0',
    };
  }
  let kwhUnit = 'pWh';
  let kwhValue = numberWithCommas(
    autoFormatPrecision(value / 1000 / 1000 / 1000 / 1000)
  );
  if (isNaN(value)) return { kwhValue, kwhUnit };
  if (value <= 90 * 1000 * 1000 * 1000 * 1000 * 1000) {
    kwhUnit = 'pWh';
    kwhValue = numberWithCommas(
      autoFormatPrecision(value / 1000 / 1000 / 1000 / 1000 / 1000)
    );
  }
  if (value <= 90 * 1000 * 1000 * 1000 * 1000) {
    kwhUnit = 'tWh';
    kwhValue = numberWithCommas(
      autoFormatPrecision(value / 1000 / 1000 / 1000)
    );
  }
  if (value <= 90 * 1000 * 1000 * 1000) {
    kwhUnit = 'gWh';
    kwhValue = autoFormatPrecision(value / 1000 / 1000);
  }
  if (value <= 90 * 1000 * 1000) {
    kwhUnit = 'mWh';
    kwhValue = autoFormatPrecision(value / 1000);
  }
  if (value <= 90 * 1000) {
    kwhUnit = 'kWh';
    kwhValue = autoFormatPrecision(value);
  }
  return { kwhUnit, kwhValue };
};

export const formatKwh = (value) => {
  return (Math.round(value * 100) / 100).toLocaleString('nl');
};

export const getExactAccountSyncStatus = (item) => {
  if (!item.exactAccountId) {
    return 'none';
  }
  if (
    item.exactAccountId &&
    item.billing.paymentMethod === 'autopay' &&
    !item.exactMandateId
  ) {
    return 'partial';
  }
  return 'full';
};

export const getExactCreditAccountSyncStatus = (item) => {
  if (!item.exactCreditAccountId) {
    return 'none';
  }
  // TODO(rewop): related to BILL-139. We may need to add the creditBilling.bicNo here
  // to mark the status as partial
  if (
    item.exactCreditAccountId &&
    item.creditBilling &&
    item.creditBilling.ibanNo &&
    !item.exactCreditMandateId
  ) {
    return 'partial';
  }
  return 'full';
};

export const getAccountingSyncStatus = (item) => {
  const services = {};
  services.exact = getExactAccountSyncStatus(item);
  services.exactCredit = getExactCreditAccountSyncStatus(item);
  let status = 'partial';
  if (services.exact === 'none') {
    status = 'none';
  }
  if (services.exact === 'full') {
    status = 'full';
  }
  return {
    services,
    status,
  };
};

export const formatAccountingSyncStatus = (status) => {
  if (status === 'none') {
    return <Label color="grey" content="Pending" />;
  }
  if (status === 'partial') {
    return <Label color="yellow" content="Partial" />;
  }
  return <Label color="olive" content="Good" />;
};

export const formatCardStatus = (status, t) => {
  if (status === 'active') {
    return <Label color="olive" content={t('status.active', 'Active')} />;
  }
  if (status === 'pending') {
    return <Label color="orange" content={t('status.pending', 'Pending')} />;
  }
  if (status === 'sent') {
    return <Label color="yellow" content={t('status.sent', 'Sent')} />;
  }
  return <Label color="grey" content={startCase(status)} />;
};

export const formatTokenStatus = (isActive, isBlocked, t) => {
  const out = [];

  const getLabel = (color, title, content) => (
    <Label color={color} title={title} content={content} />
  );

  if (isActive === true) {
    out.push(getLabel('olive', 'isActive', t('status.active', 'Active')));
  } else if (isActive === false) {
    out.push(getLabel('red', 'isActive', t('status.inActive', 'Inactive')));
  } else {
    out.push(getLabel('grey', 'isActive', t('status.unknown', 'Unknown')));
  }

  if (isBlocked === true) {
    out.push(getLabel('red', 'isBlocked', t('status.blocked', 'Blocked')));
  } else if (isBlocked === false) {
    out.push(
      getLabel('olive', 'isBlocked', t('status.unBlocked', 'Unblocked'))
    );
  } else {
    out.push(getLabel('grey', 'isBlocked', t('status.unknown', 'Unknown')));
  }

  return out;
};

export const formatRoles = (
  accountRoles,
  providerRoles,
  globalRoles = [],
  systemRoles = []
) => {
  return [
    ...accountRoles.map((r) => {
      return <Label key={r.role.id} content={r.role.name} />;
    }),
    ...providerRoles.map((r) => {
      return <Label key={r.role.id} content={r.role.name} color="teal" />;
    }),
    ...globalRoles.map((r) => {
      return <Label key={r.role.id} content={r.role.name} color="yellow" />;
    }),
    ...systemRoles.map((r) => {
      return <Label key={r.role.id} content={r.role.name} color="yellow" />;
    }),
  ];
};

export const truncate = (text, limit = 100) => {
  if (text.length > limit - 3) {
    return text.slice(0, limit - 3) + '...';
  }
  return text;
};

export const formatFullStatus = (ocpiStatus, eclearingStatus) => {
  const showStatus = (entry) => {
    if (entry.error) {
      return <Label content="Error" title={entry.error.message} color="red" />;
    }
    if (entry.success) return <Label content="OK" color="olive" />;
    if (!entry.pulledAt) return <Label content="Check Pending" color="grey" />;
    return <Label content="Unknown" />;
  };

  const formatState = (token) => {
    if (!token) return '-';
    // E-clearing
    if (token.EmtId) {
      return token.isExpired ? 'Inactive' : 'Active';
    }
    // OCPI
    if (token.valid) return 'Active';
    return 'Inactive';
  };

  if (!ocpiStatus) {
    return <Message content="No sync status yet" />;
  }

  const status = {
    Eclearing: eclearingStatus,
    ...ocpiStatus,
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ExternalProvider</Table.HeaderCell>
          <Table.HeaderCell>Push</Table.HeaderCell>
          <Table.HeaderCell>Pull</Table.HeaderCell>
          <Table.HeaderCell>State</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(status).map((key) => (
          <Table.Row key={key}>
            <Table.Cell>{key}</Table.Cell>
            <Table.Cell>{formatDateTime(status[key].pushedAt)}</Table.Cell>
            <Table.Cell>
              {status[key].pulledAt
                ? formatDateTime(status[key].pulledAt)
                : '-'}
            </Table.Cell>
            <Table.Cell>{formatState(status[key].pulledToken)}</Table.Cell>
            <Table.Cell>{showStatus(status[key])}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export function formatCoordinatorStatus(status) {
  let color = 'grey';
  if (status === 'active') {
    color = 'olive';
  }
  return <Label color={color} content={startCase(status)} />;
}

export function colorForSessionStatus(status) {
  if (status === 'COMPLETED') {
    return 'olive';
  }
  if (status === 'ACTIVE') {
    return 'blue';
  }
  return 'yellow';
}

export function formatSessionStatus(status) {
  return (
    <Label
      color={colorForSessionStatus(status)}
      content={startCase((status || 'Unknown').toLowerCase())}
    />
  );
}

export function colorForActiveSessionStatus(status) {
  if (status === 'PENDING') {
    return 'yellow';
  }
  if (status === 'ACTIVE') {
    return 'blue';
  }
  if (status === 'COMPLETED') {
    return 'olive';
  }
  if (status === 'INVALID') {
    return 'red';
  }
  return 'grey';
}

export function formatActiveSessionStatus(status) {
  return (
    <Label
      color={colorForActiveSessionStatus(status)}
      content={startCase((status || 'Unknown').toLowerCase())}
    />
  );
}

export function formatLocationAddress(location) {
  if (!location) return '-';
  const address = [];
  if (location.address) {
    address.push(location.address);
  }
  if (location.postal_code || location.postalCode) {
    address.push(location.postal_code || location.postalCode);
  }
  if (location.city) {
    address.push(location.city);
  }
  return address.join(', ');
}

export function formatSessionProvider(session) {
  const { rawRecord } = session;
  if (!rawRecord) return 'Unknown';
  const { tokenType, tokenVisualNumber } = rawRecord;
  if (tokenType === 'internal') {
    return 'E-Flux';
  }
  if (!tokenVisualNumber) {
    return 'External';
  }
  return tokenVisualNumber.replace(/-/g, '').slice(0, 5);
}

export function formatPowerType(powerType) {
  const props = {
    content: 'Unknown',
  };
  if (powerType) {
    props.content = powerType.toUpperCase();
  }
  return <Label {...props} />;
}

export function formatCostOverride(costSetting) {
  if (!costSetting.override) {
    return 'None';
  }
  if (costSetting.enablePublicFreeCharging) {
    return 'Free Public Charging';
  }
  if (costSetting.accessGroupName) {
    let title = `Access Group: ${costSetting.accessGroupName}`;
    if (costSetting.accessGroupMemberName) {
      title += ` (${costSetting.accessGroupMemberName})`;
    }
    return title;
  }
  return 'Unknown';
}

export function formatIntegrityDescriptions(classification) {
  const props = {
    title: `${startCase(classification)} Integrity Classification`,
  };
  if (classification === 'excellent') {
    props.text =
      'Attribute validation passed, no fraud, secure connection and authorization took place';
  } else if (classification === 'bad') {
    props.text = 'Attribute validation did not pass or fraud was detected';
  } else if (classification === 'average') {
    props.text =
      'Attribute validation passed, no fraud, but location was public and EVSE connectivity mode was `public-plaintext` with no authorization';
  } else {
    props.text =
      'Attribute validation passed, no fraud, but location was private or connectivity took place through a secure connection';
  }
  return props;
}

export function formatIntegrityClassification(classification) {
  const props = {
    content: startCase(classification),
  };

  if (classification === 'excellent') {
    props.color = 'olive';
  } else if (classification === 'bad') {
    props.color = 'red';
  } else if (classification === 'average') {
    props.color = 'yellow';
  } else {
    props.color = 'olive';
  }

  return <Label {...props} />;
}

export function formatSessionSyncStatus(session) {
  const { syncEclearingStatus, syncOcpiStatus } = session;
  if (!syncEclearingStatus && !syncOcpiStatus) {
    return <Label content="Pending" />;
  }
  let error = null;
  if (syncOcpiStatus) {
    Object.keys(syncOcpiStatus).map((key) => {
      const status = syncOcpiStatus[key];
      if (status && status.error) {
        error = status.error;
      }
    });
  }
  if (syncEclearingStatus && syncEclearingStatus.error) {
    error = syncEclearingStatus.error;
  }
  if (error) {
    return <Label content="Error" title={error.message} color="red" />;
  }

  let title = '';
  if (syncEclearingStatus && syncEclearingStatus.pushedAt) {
    title = `Last pushed at ${formatDateTime(syncEclearingStatus.pushedAt)}`;
  }
  //if (syncOcpiStatus && syncEclearingStatus) {
  return <Label content="Synced" color="olive" title={title} />;
  //}
  // return <Label content="Partial" color="yellow" />;
}

const StyledLabel = styled(Label)`
  && {
    transition: transform 0.1s ease-in-out;
  }
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transition: transform 0.05s ease-out;
    transform: scale(0.95);
  }
`;

export function formatSyncedRoamingNetworkCell(session) {
  if (!session) {
    return '-';
  }

  if (session.cdrSyncedToRoamingNetworkAt) {
    return formatDateTime(session.cdrSyncedToRoamingNetworkAt);
  }

  if (!session.externalSync) {
    return '-';
  }

  let syncs = session.externalSync;
  if (syncs.length === 0) {
    return '-';
  }

  syncs = syncs.filter((sync) => sync.name === 'push-ocpi-session');
  let syncedAt;
  syncs.forEach((sync) => {
    sync.states.forEach((state) => {
      if (state.status === 'SYNCED') {
        syncedAt = state.lastSynced;
      }
    });
  });

  if (syncedAt) {
    return formatDateTime(syncedAt);
  }

  return '-';
}

export function formatSyncStatusCell(
  instance,
  modelName,
  syncName,
  Wrapper,
  wrapperOptions = {}
) {
  if (!instance.externalSync) {
    return;
  }
  let syncs = instance.externalSync;

  if (syncName !== '') {
    syncs = syncs.filter((obj) => obj.name === syncName);
  }

  if (syncs.length === 0) {
    return;
  }

  const statuses = syncs.reduce((previous, sync) => {
    previous.push(...sync.states.map((state) => state.status));
    return previous;
  }, []);

  const Label = StyledLabel;

  const isSuccess = statuses.every((s) => s === 'SYNCED');
  const isFailure = statuses.every((s) => s === 'FAILED');
  const isUnqualified = statuses.every((s) => s === 'UNQUALIFIED');
  const isRequested = statuses.every(
    (s) => s === 'REQUESTED' || s === 'REQUESTED_FORCE'
  );
  const isPending = statuses.every((s) => s === 'PENDING');
  let label = <Label content="Partial" color="yellow" />;
  if (isUnqualified) {
    label = (
      <Label
        content="Unqualified"
        color="black"
        title="Object is deemed as unqualified for syncing, see Sync Log for details"
      />
    );
  }
  if (isSuccess) {
    label = <Label content="Synced" color="olive" title="Last pushed" />;
  }
  if (isFailure) {
    label = <Label content="Failed" color="red" title="Error message" />;
  }
  if (isRequested) {
    label = (
      <Label
        content="Updating"
        color="grey"
        title="Will be synced in next cycle"
      />
    );
  }
  if (isPending) {
    label = <Label content="Pending" color="grey" title="Pending" />;
  }
  label = <div style={{ cursor: 'pointer' }}>{label}</div>;
  if (Wrapper) {
    wrapperOptions.externalSyncJobName = syncName;
    return (
      <Wrapper
        data={instance}
        modelName={modelName}
        trigger={label}
        size="fullscreen"
        {...wrapperOptions}
      />
    );
  } else {
    return label;
  }
}

export function formatCdrStatusCell(status) {
  switch (status) {
    case CdrStatuses.PENDING:
      return <Label content="Pending" color="grey" title="Pending" />;
    case CdrStatuses.ACCEPTED:
      return <Label content="Accepted" color="yellow" title="Accepted" />;
    case CdrStatuses.REJECTED:
      return <Label content="Rejected" color="red" title="Rejected" />;
    case CdrStatuses.BILLED:
      return <Label content="Billed" color="olive" title="Billed" />;
  }
}

export function formatCdrSourceCell(source) {
  switch (source) {
    case CdrSources.UNSPECIFIED:
      return <Label content="Unspecified" color="grey" title="Unspecified" />;
    case CdrSources.OCHP_14:
      return <Label content="OCHP 1.4" color="grey" title="OCHP 1.4" />;
    case CdrSources.OCPI_211_PULL:
      return (
        <Label content="OCPI 2.1.1 Pull" color="grey" title="OCPI 2.1.1 Pull" />
      );
    case CdrSources.OCPI_211_PUSH:
      return (
        <Label content="OCPI 2.1.1 Push" color="grey" title="OCPI 2.1.1 Push" />
      );
    case CdrSources.GIREVE_211_PULL:
      return (
        <Label
          content="Gireve 2.1.1 Pull"
          color="grey"
          title="Gireve 2.1.1 Pull"
        />
      );
    case CdrSources.GIREVE_211_PUSH:
      return (
        <Label
          content="Gireve 2.1.1 Push"
          color="grey"
          title="Gireve 2.1.1 Push"
        />
      );
    case CdrSources.EVIOLIN_161_CSV:
      return (
        <Label
          content="Eviolin 1.6.1 CSV"
          color="grey"
          title="Eviolin 1.6.1 CSV"
        />
      );
  }
}

export function formatFinanceExportsJobStatus(status) {
  const statuses = {
    pending: {
      content: 'Pending',
      color: 'yellow',
    },
    failed: {
      content: 'Failed',
      color: 'red',
    },
    finished: {
      content: 'Finished',
      color: 'green',
    },
    busy: {
      content: 'Busy',
      color: 'orange',
    },
  };

  if (!statuses[status]) {
    return null;
  }

  return (
    <Label content={statuses[status].content} color={statuses[status].color} />
  );
}

export function getSyncLabelFromState(syncState) {
  switch (syncState) {
    case 'SYNCED':
      return <Label content="Synced" color="olive" title="Last pushed" />;
    case 'FAILED':
      return <Label content="Failed" color="red" title="Error message" />;
    case 'REQUESTED':
      return (
        <Label
          content="Updating"
          color="grey"
          title="Will be synced in next cycle"
        />
      );
    case 'REQUESTED_FORCE':
      return (
        <Label
          content="Updating"
          color="grey"
          title="Will be synced in next cycle"
        />
      );
    case 'PENDING':
      return <Label content="Pending" color="grey" title="Pending" />;
    case 'UNQUALIFIED':
      return (
        <Label
          content="Unqualified"
          color="black"
          title="Object is deemed as unqualified for syncing, see Sync Log for details"
        />
      );
    default:
      return <Label content="Partial" color="yellow" />;
  }
}

export function formatLocationSyncStatus(location) {
  const { syncEclearingStatus, syncOcpiStatus, isPublished } = location;
  if (!isPublished) {
    return '-';
  }
  if (!syncEclearingStatus && !syncOcpiStatus) {
    return <Label content="Pending" />;
  }
  let error = null;
  if (syncOcpiStatus) {
    Object.keys(syncOcpiStatus).map((key) => {
      const status = syncOcpiStatus[key];
      if (status && status.error) {
        error = status.error;
      }
    });
  }
  if (syncEclearingStatus && syncEclearingStatus.error) {
    error = syncEclearingStatus.error;
  }
  if (error) {
    return <Label content="Error" title={error.message} color="red" />;
  }

  let title = '';
  if (syncEclearingStatus && syncEclearingStatus.pushedAt) {
    title = `Last pushed at ${formatDateTime(syncEclearingStatus.pushedAt)}`;
  }
  if (syncOcpiStatus && syncEclearingStatus) {
    return <Label content="Synced" color="olive" title={title} />;
  }
  return <Label content="Partial" color="yellow" />;
}

export function formatEvseId(evseId) {
  evseId = (evseId || '').replace(/\*/g, '');
  return `${evseId.slice(0, 2)}*${evseId.slice(2, 5)}*${evseId.slice(
    5,
    7
  )}*${evseId.slice(7)}`;
}

// Format an Emi3Contract to a ContractId
// Empty or missing key fields are replaced with asterisks
export function formatContractId(emi3Contract) {
  let { checksumDigit, countryCode, emaInstance, idType, partyId } =
    emi3Contract;
  countryCode = !countryCode ? '**' : countryCode;
  partyId = !partyId ? '***' : partyId;
  checksumDigit = !checksumDigit ? '*' : checksumDigit;
  return `${countryCode}-${partyId}-${idType}${emaInstance}-${checksumDigit}`;
}

export function formatDurationWithUnit(durationSeconds, t) {
  const durationMinutes = Math.abs(durationSeconds) / 60;
  if (durationMinutes > 500 * 60 * 24) {
    return {
      value: Math.round(durationMinutes / 60 / 24 / 356),
      unit: t('formatDuration.years', 'years'),
    };
  }
  if (durationMinutes > 48 * 60) {
    return {
      value: Math.round(durationMinutes / 60 / 24),
      unit: t('formatDuration.days', 'days'),
    };
  }
  if (durationMinutes > 90) {
    return {
      value: Math.round(durationMinutes / 60),
      unit: t('formatDuration.hours', 'hours'),
    };
  }
  if (durationMinutes < 1) {
    return {
      value: Math.round(durationSeconds),
      unit: t('formatDuration.seconds', 'seconds'),
    };
  }
  return {
    value: Math.round(durationMinutes),
    unit: t('formatDuration.minutes', 'minutes'),
  };
}

export function safeFileName(str) {
  return str.replace(/[^a-z0-9]/gi, '_').toLowerCase();
}

export function formatPhone(phoneNumber, phoneCountryCode) {
  if (!phoneNumber) {
    return '0';
  }

  if (phoneCountryCode) {
    const { callingCode } = countries.find(
      (c) => c.countryCode === phoneCountryCode
    );
    if (callingCode) {
      return `+${callingCode} ${phoneNumber}`;
    }
  }
  return phoneNumber;
}

export function formatTariff(tariff, currencyOverwrite) {
  const currency = tariff.currency ?? currencyOverwrite;
  const { pricePerKwh, pricePerHour, pricePerSession } = tariff;
  const lines = [];
  if (pricePerKwh) {
    lines.push(`${formatCurrency(tariff.pricePerKwh, { currency })}/kWh`);
  }
  if (pricePerHour) {
    lines.push(`${formatCurrency(tariff.pricePerHour, { currency })}/Hour`);
  }
  if (pricePerSession) {
    lines.push(
      `${formatCurrency(tariff.pricePerSession, { currency })}/Session`
    );
  }
  return lines.join(', ');
}

/**
 *
 * @param {TariffProfile} tariffProfile
 * @param {TFunction} t
 * @returns {string}
 */
export function formatTariffProfile(tariffProfile, t) {
  const lines = [];
  lines.push(
    t(
      `editEvse.tariffProfile.baseTariff`,
      `Tariff profile has a base tariff of {{TariffDetails}}.`,
      { TariffDetails: formatTariff(tariffProfile) }
    )
  );
  if (tariffProfile.enabledCustomTariffs === 'issuer') {
    tariffProfile.customIssuerTariffs.forEach((item) => {
      lines.push(
        t(
          'editEvse.tariffProfile.customIssuer',
          `Cards by issuer "{{IssuerName}}" have a tariff set of {{TariffDetails}}`,
          {
            issuerName: item.issuerName,
            TariffDetails: formatTariff(item, tariffProfile.currency),
          }
        )
      );
    });
  }
  if (tariffProfile.enabledCustomTariffs === 'infra-token-provider') {
    tariffProfile.customInfraTokenProviderTariffs.forEach((item) => {
      lines.push(
        t(
          'editEvse.tariffProfile.customTariffs',
          `Cards by infra token provider "{{InfraTokenProvider}}" have a tariff set of {{TariffDetails}}`,
          {
            InfraTokenProvider: item.infraTokenProvider,
            TariffDetails: formatTariff(item, tariffProfile.currency),
          }
        )
      );
    });
  }
  lines.push('\n');
  if (tariffProfile.mspTokenBillingPlanTariffs?.length) {
    for (const bpt of tariffProfile.mspTokenBillingPlanTariffs) {
      lines.push(
        t(
          'editEvse.tariffProfile.customMspCardBilling',
          `Cards with billing plan "{{BillingPlanName}}" have a base tariff set of {{TariffDetails}}.\n`,
          {
            BillingPlanName: bpt.mspTokenBillingPlanId?.details?.en?.name,
            TariffDetails: formatTariff(
              bpt.costSettings?.dc,
              tariffProfile.currency
            ),
          }
        )
      );
    }
  }
  return lines.join(' ');
}

export const translateExcludedReason = (reason, t) => {
  switch (reason) {
    case 'HIGH_ENERGY_USAGE':
      return t(
        'sessions.excludedReasons.HIGH_ENERGY_USAGE',
        'The session used over 1000kWh of energy.'
      );
    case 'LOW_ENERGY_USAGE':
      return t(
        'sessions.excludedReasons.LOW_ENERGY_USAGE',
        'The session used less than 0.2kWh of energy.'
      );
    case 'MANUAL_EXCLUSION':
      return t(
        'sessions.excludedReasons.MANUAL_EXCLUSION',
        'The session was manually excluded from billing.'
      );
    case 'IMPROBABLE_ENERGY_USAGE':
      return t(
        'sessions.excludedReasons.IMPROBABLE_ENERGY_USAGE',
        'The session used an improbable (more than 10kw per minute) amount of energy for its duration.'
      );
    case 'NO_COST':
      return t(
        'sessions.excludedReasons.NO_COST',
        'The session had no cost associated with it.'
      );
    case 'HIGH_COST':
      return t(
        'sessions.excludedReasons.HIGH_COST',
        'The session cost more than 1000€.'
      );
    case 'TOO_OLD':
      return t(
        'sessions.excludedReasons.TOO_OLD',
        'The session is older than 90 days. (3 months ago from the start of this month).'
      );
    case 'LOW_DURATION':
      return t(
        'sessions.excludedReasons.LOW_DURATION',
        'The session was less than 2 minutes long.'
      );
    case 'HISTORICAL_END':
      return t(
        'sessions.excludedReasons.HISTORICAL_END',
        'The session was back-dated and cannot be billed.'
      );
    case 'NON_ACCEPTED_AUTH':
      return t(
        'sessions.excludedReasons.NON_ACCEPTED_AUTH',
        'The session was started with a token that was not authorized to charge at this station.'
      );
    case 'MAINTENANCE_TOKEN':
      return t(
        'sessions.excludedReasons.MAINTENANCE_TOKEN',
        'The session was created using a system maintenance token.'
      );
    case 'PSP_PAYMENT':
      return t(
        'sessions.excludedReasons.PSP_PAYMENT',
        'The session was paid by payment instrument.'
      );
    case 'DUPLICATE':
      return t(
        'sessions.excludedReasons.DUPLICATE',
        'The session was a duplicate of another session.'
      );
    case 'OTHER':
      return t(
        'sessions.excludedReasons.OTHER',
        'The session was excluded for an unknown reason.'
      );
    case 'INVALID_START_TIME':
      return t(
        'sessions.excludedReasons.INVALID_START_TIME',
        'The session was started over 5 years ago, or in the future'
      );
    case 'INVALID_END_TIME':
      return t(
        'sessions.excludedReasons.INVALID_END_TIME',
        'The session was ended over 5 years ago, or in the future'
      );
    default:
      return reason;
  }
};

export const formatSessionExcludedReason = (reason, t) => (
  <Table definition>
    <Table.Body>
      <Table.Row>
        <Table.Cell>
          {t('sessions.excludedReason', 'Excluded Reason')}
        </Table.Cell>
        <Table.Cell>{translateExcludedReason(reason, t)}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
