import { useTranslation } from 'react-i18next';
import { AppWrapper } from 'components';
import { Container, Message } from 'semantic';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import {
  DATA_IMPORTS_FE_PATH,
  hasDataImportPermissions,
} from 'screens/DataImports/utils';
import DataImportsList from 'screens/DataImports/List';
import DataImportManager from 'screens/DataImports/DataImportManager';

export default function DataImports() {
  const { t } = useTranslation();
  const hasReadAccess = hasDataImportPermissions('read');
  const hasWriteAccess = hasDataImportPermissions('write');

  return (
    <AppWrapper>
      {!hasReadAccess && (
        <Message
          error
          content={t(
            'dataImports.noFeature',
            'You do not have access to this feature.'
          )}
        />
      )}

      {hasReadAccess && (
        <Container>
          <Switch>
            <Route
              path={DATA_IMPORTS_FE_PATH}
              component={DataImportsList}
              exact
            />
            {hasWriteAccess && (
              <Route
                path={`${DATA_IMPORTS_FE_PATH}/:id`}
                component={DataImportManager}
                exact
              />
            )}
          </Switch>
        </Container>
      )}
    </AppWrapper>
  );
}
