import React, { useContext } from 'react';
import { Search } from 'components';
import { Table } from 'semantic';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/date';
import { Link } from 'react-router-dom';
import {
  ACCOUNTS_FE_PATH,
  CHARGING_STATIONS_FE_PATH,
  CPO_LOCATIONS_FE_PATH,
  DATA_IMPORTS_FE_PATH,
  renderDataImportFileType,
  StatusLabel,
  TOKENS_FE_PATH,
  USERS_FE_PATH,
} from 'screens/DataImports/utils';
import { DataImport, DataImportFileType } from 'types/data-import';

export default function TableView() {
  const { items, loading } = useContext(Search.Context);
  const { t } = useTranslation();

  if (!items.length || loading) return null;

  const dataImports: DataImport[] = items;

  const filterUrlByType = (fileType: DataImportFileType) => {
    switch (fileType) {
      case 'accounts':
        return ACCOUNTS_FE_PATH;
      case 'users':
        return USERS_FE_PATH;
      case 'locations':
        return CPO_LOCATIONS_FE_PATH;
      case 'evses':
      case 'connectors':
        return CHARGING_STATIONS_FE_PATH;
      case 'virtual-cards':
        return TOKENS_FE_PATH;
    }
  };

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} singleLine>
            {t('dataImports.columnImportId', 'Import ID')}
          </Table.HeaderCell>
          <Table.HeaderCell width={4} singleLine>
            {t('dataImports.columnNumberOfItems', 'N of Items Imported')}
          </Table.HeaderCell>
          <Table.HeaderCell width={4} singleLine>
            {t('dataImports.columnDescription', 'Description')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2} singleLine>
            {t('dataImports.columnImportedAt', 'Imported At')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2} singleLine>
            {t('dataImports.columnImportedBy', 'Imported By')}
          </Table.HeaderCell>
          <Table.HeaderCell width={3} singleLine>
            {t('dataImports.columnStatus', 'Status')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {dataImports.map((item) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell singleLine>
                <Link to={`${DATA_IMPORTS_FE_PATH}/${item.id}`}>
                  {item.id?.slice(-6)}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Table celled size="small" basic="very" textAlign="right">
                  <Table.Body>
                    {item.files.map((file) => {
                      return (
                        <Table.Row>
                          <Table.Cell width={8}>
                            {renderDataImportFileType(t, file.type)}
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            <Link
                              to={`${filterUrlByType(file.type)}?importId=${item.id}`}
                              target="_blank">
                              {file.fileMetrics?.totalCount || 'N/A'}
                            </Link>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Table.Cell>
              <Table.Cell>{item.note}</Table.Cell>
              <Table.Cell singleLine>
                {formatDateTime(item.createdAt)}
              </Table.Cell>
              <Table.Cell singleLine>
                {item.creatorUser && (
                  <Link
                    to={`${USERS_FE_PATH}/${item.creatorUser.id}`}
                    target="_blank">
                    {item.creatorUser.contact?.firstName}{' '}
                    {item.creatorUser.contact?.lastName}
                  </Link>
                )}
                {item.createdBy && (
                  <Link
                    to={`${USERS_FE_PATH}?searchPhrase=${item.createdBy}`}
                    target="_blank">
                    {item.createdBy}
                  </Link>
                )}
              </Table.Cell>
              <Table.Cell>
                <StatusLabel status={item.status} />
                <p style={{ fontSize: 12, color: 'grey', paddingTop: 5 }}>
                  {item.files
                    .map((file) => {
                      if (!file.fileMetrics?.statusesCount?.failed) return;

                      return `${file.fileMetrics?.statusesCount?.failed} ${renderDataImportFileType(t, file.type)} failed`;
                    })
                    .filter(Boolean)
                    .join(', ')}
                </p>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
