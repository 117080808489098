import { AccountPlatformFeatureProvider } from 'components/account-platform-features/context';
import React from 'react';
import { Header } from 'semantic';
import {
  CustomMspCardBillingPlanTariff,
  TariffProfile,
} from 'types/tariffprofile';
import { TariffProfileFeatureProvider } from './TariffProfileFeatureProvider';
import { useUser } from 'contexts/user';
import { set } from 'lodash';
import { useTranslation } from 'react-i18next';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import { request } from 'utils/api';
import { TariffCostSettings } from './TariffCostSettings';
import { ActionButtonWithIcon } from '../../semantic/ActionButtonWithIcon';

interface SpecialMspCardBillingPlanTariffsProps {
  formValues: TariffProfile;
  onChange: (next: CustomMspCardBillingPlanTariff[]) => void;
  sameForAcDc: boolean;
}

export default function SpecialMspCardBillingPlanTariffs({
  formValues,
  onChange,
  sameForAcDc,
}: SpecialMspCardBillingPlanTariffsProps) {
  const { user } = useUser();
  const { t } = useTranslation();

  const addTariff = () => {
    onChange([
      ...(formValues.mspTokenBillingPlanTariffs || []),
      {
        mspTokenBillingPlanId: '',
        costSettings: {
          ac: {
            pricePerSession: 0,
            pricePerHour: 0,
            pricePerKwh: 0,
          },
          dc: {
            pricePerSession: 0,
            pricePerHour: 0,
            pricePerKwh: 0,
          },
        },
      },
    ]);
  };

  const removeTariff = (id: string) => () => {
    onChange(
      formValues.mspTokenBillingPlanTariffs.filter(
        (t) => t.mspTokenBillingPlanId != id
      )
    );
  };

  const setField =
    (tariff: CustomMspCardBillingPlanTariff) => (field: string, value: any) => {
      set(tariff, field, value);
    };

  type Selected = {
    ac: string;
    dc: string;
  };

  const setActiveTariffSystem =
    (tariff: CustomMspCardBillingPlanTariff) => (e: Selected) => {
      if (e.ac !== 'scheduled') {
        tariff.costSettings.ac.components = [];
      }

      if (e.dc !== 'scheduled') {
        tariff.costSettings.dc.components = [];
      }

      tariff.costSettings.ac.enableEnergyMarketTracking = e.ac === 'dynamic';
      tariff.costSettings.dc.enableEnergyMarketTracking = e.dc === 'dynamic';

      if (e.ac !== 'dynamic') {
        tariff.costSettings.ac.surchargePerKwh = 0;
      }

      if (e.dc !== 'dynamic') {
        tariff.costSettings.dc.surchargePerKwh = 0;
      }
    };

  if (!user) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Header style={{ marginTop: '2rem' }}>
        {t(
          'specialMspTariffs.specialTariffs',
          'Special Tariff for Consumer card billing plans'
        )}
      </Header>
      {formValues.mspTokenBillingPlanTariffs?.map((tariff, index) => {
        return (
          <div
            style={{
              border: 'rgba(29, 31, 33, 0.1) solid 1px',
              padding: 10,
              marginBottom: 15,
              borderRadius: '.25rem',
            }}
            key={`custom-msp-tariff-${tariff.mspTokenBillingPlanId}-${index}`}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <h4
                style={{
                  margin: 'auto 0',
                }}>
                AC/DC
              </h4>
              {formValues.mspTokenBillingPlanTariffs.length > 1 && (
                <ActionButtonWithIcon
                  text={t('specialMspTariffs.remove', 'Remove')}
                  icon="trash-can regular"
                  onClick={removeTariff(tariff.mspTokenBillingPlanId)}
                />
              )}
            </div>
            <h4>
              {t(
                'specialMspTariffs.selectPlan',
                'Select Consumer card billing plan'
              )}
            </h4>
            <SearchDropdown
              keywordField="name"
              objectMode={false}
              getOptionLabel={(item) => item.name}
              value={tariff.mspTokenBillingPlanId}
              onDataNeeded={(body) => {
                return request({
                  method: 'POST',
                  path: '/1/billing-plans/search',
                  body: {
                    ...body,
                    limit: 50,
                    type: 'consumer',
                    providerContext: 'msp',
                    sort: {
                      field: 'name',
                      order: 'asc',
                    },
                  },
                });
              }}
              onChange={(e, { value }) => {
                tariff.mspTokenBillingPlanId = value;
                onChange(formValues.mspTokenBillingPlanTariffs);
              }}
            />
            <AccountPlatformFeatureProvider accountId={user.accountId}>
              <TariffProfileFeatureProvider>
                <TariffCostSettings
                  sameForAcDc={sameForAcDc}
                  formValues={{
                    ...formValues,
                    costSettings: tariff.costSettings,
                  }}
                  setField={setField(tariff)}
                  setActiveTariffSystem={setActiveTariffSystem(tariff)}
                  hideHeader={true}
                />
              </TariffProfileFeatureProvider>
            </AccountPlatformFeatureProvider>
          </div>
        );
      })}
      <ActionButtonWithIcon
        text={t('specialMspTariffs.add', 'Add Special Tariff')}
        icon="plus"
        onClick={addTariff}
      />
    </React.Fragment>
  );
}
