import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { request } from 'utils/api';
import { Table } from 'semantic';
import Currency from 'components/Currency';
import { roundUpTwoDigits } from 'utils/formatting';
import React from 'react';

const feeTypes = {
  CPOKWHUsageFee: 'cpo_kwh_usage_fee',
};

type BillingCharge = {
  type: string;
  amount: number;
  currency: string;
};

type Props = {
  sessionId?: string;
};

export default function CPOSessionDetailsAdditionalCharges(props: Props) {
  const { t } = useTranslation();

  const { data } = useQuery<BillingCharge[], unknown, BillingCharge[]>(
    ['/2/sessions/cpo/:session/additional-charges', props.sessionId],
    async () => {
      if (!props.sessionId) {
        return [];
      }
      const { data } = await request({
        path: `/2/sessions/cpo/${props.sessionId}/additional-charges`,
        method: 'GET',
      });
      return data;
    },
    {
      placeholderData: [],
      staleTime: 5 * 60 * 1000,
    }
  );

  // The decision was made explicitly to accumulate charges of the same type:
  // https://linear.app/road-io/issue/SAAS-2240/usage-fee-make-fee-visible-on-session-details#comment-55413496
  const chargesAccumulatedByType =
    data?.reduce(
      (acc, charge) => {
        acc[charge.type] ??= { value: 0, currency: charge.currency };
        acc[charge.type].value += charge.amount;
        return acc;
      },
      {} as Record<string, { value: number; currency: string }>
    ) || {};

  return (
    <>
      {chargesAccumulatedByType[feeTypes.CPOKWHUsageFee]?.value > 0 && (
        <Table.Row>
          <Table.Cell>
            {t('sessionDetails.costAndBilling.usageFee', 'Usage fee')}
          </Table.Cell>
          <Table.Cell>
            <Currency
              value={roundUpTwoDigits(
                chargesAccumulatedByType[feeTypes.CPOKWHUsageFee].value
              )}
              currency={
                chargesAccumulatedByType[feeTypes.CPOKWHUsageFee].currency
              }
            />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
}
