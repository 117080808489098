import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { submitBillingInfo } from 'components/BillingInfo/api';
import { CardRequestError } from 'components/modals/RequestCard/errors';
import { RequestCardFormValues } from 'components/modals/RequestCard/formData';
import { useAccount } from 'contexts/account';
import { Account } from 'types/account';
import { request } from 'utils/api';

export function useSubmitCardRequest(
  options: Omit<
    UseMutationOptions<unknown, CardRequestError, RequestCardFormValues>,
    'mutationFn'
  >
) {
  const { account, isBillingUpdateRequired } = useAccount();
  return useMutation({
    mutationFn: async (values: RequestCardFormValues) => {
      if (!account) {
        throw new Error('No account in context!');
      }

      if (isBillingUpdateRequired) {
        try {
          await submitBillingInfo(account, values.billing);
        } catch (e) {
          throw CardRequestError.Billing(e);
        }
      }

      await submitCardRequest(account, values);
    },
    ...options,
  });
}

export async function submitCardRequest(
  account: Account,
  values: RequestCardFormValues
) {
  let cardHolderInfo: Record<string, string | number | boolean>;
  if (values.cardHolder.type === 'existing') {
    cardHolderInfo = {
      requestUser: false,
      requestUserId: values.cardHolder.userId,
    };
  } else {
    cardHolderInfo = {
      requestUser: true,
      requestUserFirstName: values.cardHolder.firstName,
      requestUserLastName: values.cardHolder.lastName,
      requestUserEmail: values.cardHolder.email,
    };
  }

  try {
    return await request({
      method: 'POST',
      path: '/2/cards/request',
      body: {
        billingPlanId: values.card.billingPlanId,
        type: values.card.type,
        delivery: resolveDelivery(values),
        ...cardHolderInfo,
      },
    });
  } catch (e) {
    throw CardRequestError.Card(e);
  }
}

function resolveDelivery(values: RequestCardFormValues) {
  if (values.delivery.useSameAsBilling) {
    return {
      firstName:
        values.cardHolder.type === 'existing'
          ? values.cardHolder.user?.contact?.firstName || ''
          : values.cardHolder.firstName,
      lastName:
        values.cardHolder.type === 'existing'
          ? values.cardHolder.user?.contact?.lastName || ''
          : values.cardHolder.lastName,
      address: values.billing.street,
      addressLine2: values.billing.number,
      postalCode: values.billing.postalCode,
      city: values.billing.city,
      countryCode: values.billing.countryCode,
    };
  }
  return {
    firstName: values.delivery.firstName,
    lastName: values.delivery.lastName,
    address: values.delivery.street,
    addressLine2: values.delivery.number,
    postalCode: values.delivery.postalCode,
    city: values.delivery.city,
    countryCode: values.delivery.countryCode,
  };
}
