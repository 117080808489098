import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Message, Modal } from 'semantic';
import { TFunction } from 'react-i18next';

type NonCompatibleModalProps = {
  t: TFunction;
};

export interface NonCompatibleStatusesModalViewImperativeHandle {
  open: () => void;
  close: () => void;
}

export const NonCompatibleStatusesForRetry = forwardRef(function ModalView(
  { t }: NonCompatibleModalProps,
  ref
) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useImperativeHandle(
    ref,
    (): NonCompatibleStatusesModalViewImperativeHandle => {
      return {
        open: () => {
          setIsModalOpen(true);
        },
        close: () => {
          setIsModalOpen(false);
        },
      };
    }
  );

  return (
    <Modal
      closeIcon
      open={isModalOpen}
      size="small"
      onOpen={() => setIsModalOpen(true)}
      onClose={() => setIsModalOpen(false)}>
      <Modal.Header>
        {t(
          'evseControllersBackgroundJobsDetail.batch.retry.nonCompatibleStatuses.title',
          'Retry jobs for selected stations'
        )}
      </Modal.Header>
      <Modal.Content>
        <Message
          error
          icon="triangle-exclamation"
          header={t(
            'evseControllersBackgroundJobsDetail.batch.retry.nonCompatibleStatuses.errorTitle',
            'Only the job with status "Failed" or "Canceled" can be retried.'
          )}
          content={
            <>
              <p>
                {t(
                  'evseControllersBackgroundJobsDetail.batch.retry.nonCompatibleStatuses.errorContent',
                  'Some of the selected charging stations have a job status of "In Progress". You can only rerun jobs that have already failed or been canceled.'
                )}
              </p>
              <p>
                {t(
                  'evseControllersBackgroundJobsDetail.batch.retry.nonCompatibleStatuses.errorAction',
                  'Please review your selection and retry.'
                )}
              </p>
            </>
          }
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content={t('common.back', 'Back')}
          onClick={() => setIsModalOpen(false)}
        />
      </Modal.Actions>
    </Modal>
  );
});

export const NonCompatibleStatusesForCancel = forwardRef(function ModalView(
  { t }: NonCompatibleModalProps,
  ref
) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useImperativeHandle(
    ref,
    (): NonCompatibleStatusesModalViewImperativeHandle => {
      return {
        open: () => {
          setIsModalOpen(true);
        },
        close: () => {
          setIsModalOpen(false);
        },
      };
    }
  );

  return (
    <Modal
      closeIcon
      open={isModalOpen}
      size="small"
      onOpen={() => setIsModalOpen(true)}
      onClose={() => setIsModalOpen(false)}>
      <Modal.Header>
        {t(
          'evseControllersBackgroundJobsDetail.batch.cancel.nonCompatibleStatuses.title',
          'Cancel jobs for selected stations'
        )}
      </Modal.Header>
      <Modal.Content>
        <Message
          error
          icon="triangle-exclamation"
          header={t(
            'evseControllersBackgroundJobsDetail.batch.cancel.nonCompatibleStatuses.errorTitle',
            'Only the job with status "InProgress" can be canceled.'
          )}
          content={
            <>
              <p>
                {t(
                  'evseControllersBackgroundJobsDetail.batch.cancel.nonCompatibleStatuses.errorContent',
                  'Some of the selected charging stations does not have a job status of "In Progress". You can only cancel jobs that are in progress.'
                )}
              </p>
              <p>
                {t(
                  'evseControllersBackgroundJobsDetail.batch.cancel.nonCompatibleStatuses.errorAction',
                  'Please review your selection and cancel.'
                )}
              </p>
            </>
          }
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content={t('common.back', 'Back')}
          onClick={() => setIsModalOpen(false)}
        />
      </Modal.Actions>
    </Modal>
  );
});

type ConfirmationModalProps = {
  t: TFunction;
  onConfirm: () => void;
};

export const BatchCancelConfirmation = forwardRef(function ModalView(
  { t, onConfirm }: ConfirmationModalProps,
  ref
) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useImperativeHandle(
    ref,
    (): NonCompatibleStatusesModalViewImperativeHandle => {
      return {
        open: () => {
          setIsModalOpen(true);
        },
        close: () => {
          setIsModalOpen(false);
        },
      };
    }
  );

  return (
    <Modal
      closeIcon
      open={isModalOpen}
      size="small"
      onOpen={() => setIsModalOpen(true)}
      onClose={() => setIsModalOpen(false)}>
      <Modal.Header>
        {t(
          'evseControllersBackgroundJobsDetail.batch.cancel.confirmation.title',
          'Are you sure you want to cancel the job for the selected charging station(s)?'
        )}
      </Modal.Header>
      <Modal.Content>
        <Message warning>
          {t(
            'evseControllersBackgroundJobsDetail.batch.cancel.confirmation.important',
            'This action cannot be undone.'
          )}
        </Message>
        <p>
          {t(
            'evseControllersBackgroundJobsDetail.batch.cancel.confirmation.description',
            'Canceling the job will stop further actions, but any process already started by the charging station cannot be reversed.'
          )}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content={t('common.back', 'Back')}
          onClick={() => setIsModalOpen(false)}
        />
        <Button
          primary
          // disabled={disabled}
          content={t('common.confirm', 'Confirm')}
          color="red"
          onClick={onConfirm}
        />
      </Modal.Actions>
    </Modal>
  );
});
