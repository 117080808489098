import React, { useContext, useRef, useState } from 'react';
import { Search } from 'components';
import { Label, Table, Checkbox } from 'semantic';
import { Link, useHistory } from 'react-router-dom';
import { request } from 'utils/api';
import { getBulkCancelUrl } from '../utils';
import {
  ACCOUNTS_FE_PATH,
  CHARGING_STATIONS_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH,
  EVSE_CONTROLLERS_LOCATIONS_FE_PATH,
} from 'screens/EvseControllersBackgroundJobs/utils';
import { ConnectivityStatus } from 'components/ConnectivityStatus';
import EvseControllerBackgroundJobExecutionStatus from 'screens/EvseControllersBackgroundJobs/EvseControllerBackgroundJobExecutionStatus';
import { useTranslation } from 'react-i18next';
import ResultsFound from 'screens/EvseControllersBackgroundJobs/ResultsFound';
import {
  EvseBulkActionWorkflow,
  EvseControllerBackgroundJobExecution,
  EvseControllerBackgroundJobStatus,
  EvseControllerBackgroundJobType,
} from 'types/evse-controller-background-job-execution';
import HelpTip from 'components/HelpTip';
import { Center } from 'components/Layout/helpers';
import WorkflowActions from './Actions';
import { formatDateTime } from 'utils/date';
import {
  BulkActionsIsland,
  toggle,
  toggleAll,
} from 'components/BulkActionsIsland';
import {
  BatchCancelConfirmation,
  NonCompatibleStatusesForCancel,
  NonCompatibleStatusesForRetry,
  NonCompatibleStatusesModalViewImperativeHandle,
} from './BatchAlerts';

const itemsEqual = (a: EvseBulkActionWorkflow, b: EvseBulkActionWorkflow) =>
  a.evseController?.ocppIdentity === b.evseController?.ocppIdentity;

const selectionReviewColumns = [
  {
    title: <Table.HeaderCell>OCPP ID</Table.HeaderCell>,
    data: (item: EvseBulkActionWorkflow) => (
      <Table.Cell>{item.evseController?.ocppIdentity}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Account</Table.HeaderCell>,
    data: (item: EvseBulkActionWorkflow) => (
      <Table.Cell>{item.evseController?.account?.name || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>OCPP Version</Table.HeaderCell>,
    data: (item: EvseBulkActionWorkflow) => (
      <Table.Cell>{item.evseController?.ocppProtocolVersion || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Firmware Version</Table.HeaderCell>,
    data: (item: EvseBulkActionWorkflow) => (
      <Table.Cell textAlign="center">
        {item.evseController?.bootInfo?.firmwareVersion || '-'}
      </Table.Cell>
    ),
  },
];

const canBeSelected = (workflow: EvseBulkActionWorkflow) =>
  workflow.status === EvseControllerBackgroundJobStatus.Failed ||
  workflow.status === EvseControllerBackgroundJobStatus.Cancelled ||
  workflow.status === EvseControllerBackgroundJobStatus.InProgress ||
  workflow.status === EvseControllerBackgroundJobStatus.PartlyCompleted;

const includesCancelable = (workflows: EvseBulkActionWorkflow[]) =>
  workflows.some(
    (workflow) =>
      workflow.status === EvseControllerBackgroundJobStatus.InProgress
  );

const includesRetryable = (workflows: EvseBulkActionWorkflow[]) =>
  workflows.some(
    (workflow) =>
      workflow.status === EvseControllerBackgroundJobStatus.Failed ||
      workflow.status === EvseControllerBackgroundJobStatus.Cancelled
  );

type WorkflowsTableProps = {
  backgroundJob: EvseControllerBackgroundJobExecution;
  onRefresh: () => void;
};

export default function WorkflowsTable({
  backgroundJob,
  onRefresh,
}: WorkflowsTableProps) {
  const { items, loading } = useContext(Search.Context);
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = useState<EvseBulkActionWorkflow[]>(
    []
  );
  const openNonCompatibleStatusesForRetryModal =
    useRef<NonCompatibleStatusesModalViewImperativeHandle>(null);
  const openNonCompatibleStatusesForCancelModal =
    useRef<NonCompatibleStatusesModalViewImperativeHandle>(null);
  const openConfirmationForCancelModal =
    useRef<NonCompatibleStatusesModalViewImperativeHandle>(null);

  if (!items?.length || loading) {
    return null;
  }

  const history = useHistory();

  const onRetrySelectedItems = (selectedItems: EvseBulkActionWorkflow[]) => {
    if (includesCancelable(selectedItems)) {
      openNonCompatibleStatusesForRetryModal.current?.open();
      return;
    }

    const selectedEvseControllers = selectedItems.map(
      (workflow) => workflow.evseController
    );
    switch (backgroundJob.actionType) {
      case EvseControllerBackgroundJobType.SetConfiguration:
        history.push(
          `${EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH}`,
          {
            evseControllers: selectedEvseControllers,
            predefinedValues: backgroundJob,
          }
        );
        break;
      case EvseControllerBackgroundJobType.ExecuteCommand:
        history.push(
          `${EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH}`,
          {
            evseControllers: selectedEvseControllers,
            predefinedValues: backgroundJob,
          }
        );
        break;
      case EvseControllerBackgroundJobType.UpdateFirmware:
        history.push(
          `${EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH}`,
          {
            evseControllers: selectedEvseControllers,
            predefinedValues: backgroundJob,
          }
        );
        break;
    }
  };

  const onCancelSelectedItems = (
    backgroundJobId: string,
    selectedItems: EvseBulkActionWorkflow[]
  ) => {
    if (includesRetryable(selectedItems)) {
      openNonCompatibleStatusesForCancelModal.current?.open();
      return;
    }

    openConfirmationForCancelModal.current?.open();
  };

  const executeBatchCancel = async () => {
    await request({
      method: 'POST',
      path: getBulkCancelUrl(backgroundJob.id),
      body: {
        executionIds: selectedItems.map((workflow) => workflow.executionId),
      },
    })
      .then(() => {
        onRefresh();
      })
      .catch(() => {
        // Handle error
      });
  };

  return (
    <>
      <NonCompatibleStatusesForRetry
        t={t}
        ref={openNonCompatibleStatusesForRetryModal}
      />
      <NonCompatibleStatusesForCancel
        t={t}
        ref={openNonCompatibleStatusesForCancelModal}
      />
      <BatchCancelConfirmation
        t={t}
        ref={openConfirmationForCancelModal}
        onConfirm={executeBatchCancel}
      />
      <ResultsFound numResults={items?.length || 0} />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: '1%' }} textAlign="center">
              <Checkbox
                indeterminate={
                  selectedItems.length > 0 &&
                  items.length !== selectedItems.length
                }
                onChange={(e: any, { checked }: { checked: boolean }) => {
                  toggleAll(
                    setSelectedItems,
                    selectedItems,
                    // Filter out items that are not eligible for selection
                    // Only items that can be Retried or Canceled can be selected.
                    items?.filter((workflow: EvseBulkActionWorkflow) =>
                      canBeSelected(workflow)
                    ),
                    itemsEqual,
                    checked
                  );
                }}
              />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t(
                'evseControllersBackgroundJobsDetail.columChargeStationId',
                'Charge Station ID'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t(
                'evseControllersBackgroundJobsDetail.columAccountInfo',
                'Account Info'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t(
                'evseControllersBackgroundJobsDetail.columFirmwareVersion',
                'Firmware Version'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t(
                'evseControllersBackgroundJobsDetail.columOcppVersion',
                'OCPP Version'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t(
                'evseControllersBackgroundJobsDetail.columConnectivity',
                'Connectivity'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllersBackgroundJobsDetail.columStatus', 'Status')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t(
                'evseControllersBackgroundJobsDetail.columnActions',
                'Actions'
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items?.map((workflow: EvseBulkActionWorkflow) => {
            const evseController = workflow.evseController;
            if (!evseController) {
              return null;
            }

            const ocppVersion = evseController.ocppProtocolVersion?.replaceAll(
              'ocpp',
              ''
            );

            const statusDetails =
              workflow.status === EvseControllerBackgroundJobStatus.Cancelled
                ? t(
                    'evseControllersBackgroundJobsDetail.statusDetailModalDetailsCanceledInfo',
                    'The job is canceled by {{requestedBy}} on {{requestedAt}}.',
                    {
                      requestedBy:
                        workflow.cancelRequestedByUser?.name || 'Unknown',
                      requestedAt: formatDateTime(workflow.cancelRequestedAt),
                    }
                  )
                : workflow.status ===
                    EvseControllerBackgroundJobStatus.Cancelling
                  ? t(
                      'evseControllersBackgroundJobsDetail.statusDetailModalDetailsCancelingInfo',
                      'The job is canceled by {{requestedBy}} on {{requestedAt}}.',
                      {
                        requestedBy:
                          workflow.cancelRequestedByUser?.name || 'Unknown',
                        requestedAt: formatDateTime(workflow.cancelRequestedAt),
                      }
                    )
                  : workflow.statusDetails;

            const isSelected = selectedItems.some((s) =>
              itemsEqual(s, workflow)
            );

            return (
              <Table.Row key={evseController.id}>
                <Table.Cell style={{ width: '1%' }} textAlign="center">
                  <Checkbox
                    checked={isSelected}
                    style={{
                      backgroundColor: !canBeSelected(workflow)
                        ? 'rgb(192, 192, 192, 0.6)'
                        : 'transparent',
                    }}
                    disabled={!canBeSelected(workflow)}
                    onChange={(e: any, { checked }: { checked: boolean }) => {
                      if (!canBeSelected(workflow)) {
                        return;
                      }
                      toggle(
                        setSelectedItems,
                        selectedItems,
                        workflow as EvseBulkActionWorkflow,
                        itemsEqual,
                        checked
                      );
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  {evseController.deleted ? (
                    evseController.ocppIdentity
                  ) : (
                    <>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to={`${CHARGING_STATIONS_FE_PATH}/${evseController.id}`}
                          target="_blank">
                          {evseController.ocppIdentity}
                        </Link>
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {t(
                          'evseControllersBackgroundJobsDetail.columSerialNumberText',
                          'Serial Number'
                        )}
                        : {evseController.serialNumber}
                      </p>
                    </>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {evseController.account && (
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'evseControllersBackgroundJobsDetail.columAccountText',
                        'Account'
                      )}
                      :{' '}
                      <Link
                        to={`${ACCOUNTS_FE_PATH}/${evseController.account.id}`}
                        target="_blank">
                        {evseController.account.name}
                      </Link>
                    </p>
                  )}

                  {evseController.maintenanceAccount && (
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'evseControllersBackgroundJobsDetail.columFieldServiceText',
                        'Field Service'
                      )}
                      :{' '}
                      <Link
                        to={`${ACCOUNTS_FE_PATH}/${evseController.maintenanceAccount.id}`}
                        target="_blank">
                        {evseController.maintenanceAccount.name}
                      </Link>
                    </p>
                  )}
                  {evseController.location && (
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'evseControllersBackgroundJobsDetail.columLocationText',
                        'Location'
                      )}
                      :{' '}
                      <Link
                        to={`${EVSE_CONTROLLERS_LOCATIONS_FE_PATH}/${evseController.location.id}`}
                        target="_blank">
                        {evseController.location.name}
                      </Link>
                    </p>
                  )}
                  {evseController.billingPlan && (
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'evseControllersBackgroundJobsDetail.columPlanText',
                        'Plan'
                      )}
                      : {evseController.billingPlan.name}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {evseController.bootInfo?.firmwareVersion}
                </Table.Cell>
                <Table.Cell textAlign="center">{ocppVersion}</Table.Cell>
                <Table.Cell textAlign="center">
                  {evseController.deleted ? (
                    <Label
                      color="red"
                      content={t('common.deleted', 'Deleted')}
                    />
                  ) : (
                    <ConnectivityStatus item={evseController} />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Center>
                    <EvseControllerBackgroundJobExecutionStatus
                      status={workflow.status}
                    />
                    {statusDetails && (
                      <HelpTip
                        disabledIconTitle
                        marginTop="0"
                        marginLeft="0.5em"
                        iconTooltip="info"
                        iconCircular
                        title={t(
                          'evseControllersBackgroundJobsDetail.statusDetailModalTitle',
                          'Status Description'
                        )}
                        text={
                          <>
                            <p>
                              {t(
                                'evseControllersBackgroundJobsDetail.statusDetailModalJobStatus',
                                'Job status for'
                              )}{' '}
                              {evseController.ocppIdentity}
                            </p>
                            <div style={{ paddingBottom: 20 }}>
                              <EvseControllerBackgroundJobExecutionStatus
                                status={workflow.status}
                              />
                            </div>
                            <p>
                              <strong>
                                {t(
                                  'evseControllersBackgroundJobsDetail.statusDetailModalDetailsTitle',
                                  'Status details'
                                )}
                                :
                              </strong>
                            </p>
                            <p style={{ whiteSpace: 'pre-line' }}>
                              {statusDetails.replace(
                                /: |, /g,
                                (match) => match
                              )}
                            </p>
                          </>
                        }
                      />
                    )}
                  </Center>
                </Table.Cell>
                <Table.Cell>
                  <Center>
                    <WorkflowActions
                      t={t}
                      backgroundJob={backgroundJob}
                      workflow={workflow}
                      onRefresh={onRefresh}
                      onRetry={async (
                        backgroundJobId: string,
                        workflow: EvseBulkActionWorkflow
                      ): Promise<void> => {
                        return onRetrySelectedItems([workflow]);
                      }}
                      onCancel={async (
                        backgroundJobId: string,
                        workflow: EvseBulkActionWorkflow
                      ): Promise<void> => {
                        return onCancelSelectedItems(backgroundJobId, [
                          workflow,
                        ]);
                      }}
                    />
                  </Center>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <BulkActionsIsland
        selectedItems={selectedItems}
        onSetSelectedItems={setSelectedItems}
        selectionReviewColumns={selectionReviewColumns}
        equal={itemsEqual}
        actions={[
          ...(includesRetryable(selectedItems || [])
            ? [
                {
                  name: t(
                    'evseControllersBackgroundJobsDetail.batch.retry.buttonTitle',
                    'Retry'
                  ),
                  icon: 'arrow-rotate-right',
                  quickAccess: true,
                  onExecute: async (
                    selectedItems: EvseBulkActionWorkflow[]
                  ) => {
                    onRetrySelectedItems(selectedItems);
                  },
                },
              ]
            : []),
          ...(includesCancelable(selectedItems || [])
            ? [
                {
                  name: t(
                    'evseControllersBackgroundJobsDetail.batch.cancel.buttonTitle',
                    'Cancel'
                  ),
                  icon: 'close',
                  quickAccess: true,
                  onExecute: async (
                    selectedItems: EvseBulkActionWorkflow[]
                  ) => {
                    onCancelSelectedItems(backgroundJob.id, selectedItems);
                  },
                },
              ]
            : []),
        ]}
      />
    </>
  );
}
