import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFilters, Breadcrumbs, Search, Layout } from 'components';
import { Segment, Divider, Message } from 'semantic';
import { useUser } from 'contexts/user';
import { userCanAccessProviderEndpoint } from '../../../utils/roles';
import Table from './Table';
import { request } from 'utils/api';
import { useFeatures } from '../../../contexts/features';

export type LabelItem = {
  label: string;
  objectType: string;
  usage: number;
};

export default function Integrations() {
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const { hasFeature, isReady } = useFeatures();

  const labelFeatureEnabled = hasFeature('manage_labels');

  const writeAccess = userCanAccessProviderEndpoint(
    user!,
    provider,
    'labels',
    'write'
  );

  if (!isReady || !labelFeatureEnabled || !writeAccess) {
    return (
      <Message warning>
        {t(
          'access.cannotAccess',
          'This page is not available for you. Please contact your administrator.'
        )}
      </Message>
    );
  }

  const onDataNeeded = async (filters: any) => {
    return request({
      method: 'POST',
      path: '/1/labels/search',
      body: {
        ...filters,
      },
    });
  };

  const getFilterMapping = () => {
    return {
      searchPhrase: {
        type: 'search',
      },
      objectType: {
        type: 'string',
      },
    };
  };

  const searchRef = useRef(null);

  return (
    <Search.Provider
      onDataNeeded={onDataNeeded}
      ref={searchRef}
      filterMapping={getFilterMapping()}>
      {() => (
        <>
          <Breadcrumbs
            active={t('globalManageLabels.title', 'Manage Labels')}
          />
          <h3>{t('globalManageLabels.title', 'Manage Labels')}</h3>
          <p>
            {t(
              'globalManageLabels.description',
              'Use label to group any objects within same type and easily filter them with the same labels later'
            )}
          </p>

          <Divider hidden />
          <Segment>
            <Layout horizontal spread stackable>
              <SearchFilters.ModalFilterV2>
                <SearchFilters.DropdownFilterV2
                  name="objectType"
                  label={t('globalManageLabels.objectType', 'Object Type')}
                  options={[
                    {
                      text: t(
                        'globalManageLabels.objectTypeOptions.chargingStations',
                        'Charging Stations'
                      ),
                      key: 'evse-controller',
                      value: 'evse-controller',
                    },
                    {
                      text: t(
                        'globalManageLabels.objectTypeOptions.accounts',
                        'Accounts'
                      ),
                      key: 'account',
                      value: 'account',
                    },
                    {
                      text: t(
                        'globalManageLabels.objectTypeOptions.users',
                        'Users'
                      ),
                      key: 'user',
                      value: 'user',
                    },
                  ]}
                />
              </SearchFilters.ModalFilterV2>
              <Layout horizontal stackable center right>
                <Search.Total />
                <SearchFilters.Search
                  name="searchPhrase"
                  placeholder={t('globalManageLabels.search', 'Search label')}
                />
              </Layout>
            </Layout>
          </Segment>
          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Table writeAccess={writeAccess} />
          <div
            style={{
              textAlign: 'center',
            }}>
            <Search.Pagination />
          </div>
        </>
      )}
    </Search.Provider>
  );
}
