import React from 'react';
import { useFeatures } from 'contexts/features';
import PropTypes from 'prop-types';

/**
 * FeatureFlag determines if a feature is enabled for the current user.
 * IMPORTANT: `<FeatureFlag />` component only accepts a single child!!!
 */
export default function FeatureFlag({ children, feature, invert }) {
  if (!React.isValidElement(children)) {
    return null;
  }

  const { hasFeature } = useFeatures();
  return (hasFeature(feature) && !invert) || (!hasFeature(feature) && invert)
    ? children
    : null;
}

FeatureFlag.propTypes = {
  children: PropTypes.node.isRequired,
  feature: PropTypes.string.isRequired,
  invert: PropTypes.bool,
};
