import React from 'react';

import { Form, Dimmer, Grid, Message, Modal, Divider, Button } from 'semantic';

import { useTranslation } from 'react-i18next';
import { useWizardContext } from '../context';
import CountriesField from 'components/form-fields/Countries';
import PointerMap from 'components/maps/Pointer';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import { request } from 'utils/api';
import useDebounce from 'utils/use-debounce';

export const useDebouncedState = (initialState, durationInMs = 200) => {
  const [internalState, setInternalState] = React.useState(initialState);
  const debouncedFunction = useDebounce(setInternalState, durationInMs);
  return [internalState, debouncedFunction];
};

export default ({ onDone, onBack, evseInvite, stepHeader }) => {
  const { t } = useTranslation();

  const [completeAddress, setCompleteAddress] = useDebouncedState(
    undefined,
    1000
  );

  const [supportedCountries, setSupportedCountries] = React.useState([]);
  const [defaultCountry, setDefaultCountry] = React.useState('');

  React.useEffect(() => {
    request({
      path: '/2/locations/cpo/supported-countries',
      method: 'GET',
    }).then(({ data }) => {
      setSupportedCountries(data.supportedCountries);
      setDefaultCountry(data.defaultCountry);
    });
  }, []);

  const {
    setLocation,
    location,
    existingLocation,
    setExistingLocation,
    addToExisting,
    setAddToExisting,
  } = useWizardContext();

  const [error, setError] = React.useState(null);

  function handleSubmit() {
    setError(null);

    if (!addToExisting) {
      setExistingLocation(null);
      onDone();
      return;
    }

    if (!existingLocation) {
      setError({
        message: t(
          'newChargingStationLocation.noExisitingLocation',
          'Please select an existing location.'
        ),
      });
      return;
    }
    onDone();
  }

  React.useEffect(() => {
    const address = [
      location.address,
      location.addressLine2,
      location.postal_code,
      location.city,
      location.country,
    ];
    const hasCompleteAddress = address.every((c) => !!c);
    setCompleteAddress(hasCompleteAddress ? address.join(', ') : undefined);
  }, [location]);

  return (
    <>
      <Modal.Header>
        {stepHeader}
        {t('newChargingStationLocation.header', 'Location')}
      </Modal.Header>
      <Modal.Content>
        {!evseInvite && (
          <Form
            onSubmit={handleSubmit}
            id="formNewChargingStationLocationExisting">
            <Form.Group
              widths="equal"
              style={{ marginBottom: '0px', alignItems: 'center' }}>
              <Form.Checkbox
                checked={addToExisting}
                width={6}
                onChange={(e, { checked }) => setAddToExisting(checked)}
                label={t(
                  'newChargingStationLocation.useExisitingLocation',
                  'Use existing location'
                )}
              />
              <Form.Field required>
                <SearchDropDown
                  required
                  disabled={!addToExisting}
                  value={existingLocation?.id}
                  style={{ zIndex: '10002' }}
                  objectMode={false}
                  onDataNeeded={() => {
                    return request({
                      path: '/2/locations/cpo/mine',
                      method: 'POST',
                    });
                  }}
                  onChange={(e, { value }) => {
                    if (!value) {
                      setExistingLocation(null);
                      return;
                    }

                    // we should really update to latest bedrock
                    request({
                      path: `/2/locations/cpo/${value}`,
                      method: 'GET',
                    }).then(({ data }) => {
                      setExistingLocation(data);
                    });
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Divider horizontal>{t('common.or', 'Or')}</Divider>
          </Form>
        )}
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Form onSubmit={handleSubmit} id="formNewChargingStationLocation">
                <Form.Input
                  value={location.name}
                  disabled={addToExisting}
                  name="name"
                  required
                  label={t(
                    'newChargingStationLocation.fieldName',
                    'Location Name'
                  )}
                  type="text"
                  autoComplete="disabled"
                  onChange={(e, { name, value }) =>
                    setLocation({ ...location, [name]: value })
                  }
                />
                {/* <Header>{t('common.address', 'Address')}</Header> */}

                <Form.Group>
                  <Form.Input
                    width="12"
                    disabled={addToExisting}
                    value={location.address || ''}
                    name="address"
                    required
                    label={t('formLabel.address', 'Address')}
                    type="text"
                    autoComplete="disabled"
                    onChange={(e, { name, value }) =>
                      setLocation({ ...location, [name]: value })
                    }
                  />

                  <Form.Input
                    width="4"
                    required
                    disabled={addToExisting}
                    value={location.addressLine2 || ''}
                    name="addressLine2"
                    label={t('formLabel.addressLine2', 'No.')}
                    type="text"
                    onChange={(e, { name, value }) =>
                      setLocation({ ...location, [name]: value })
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    disabled={addToExisting}
                    value={location.postal_code || ''}
                    name="postal_code"
                    required
                    label={t('formLabel.postCode')}
                    type="text"
                    autoComplete="disabled"
                    onChange={(e, { name, value }) =>
                      setLocation({ ...location, [name]: value })
                    }
                  />
                  <Form.Input
                    disabled={addToExisting}
                    value={location.city || ''}
                    name="city"
                    required
                    label={t('formLabel.city')}
                    type="text"
                    autoComplete="disabled"
                    onChange={(e, { name, value }) =>
                      setLocation({ ...location, [name]: value })
                    }
                  />
                </Form.Group>

                <CountriesField
                  disabled={addToExisting}
                  label={t('formLabel.country')}
                  name="country"
                  required
                  standard="alpha-3"
                  lowerCase={false}
                  whitelist={supportedCountries}
                  autoComplete="disabled"
                  value={location.country || defaultCountry}
                  onChange={(value) =>
                    setLocation({ ...location, country: value })
                  }
                />
              </Form>

              {error && <Message error>{error.message}</Message>}
            </Grid.Column>

            <Grid.Column width={8}>
              <Dimmer.Dimmable
                style={{ height: '350px' }}
                dimmed={!completeAddress || addToExisting}>
                <Dimmer active={!completeAddress || addToExisting} inverted />
                <PointerMap
                  onChange={(position) => {
                    setLocation({
                      ...location,
                      geoLocation: {
                        type: 'Point',
                        coordinates: [position.longitude, position.latitude],
                      },
                    });
                  }}
                  address={completeAddress}
                />
                {!completeAddress && (
                  <Message
                    style={{
                      position: 'absolute',
                      zIndex: 10001,
                      top: 150,
                      left: 60,
                      margin: 'auto',
                    }}>
                    {t(
                      'newChargingStationLocation.positionMap',
                      'Enter address to position the station'
                    )}
                  </Message>
                )}
              </Dimmer.Dimmable>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onBack()}>{t('common.back', 'Back')}</Button>
        <Button
          form={
            addToExisting
              ? 'formNewChargingStationLocationExisting'
              : 'formNewChargingStationLocation'
          }
          primary
          content={t('common.next', 'Next')}
        />
      </Modal.Actions>
    </>
  );
};
