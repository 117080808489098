import React, { useState } from 'react';

import { Layout, Search, SearchFilters } from 'components';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash-es';
import { Divider, Button, Loader, Segment, Grid } from 'semantic';

import { request } from 'utils/api';
import Table from './Table';
import ExecuteEvseCommand from 'components/modals/ExecuteEvseCommand';
import { determineCoordinatorStatus } from 'utils/evse-controllers';
import { ConnectivityStatus } from 'components/ConnectivityStatus';
import { EvseController } from 'types/evse-controller';
import { CommandStatus } from 'types/command';
import useFetch from 'hooks/useFetch';
import useInterval from 'hooks/useInternal';
import { OCPPProtocolResponse } from 'types/ocpp-protocol';
import { FeatureFlags, useFeatures } from 'contexts/features';

const itemLimit = 100;

const defaultFilter = {
  hideHeartBeats: true,
  hideMeterValues: true,
};

type Props = {
  evseController: EvseController;
};

function getOCPPProtocolPath(ocppProtocolVersion: string): string {
  switch (ocppProtocolVersion) {
    case 'ocpp1.5':
    case 'ocpp1.6':
      return `/1/ocpp/1.6/protocol`;
    case 'ocpp2.0.1':
      return `/1/ocpp/2.0.1/protocol`;
    default:
      return '';
  }
}

export default function EvseControllerCommands({
  evseController: evseCtrl,
}: Props) {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();

  const isExtraFiltersCommandTabEnabled = hasFeature(
    FeatureFlags.ExtraFiltersCommandTab
  );

  // hack to force refresh
  const [dataKey, setDataKey] = useState<number>(Date.now());

  const ocppProtocolPath = getOCPPProtocolPath(
    evseCtrl.ocppProtocolVersion || ''
  );

  const { data: evseController, refresh: evseControllerRefresh } =
    useFetch<EvseController>({
      method: 'GET',
      path: `/1/evse-controllers/${evseCtrl.id}`,
    });
  const coordinatorStatus = evseController?.coordinatorStatus || {
    status: 'inactive',
  };

  const {
    data: protocolData,
    loading: protocolLoading,
    error: protocolError,
  } = useFetch<OCPPProtocolResponse>({
    method: 'GET',
    path: ocppProtocolPath,
  });

  useInterval(async () => {
    evseControllerRefresh();
  }, 4000);

  if (!evseController) return <Loader active />;

  const filterMapping = {
    showHeartBeats: {
      type: 'boolean',
      label: t('evseControllerCommands.heartbeatsLabel', 'Show Heartbeats'),
    },
    showMeterValues: {
      type: 'boolean',
      label: t('evseControllerCommands.meterValuesLabel', 'Show MeterValues'),
    },
    method: {
      type: 'string',
      label: t('evseControllerCommands.methodLabel', 'Method'),
      multiple: true,
    },
    status: {
      type: 'string',
      label: t('evseControllerCommands.statusLabel', 'Status'),
      multiple: true,
    },
    createdAt: {
      type: 'date',
      label: t('evseControllerCommands.createdAtLabel', 'Command created:'),
      range: true,
    },
    transactionId: {
      type: 'string',
      label: t('evseControllerCommands.transactionIdLabel', 'Transaction ID'),
    },
    connectorId: {
      type: 'number',
      label: t('evseControllerCommands.connectorIdLabel', 'Connector ID'),
    },
  };

  const onDataNeeded = (filters: any) => {
    const { showHeartBeats, showMeterValues, ...rest } = filters;
    const body = {
      ...defaultFilter,
      ...rest,
    };

    if (showHeartBeats) {
      delete body.hideHeartBeats;
    }
    if (showMeterValues) {
      delete body.hideMeterValues;
    }

    return request({
      method: 'POST',
      path: `/1/evse-controllers/${evseController.id}/commands/search`,
      body,
    });
  };

  if (!coordinatorStatus) return <Loader active />;
  const status = determineCoordinatorStatus(evseController);

  const protocolOptions = uniqBy(
    [
      ...(protocolData?.CENTRAL_SYSTEM_COMMANDS || []),
      ...(protocolData?.CHARGE_POINT_COMMANDS || []),
      { name: 'Connected' },
    ],
    'name'
  ).map((command) => ({
    key: command.name,
    text: command.name,
    value: command.name,
  }));

  const statusOptions = [
    {
      key: CommandStatus.Pending,
      text: 'Pending',
      value: CommandStatus.Pending,
    },
    { key: CommandStatus.Busy, text: 'Busy', value: CommandStatus.Busy },
    { key: CommandStatus.Done, text: 'Done', value: CommandStatus.Done },
  ];

  return (
    <div>
      <Search.Provider
        live
        key={dataKey}
        filterMapping={filterMapping}
        onDataNeeded={onDataNeeded}
        limit={itemLimit}>
        <Layout horizontal spread>
          <ExecuteEvseCommand
            onDone={() => setDataKey(Date.now())}
            evseControllerId={evseController.id}
            chargingStationId={evseController.ocppChargingStationId}
            ocppProtocolPath={ocppProtocolPath}
            trigger={
              <Button
                disabled={status.state === 'disconnected'}
                primary
                icon="terminal"
                content={t(
                  'evseControllerCommands.executeCommandButton',
                  'Execute Command'
                )}
              />
            }
          />
          <Search.Export content="Export Log" filename="commands" />
        </Layout>

        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <SearchFilters.Modal>
                  <SearchFilters.Checkbox
                    label={filterMapping.showHeartBeats.label}
                    name="showHeartBeats"
                    style={{ marginRight: '10px' }}
                    toggle
                  />
                  <SearchFilters.Checkbox
                    label={filterMapping.showMeterValues.label}
                    name="showMeterValues"
                    style={{ marginRight: '10px' }}
                    toggle
                  />
                  <Divider hidden />

                  <SearchFilters.DateRange
                    label={filterMapping.createdAt.label}
                    name="createdAt"
                    time
                    fullWidth
                    startLabel={t('common.from', 'From')}
                    endLabel={t('common.to', 'To')}
                  />

                  <SearchFilters.Dropdown
                    label={filterMapping.method.label}
                    search
                    multiple
                    error={protocolError}
                    loading={protocolLoading}
                    options={protocolOptions}
                    name="method"
                    clearable
                  />
                  <SearchFilters.Dropdown
                    label={filterMapping.status.label}
                    search
                    multiple
                    options={statusOptions}
                    name="status"
                    clearable
                  />
                  {isExtraFiltersCommandTabEnabled && (
                    <>
                      <SearchFilters.Input
                        label={filterMapping.transactionId.label}
                        name="transactionId"
                        fluid
                        type="text"
                      />
                      <SearchFilters.Input
                        label={filterMapping.connectorId.label}
                        name="connectorId"
                        fluid
                        type="number"
                      />
                    </>
                  )}
                </SearchFilters.Modal>
              </Grid.Column>
              <Grid.Column width={4} className={'right aligned'}>
                {t(
                  'evseControllerCommands.connectivityStatus',
                  'Connectivity Status'
                )}
                : <ConnectivityStatus item={evseController} oneLine />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Table />
        <Search.Status
          noResults={t(
            'evseControllerCommands.noResults',
            'No commands executed yet'
          )}
        />
        <Divider hidden />
        <div
          style={{
            textAlign: 'center',
          }}>
          <Search.Pagination />
        </div>
      </Search.Provider>
    </div>
  );
}
