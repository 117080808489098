import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from 'semantic-ui-react';
import styles from 'screens/DataImports/Form/DataImportFileRow.module.less';
import { Button, Icon } from 'semantic';
import {
  DataImportFileLabel,
  DataImportFileStatus,
  dataTypeTitle,
} from 'screens/DataImports/utils';
import React from 'react';
import { DataImportFileRowItem } from 'screens/DataImports/Form/DataImportFileRow';

type DataImportFileValidatedRowProps = {
  index: number;
  file: DataImportFileRowItem;
  onDelete: () => void;
  onOpen: () => void;
};

export default function DataImportFileValidatedRow({
  file,
  onOpen,
  onDelete,
}: DataImportFileValidatedRowProps) {
  const { t } = useTranslation();

  return (
    <>
      <TableRow className={file.isOpen ? styles.backgroundGrey : ''}>
        <TableCell
          collapsing
          width={1}
          style={{ width: '8%', paddingRight: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              link
              name={file.isOpen ? 'angle-up' : 'angle-right'}
              disabled={true}
              onClick={() => {}}
              style={{ marginRight: 15 }}
            />
            <DataImportFileStatus status={file.status} />
          </div>
        </TableCell>
        <TableCell collapsing width={1} style={{ paddingLeft: 0 }}>
          <DataImportFileLabel>
            {file.type && dataTypeTitle(t, file.type)}
          </DataImportFileLabel>
        </TableCell>
        <TableCell collapsing width={6}>
          {t('dataImports.file', 'File')}: {file.originalFilename}
        </TableCell>
        <TableCell collapsing width={2}>
          {t('dataImports.file', 'Total')}:{' '}
          <b>{file.fileMetrics?.totalCount}</b>
        </TableCell>
        <TableCell width={5}>
          {file.fileMetrics?.statusesCount &&
          file.fileMetrics?.statusesCount?.invalid > 0 ? (
            <>
              {t('dataImports.failed', 'Failed')}:{' '}
              <b>{file.fileMetrics?.statusesCount.invalid}</b>
            </>
          ) : (
            <span style={{ color: 'grey' }}>
              {t('dataImports.readyForImport', 'Ready for import')}
            </span>
          )}
        </TableCell>
        <TableCell collapsing width={1}>
          <Button
            basic
            icon="trash"
            title="Delete"
            onClick={onDelete}
            disabled={false}
          />
        </TableCell>
      </TableRow>
    </>
  );
}
