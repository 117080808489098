import { memoize } from 'lodash';

import { CSP_NONCE } from 'utils/env';

export const loadScript = memoize(function loadScript(src) {
  return new Promise(function (resolve) {
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    script.setAttribute('nonce', CSP_NONCE);
    script.addEventListener('load', () => {
      resolve(void 0);
    });
    document.body.appendChild(script);
  });
});
