import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RoamingSyncState.module.css';
import { Label } from 'semantic';
import { formatDateTime } from 'utils/date';

type entityType = 'location' | 'token' | 'cdr' | 'session';

interface Props {
  state: {
    [k in entityType]: {
      status: 'SUCCESS' | 'FAILED';
      lastSyncedAt: string;
      error?: string;
    };
  };
}

const RoamingSyncState: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation();
  if (!state) {
    return null;
  }

  const syncErrors = Object.keys(state).filter(
    (key) => state[key]?.status === 'ERROR' || state[key]?.status === 'FAILED'
  );

  if (syncErrors.length === 0) {
    return null;
  }

  return (
    <>
      <h3>{t('roamingSyncState.title', 'Roaming errors')}</h3>
      {Object.keys(state).map((key) => {
        const entity = state[key as keyof typeof state];
        return (
          <div key={key} className={styles.syncError}>
            <p className={styles.errorMessage}>{entity?.error}</p>
            <p>
              {entity.lastSyncedAt ? formatDateTime(entity.lastSyncedAt) : ''}
            </p>
            <Label color="red" size="small">
              {key?.toUpperCase()}
            </Label>
          </div>
        );
      })}
    </>
  );
};

export default RoamingSyncState;
