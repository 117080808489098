import { Dropdown, Segment, TableCell, TableRow } from 'semantic-ui-react';
import { Button, Icon } from 'semantic';
import {
  DataImportFileLabel,
  DataImportFileStatus,
  dataTypeTitle,
} from 'screens/DataImports/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import { DataImportFileMetrics, DataImportFileType } from 'types/data-import';
import styles from './DataImportFileRow.module.less';
import styled from 'styled-components';

export type DataImportFileRowStatus =
  | 'pending'
  | 'completed'
  | 'failed'
  | 'validating';

export type DataImportFileRowItem = {
  originalFilename: string;
  id?: string;
  type?: DataImportFileType;
  status?: DataImportFileRowStatus;
  isOpen?: boolean;
  rawFile?: File;
  localValidationError?: string;
  fileMetrics?: DataImportFileMetrics;
};

type DataImportFileRowProps = {
  index: number;
  file: DataImportFileRowItem;
  onDelete: () => void;
  onOpen: () => void;
  onObjectTypeChange: (type: DataImportFileType) => void;
};

export default function DataImportFileRow({
  file,
  onOpen,
  onDelete,
  onObjectTypeChange,
}: DataImportFileRowProps) {
  const { t } = useTranslation();

  const typeChangeDisabled = ['completed', 'validating'].includes(
    file.status as string
  );
  const deleteDisabled = ['pending', 'validating'].includes(
    file.status as string
  );

  return (
    <>
      <TableRow className={file.isOpen ? styles.backgroundGrey : ''}>
        <TableCell
          collapsing
          width={1}
          style={{ width: '8%', paddingRight: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              link
              name={file.isOpen ? 'angle-up' : 'angle-right'}
              disabled={!file.localValidationError}
              onClick={() => file.localValidationError && onOpen()}
              style={{ marginRight: 15 }}
            />
            <DataImportFileStatus status={file.status} />
          </div>
        </TableCell>
        <TableCell width={10} style={{ paddingLeft: 0 }}>
          <DataImportFileLabel>{file.originalFilename}</DataImportFileLabel>
        </TableCell>
        <TableCell collapsing width={4}>
          <DataImportObjectType
            onChange={onObjectTypeChange}
            value={file.type}
            disabled={typeChangeDisabled}
          />
        </TableCell>
        <TableCell collapsing width={1}>
          <Button
            basic
            icon="trash"
            title="Delete"
            onClick={onDelete}
            disabled={deleteDisabled}
          />
        </TableCell>
      </TableRow>
      {file.isOpen && file.localValidationError && (
        <TableRow className={styles.backgroundGrey}>
          <TableCell colSpan={5}>
            <b>{t('dataImports.detectedErrors', 'Detected errors:')}</b>
            <Segment>{file.localValidationError}</Segment>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

interface DropdownItemObjectTypeProps extends DropdownItemProps {
  value: DataImportFileType;
}

const ObjectTypeContainer = styled.div`
  background: white !important;

  .nocturnal-theme & {
    background: transparent none !important;
  }
`;

const DataImportObjectType: React.FC<{
  onChange: (value: DataImportFileType) => void;
  disabled: boolean;
  value?: DataImportFileType;
}> = ({ onChange, value, disabled }) => {
  const { t } = useTranslation();
  const items: DropdownItemObjectTypeProps[] = [
    {
      text: dataTypeTitle(t, 'accounts'),
      icon: 'circle-user regular',
      value: 'accounts',
    },
    {
      text: dataTypeTitle(t, 'users'),
      icon: 'users',
      value: 'users',
    },
    {
      text: dataTypeTitle(t, 'locations'),
      icon: 'location-dot',
      value: 'locations',
    },
    {
      text: dataTypeTitle(t, 'evses'),
      icon: 'charging-station',
      value: 'evses',
    },
    {
      text: dataTypeTitle(t, 'connectors'),
      icon: 'plug',
      value: 'connectors',
    },
    {
      text: dataTypeTitle(t, 'virtual-cards'),
      icon: 'credit-card regular',
      value: 'virtual-cards',
    },
  ];

  return (
    <ObjectTypeContainer>
      <Dropdown
        button
        basic
        fluid
        disabled={disabled}
        value={value}
        options={items}
        onChange={(e, data) => onChange(data.value as DataImportFileType)}
        placeholder={t('dataImports.objectTypePlaceholder', 'Object Type')}
      />
    </ObjectTypeContainer>
  );
};
