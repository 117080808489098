import { EvseController } from './evse-controller';

export enum EvseControllerBackgroundJobType {
  UpdateFirmware = 'UpdateFirmware',
  ExecuteCommand = 'ExecuteCommand',
  SetConfiguration = 'SetConfiguration',
}

export enum EvseControllerBackgroundJobStatus {
  InProgress = 'InProgress',
  PartlyCompleted = 'PartlyCompleted',
  Completed = 'Completed',
  Failed = 'Failed',
  Cancelling = 'Cancelling',
  Cancelled = 'Cancelled',
}

export type EvseControllerBackgroundJobExecutionCreatorUser = {
  id: string;
  contact?: {
    firstName?: string;
    lastName?: string;
  };
};

export type EvseControllerBackgroundJobExecutionCancellerUser = {
  id: string;
  name: string;
};

export interface EvseBulkActionWorkflow {
  executionId: string;
  chargingStationId: string;
  bulkExecutionId: string;
  status: EvseControllerBackgroundJobStatus;
  statusDetails: string;
  startedAt: Date;
  endedAt?: Date;
  evseController?: EvseController;
  cancelRequestedAt?: Date;
  cancelRequestedByUser?: EvseControllerBackgroundJobExecutionCancellerUser;
}

export type EvseControllerBackgroundJobExecution = {
  id: string;
  bulkExecutionId: string;
  createdAt: Date;
  updatedAt: Date;
  startedAt: Date;
  endedAt?: Date;
  creatorUser: EvseControllerBackgroundJobExecutionCreatorUser;
  creatorAccountId: string;
  creatorProviderId: string;
  actionType: EvseControllerBackgroundJobType;
  parameters: object;
  description: string;
  note: string;
  status: EvseControllerBackgroundJobStatus;
  workflowExecutionsNumber: number;
  workflows?: EvseBulkActionWorkflow[];
  workflowMetrics?: EvseControllerBackgroundJobWorkflowMetrics;
};

export type EvseControllerBackgroundJobWorkflowMetrics = {
  statusesCount: Map<EvseControllerBackgroundJobStatus, number>;
  executionsCount: number;
};
