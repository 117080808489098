import React from 'react';

import {
  formatCurrency,
  formatKwh,
  formatSessionStatus,
} from 'utils/formatting';
import { Link } from 'react-router-dom';
import { formatDateTime, formatDuration } from 'utils/date';
import { request } from 'utils/api';

import { Button, Dropdown, Icon, Table } from 'semantic';

import Session from 'components/Session';

import { useTranslation } from 'react-i18next';
import { CPO_SESSION_EXCLUDED_REASON } from 'types/sessions';
import { useCanAccess } from 'hooks/useCanAccess';
import { CpoSession } from 'types/cpo-session';
import { useSearchContext } from 'components/search/Context';
import ViewSession from 'components/modals/ViewSession';
import { Modal } from 'semantic-ui-react';
import AddSessionToAccessGroup from 'components/modals/AddSessionToAccessGroup';
import InspectObject from 'components/modals/InspectObject';
import ViewSessionCalibration from 'components/modals/ViewSessionCalibration';
import { currentUserIsSuperAdmin } from 'utils/roles';
import FeatureFlag from 'components/FeatureFlag';
import { SessionTimelineModal } from 'components/Session/TimelineModal';
import SyncLogStatus from 'components/modals/SyncLogStatus';
import EvsePaymentTerminalSessions from 'screens/SessionsCpo/EvsePaymentTerminalSessions';
import StopSession from 'components/modals/StopSession';
import ExcludeSessionMenuItem from 'components/modals/ExcludeSession';
import ResyncSession from 'components/modals/ResyncSession';
import { PaymentStatus } from 'components/cpo-payment/paymentStatus';
import DropdownMenu from '../DropdownMenu';
import { PaymentMethod } from '../cpo-payment/paymentMethod';

type SessionsTableProps = {
  internalCalculatedPrice?: boolean;
  includeResyncAction?: boolean;
  hideInspectAction?: boolean;
  includeLocation?: boolean;
  includeStopAction?: boolean;
  stopSessionForLocation?: boolean;
  hasActions?: boolean;
  showInvoiceStatus?: boolean;
  onDeleteItem?: () => void;
  addToAccessGroup?: boolean;
  linkToSessionDetail?: boolean;
  sessionDetailFromMineEndpoint?: boolean;
};

export default function SessionsTable({
  internalCalculatedPrice = false,
  includeResyncAction,
  hideInspectAction = false,
  includeLocation = false,
  includeStopAction = false,
  stopSessionForLocation = false,
  hasActions = true,
  showInvoiceStatus = false,
  onDeleteItem = undefined,
  addToAccessGroup = false,
  linkToSessionDetail = true,
  sessionDetailFromMineEndpoint = false,
}: SessionsTableProps) {
  const { t } = useTranslation();
  const { items, loading, reload } = useSearchContext<CpoSession>();

  async function excludeSession(item: CpoSession) {
    await request({
      method: 'POST',
      path: `/1/sessions/${item.id}/exclude`,
    });
    await reload();
  }

  async function deleteSession(item: CpoSession) {
    await request({
      method: 'DELETE',
      path: `/2/sessions/cpo/${item.id}`,
    });
    await reload();
  }

  async function fetchSession(id: string) {
    let path = `/2/sessions/cpo/${id}`;
    if (sessionDetailFromMineEndpoint) {
      path = `/2/sessions/cpo/mine/${id}`;
    }
    const response = await request({
      method: 'GET',
      path,
    });
    return response.data;
  }

  async function fetchSessionTimeline(id: string) {
    const result = await request({
      method: 'GET',
      path: `/2/sessions/cpo/${id}/timeline`,
    });
    return result.data;
  }

  async function fetchEvsePaymentTerminalSession({
    evsePaymentTerminalSessionId,
    sessionId,
  }: {
    evsePaymentTerminalSessionId: string;
    sessionId: string;
  }) {
    let path = `/2/sessions/cpo/evse-payment-terminal-session/${evsePaymentTerminalSessionId}`;
    if (sessionDetailFromMineEndpoint) {
      path = `/2/sessions/cpo/mine/${sessionId}/evse-payment-terminal-session`;
    }

    const result = await request({
      method: 'GET',
      path,
    });

    return result.data;
  }

  function isExcluded(item: CpoSession) {
    return (
      item.excluded &&
      ![CPO_SESSION_EXCLUDED_REASON.EXTERNAL_PAYMENT_SOLUTION].includes(
        item.excludedReason
      )
    );
  }

  // Only allow access to timeline via provider/system/global roles
  const showTimelineAction = useCanAccess({
    endpoint: 'cpoSessions',
    requiredLevel: 'read',
    allowAccountContext: false,
  });

  if (!items.length || loading) return null;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>
            {t('sessionTable.columnId', 'ID')}
          </Table.HeaderCell>
          {includeLocation && (
            <Table.HeaderCell>
              {t('sessionTable.columnLocation', 'Location')}
            </Table.HeaderCell>
          )}
          <Table.HeaderCell width={3}>
            {t('sessionTable.columnDuration', 'Duration')}
          </Table.HeaderCell>
          {!internalCalculatedPrice && (
            <>
              <Table.HeaderCell>
                {t('sessionTable.columnPriceVAT', 'Price (incl. VAT)')}
              </Table.HeaderCell>

              <Table.HeaderCell>
                {t('sessionTable.columnPriceWithOutVat', 'Price (excl. VAT)')}
              </Table.HeaderCell>
            </>
          )}
          {internalCalculatedPrice && (
            <Table.HeaderCell>
              {t('sessionTable.columnPriceTotal', 'Total')}
            </Table.HeaderCell>
          )}
          <Table.HeaderCell>
            {t('sessionTable.columnkWh', 'kWh')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('sessionTable.columnStatus', 'Session Status')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('sessionTable.columnPaymentMethod', 'Payment Method')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('sessionTable.columnPaymentStatus', 'Payment Status')}
          </Table.HeaderCell>
          {hasActions && (
            <Table.HeaderCell width={3}>
              {t('sessionTable.columnActions', 'Actions')}
            </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item) => (
          <Table.Row key={item.id}>
            <Table.Cell>
              {item.rawRecord && item.rawRecord.signedData && (
                <Icon
                  title="Calibration verification available for this session"
                  name="key"
                  style={{
                    float: 'right',
                    color: '#ccc',
                    marginTop: '2px',
                  }}
                />
              )}
              {isExcluded(item) ? (
                linkToSessionDetail ? (
                  <Link
                    to={`/charging-stations/sessions/${item.id}`}
                    style={{ textDecoration: 'line-through', opacity: 0.5 }}>
                    {item.externalId}
                  </Link>
                ) : (
                  <span style={{ textDecoration: 'line-through' }}>
                    {item.externalId}
                  </span>
                )
              ) : linkToSessionDetail ? (
                <Link to={`/charging-stations/sessions/${item.id}`}>
                  {item.externalId}
                </Link>
              ) : (
                item.externalId
              )}
            </Table.Cell>

            {includeLocation && (
              <Table.Cell>
                {item.location ? (
                  <>
                    <Link
                      to={`/charging-stations/locations/${
                        item.location.id || item.locationId
                      }`}>
                      {item.location.name}
                    </Link>{' '}
                    {item.evseController && (
                      <p>
                        <small>
                          (
                          {item.evseController.id || item.evseControllerId ? (
                            <span>
                              <Link
                                to={`/charging-stations/${
                                  item.evseController.id ||
                                  item.evseControllerId
                                }`}>
                                {item.evseController.ocppIdentity}
                              </Link>{' '}
                              #{item.connectorId}
                            </span>
                          ) : (
                            `${item.evseController.ocppIdentity} #${item.connectorId}`
                          )}
                          )
                        </small>
                      </p>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </Table.Cell>
            )}

            <Table.Cell width={3}>
              <div>{formatDuration(item.durationSeconds, t)}</div>
              <div style={{ fontSize: '10px' }}>
                {t('common.start', 'Start')}: {formatDateTime(item.startedAt)}
                <br />
                {item.endedAt ? (
                  <>
                    {t('common.end', 'End')}: {formatDateTime(item.endedAt)}
                  </>
                ) : (
                  <>
                    {t('common.end', 'End')}:{' '}
                    {t('common.inProgress', 'In progress')}
                  </>
                )}
              </div>
            </Table.Cell>

            {internalCalculatedPrice && (
              <Table.Cell textAlign="right">
                {formatCurrency(
                  (item.timeCosts ?? 0) +
                    (item.startCosts ?? 0) +
                    (item.energyCosts ?? 0),
                  {
                    currency: item.currency,
                  }
                )}
              </Table.Cell>
            )}

            {!internalCalculatedPrice && (
              <>
                <Table.Cell textAlign="right">
                  <Session.Price withVAT session={item} />
                  {showInvoiceStatus && (
                    <>
                      <br />
                      <small>
                        {item.invoiceId ||
                        item.debitInvoiceId ||
                        item.debitInvoiceIds?.length
                          ? '(invoiced)'
                          : '(uninvoiced)'}
                      </small>
                    </>
                  )}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Session.Price session={item} />
                </Table.Cell>
              </>
            )}

            <Table.Cell title={item.kwh || 0} textAlign="right">
              {item.kwh ? formatKwh(item.kwh) : '-'}
            </Table.Cell>

            <Table.Cell textAlign="center">
              {formatSessionStatus(item.status)}
            </Table.Cell>

            <Table.Cell textAlign="center">
              <PaymentMethod
                source={item.authorization?.payment?.productPaymentFlow}
                t={t}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <PaymentStatus
                status={item.authorization?.payment?.status}
                t={t}
              />
            </Table.Cell>

            {hasActions && (
              <Table.Cell textAlign="center">
                <ViewSession
                  getData={async () => fetchSession(item._id || item.id)}
                  trigger={
                    <Button
                      basic
                      icon="magnifying-glass"
                      title={t('common.viewSession', 'View Session')}
                      style={{ marginBottom: '3px' }}
                    />
                  }
                />

                <DropdownMenu text={t('sessions.dropdownMenuText', 'More')}>
                  {addToAccessGroup && item.rawRecord?.tokenVisualNumber && (
                    <AddSessionToAccessGroup
                      size="tiny"
                      visualNumber={item.rawRecord.tokenVisualNumber}
                      trigger={
                        <Dropdown.Item
                          icon="key"
                          text={t(
                            'sessionTable.addToAccessGroup',
                            'Add To Access Group'
                          )}
                        />
                      }
                    />
                  )}

                  {!hideInspectAction && (
                    <InspectObject
                      name="Session"
                      getData={() => fetchSession(item._id || item.id)}
                      trigger={
                        <Dropdown.Item
                          icon="file-code"
                          text={t('common.inspect', 'Inspect')}
                        />
                      }
                    />
                  )}

                  {showTimelineAction && (
                    <FeatureFlag feature={'advanced_session_timeline'}>
                      <SessionTimelineModal
                        getData={() =>
                          fetchSessionTimeline(item._id || item.id)
                        }
                        trigger={
                          <Dropdown.Item
                            icon="timeline"
                            text={t('common.timeline', 'Timeline')}
                          />
                        }
                      />
                    </FeatureFlag>
                  )}

                  {item.rawRecord && item.rawRecord.signedData && (
                    <ViewSessionCalibration
                      getData={() => fetchSession(item._id || item.id)}
                      trigger={
                        <Dropdown.Item
                          icon="key"
                          text={t(
                            'common.viewVerification',
                            'View Verification'
                          )}
                        />
                      }
                    />
                  )}

                  {currentUserIsSuperAdmin() && (
                    <SyncLogStatus
                      getData={() => fetchSession(item._id || item.id)}
                      modelName="session"
                      size="fullscreen"
                      showExternalRefs
                      externalRefFields={['credentialName', 'credentialIds']}
                      trigger={
                        <Dropdown.Item
                          text="Sync status"
                          icon="wand-magic-sparkles"
                        />
                      }
                    />
                  )}

                  {item.evsePaymentTerminalSessionId && (
                    <EvsePaymentTerminalSessions
                      getData={() =>
                        fetchEvsePaymentTerminalSession({
                          evsePaymentTerminalSessionId:
                            item.evsePaymentTerminalSessionId._id ||
                            item.evsePaymentTerminalSessionId,
                          sessionId: item.id,
                        })
                      }
                      size="medium"
                      trigger={
                        <Dropdown.Item
                          text={t(
                            'evsePaymentTerminalSession.showDetails',
                            'Show payment details'
                          )}
                          icon="file"
                        />
                      }
                    />
                  )}

                  {includeResyncAction && (
                    <ResyncSession
                      sessionId={item.id}
                      onDone={() => reload()}
                      onClose={() => reload()}
                      trigger={
                        <Dropdown.Item
                          icon="arrow-rotate-right"
                          text={t(
                            'sessionTable.resyncSession',
                            'Resync this session to MSP providers'
                          )}
                        />
                      }
                    />
                  )}

                  {onDeleteItem && (
                    <Modal
                      header={t(
                        'deleteDialog.header',
                        'Are you sure you want to delete {{name}}?',
                        { name: item.id }
                      )}
                      content={t(
                        'deleteDialog.content',
                        'All data will be permanently deleted'
                      )}
                      trigger={
                        <Dropdown.Item
                          icon="trash"
                          text={t('common.delete', 'Delete')}
                        />
                      }
                      closeIcon
                      actions={[
                        {
                          key: 'delete',
                          primary: true,
                          content: t('deleteDialog.button', 'Delete'),
                          onClick: async () => {
                            await deleteSession(item);
                            await reload();
                          },
                        },
                      ]}
                    />
                  )}

                  {includeStopAction && item.status === 'ACTIVE' && (
                    <StopSession
                      stopSessionForLocation={stopSessionForLocation}
                      initialValues={item}
                      onClose={() => reload()}
                      trigger={
                        <Dropdown.Item
                          icon="ban"
                          text={t(
                            'sessionTable.stopActiveSession',
                            'Stop this active session'
                          )}
                        />
                      }
                    />
                  )}

                  {currentUserIsSuperAdmin() && !item.excluded && (
                    <ExcludeSessionMenuItem
                      session={item}
                      onDone={() => reload()}
                    />
                  )}
                </DropdownMenu>
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
