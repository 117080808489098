import React from 'react';
import { Button, Modal, Form, Message } from 'semantic';
import { useTranslation } from 'react-i18next';
import { LabelItem } from '.';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { utf8ToBase64 } from '../../../utils/url';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/form-fields/formik/InputField';
import { joiErrorDetailsToObject, request } from 'utils/api';

interface LabelActionsProps {
  item: LabelItem;
  writeAccess: boolean;
  onSave: () => void;
}

export default function LabelActions({
  item,
  writeAccess,
  onSave,
}: LabelActionsProps) {
  const { t } = useTranslation();

  const handleDeleteLabel = async (item: LabelItem) => {
    await request({
      method: 'POST',
      path: `/1/labels/remove`,
      body: {
        objectType: item.objectType,
        labels: [item.label],
      },
    });
    onSave();
  };

  const handleRenameLabel = (item: LabelItem) => {
    return (formValue: { newLabel: string }, formikBag: any) => {
      return request({
        method: 'POST',
        path: `/1/labels/rename`,
        body: {
          objectType: item.objectType,
          oldLabel: item.label,
          newLabel: formValue.newLabel,
        },
      })
        .then(() => {
          formikBag.resetForm();
          onSave();
        })
        .catch((error: any) => {
          if (Array.isArray(error?.details)) {
            formikBag.setErrors(joiErrorDetailsToObject(error));
            return;
          }

          if (error?.status === 409) {
            formikBag.setStatus(
              t(
                'globalManageLabels.renameModal.error.duplicate',
                'Label "{{label}}" already exists.',
                {
                  label: formValue.newLabel,
                }
              )
            );
            return;
          }

          formikBag.setStatus(error?.message);
        });
    };
  };

  return (
    <>
      <RenameLabel
        writeAccess={writeAccess}
        onSubmitHandler={handleRenameLabel}
        item={item}
      />
      <DeleteLabel
        writeAccess={writeAccess}
        onSubmitHandler={handleDeleteLabel}
        item={item}
      />
    </>
  );
}

interface ActionModalProps {
  writeAccess: boolean;
  onSubmitHandler: any;
  item: LabelItem;
}

function DeleteLabel({ writeAccess, onSubmitHandler, item }: ActionModalProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();

  const plural = (item: LabelItem) => {
    if (item.objectType === 'evse-controller') {
      return 'stations';
    }
    if (item.objectType === 'account') {
      return 'accounts';
    }
    if (item.objectType === 'user') {
      return 'users';
    }
    throw new Error(`invalid objectType: ${item.objectType}`);
  };

  const linkRoute = (item: LabelItem) => {
    if (item.objectType === 'evse-controller') {
      return `/charging-stations?labels=${utf8ToBase64(item.label)}`;
    }
    if (item.objectType === 'account') {
      return `/accounts?labels=${utf8ToBase64(item.label)}`;
    }
    if (item.objectType === 'user') {
      return `/accounts/users?labels=${utf8ToBase64(item.label)}`;
    }
    throw new Error(`invalid objectType: ${item.objectType}`);
  };

  return (
    <Modal
      closeIcon
      open={isModalOpen}
      onOpen={() => setIsModalOpen(true)}
      onClose={() => setIsModalOpen(false)}
      trigger={
        <Button disabled={!writeAccess} basic icon="trash" title="Delete" />
      }>
      <Modal.Header>
        {t('globalManageLabels.deleteModal.title', 'Delete Label')}
      </Modal.Header>
      <Modal.Content style={{ paddingTop: '0' }}>
        <h4>
          {t(
            'globalManageLabels.deleteModal.heading',
            'Delete label "{{label}}"?',
            {
              label: item.label,
            }
          )}
        </h4>
        <p>
          {item.usage > 0
            ? t(
                'globalManageLabels.deleteModal.warning.withUsage',
                'This label will be removed from {{usage}} {{plural}}.',
                {
                  usage: item.usage,
                  plural: plural(item),
                }
              )
            : t(
                'globalManageLabels.deleteModal.warning.withoutUsage',
                'This label is not used by any {{plural}}.',
                {
                  plural: plural(item),
                }
              )}
        </p>
        <p>
          {t(
            'globalManageLabels.deleteModal.warning.description',
            'This action cannot be undone.'
          )}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} textAlign="left" verticalAlign="middle">
              <Link
                to={linkRoute(item)}
                target="_blank"
                title={t(
                  'globalManageLabels.deleteModal.viewAssignedObjects',
                  'View assigned {{plural}}',
                  {
                    plural: plural(item),
                  }
                )}
                style={{
                  marginRight: 'auto',
                  color: 'var(--primary-color)',
                  fontWeight: 'bold',
                }}>
                {t(
                  'globalManageLabels.deleteModal.viewAssignedObjects',
                  'View assigned {{plural}}',
                  {
                    plural: plural(item),
                  }
                )}
              </Link>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <div>
                <Button
                  basic
                  content={t('common.cancel', 'Cancel')}
                  onClick={() => setIsModalOpen(false)}
                />

                <Button
                  disabled={!writeAccess}
                  content={t('common.confirm', 'Confirm')}
                  onClick={() => {
                    onSubmitHandler(item);
                    setIsModalOpen(false);
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
}

function RenameLabel({ writeAccess, onSubmitHandler, item }: ActionModalProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();

  const VALIDATION_SCHEMA = Yup.object({
    newLabel: Yup.string().required(),
  });

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={onSubmitHandler(item, setIsModalOpen)}
        validationSchema={VALIDATION_SCHEMA}
        initialValues={{
          newLabel: item.label,
        }}>
        {({ values, dirty, status, isSubmitting, handleSubmit, isValid }) => {
          return (
            <Modal
              closeIcon
              open={isModalOpen}
              onOpen={() => setIsModalOpen(true)}
              onClose={() => {
                setIsModalOpen(false);
                values.newLabel = '';
              }}
              trigger={
                <Button
                  disabled={!writeAccess}
                  basic
                  icon="pen-to-square"
                  title="Rename"
                />
              }>
              <Modal.Header>
                {t(
                  'globalManageLabels.renameModal.title',
                  'Rename Label "{{label}}"',
                  {
                    label: item.label,
                  }
                )}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <InputField
                    name={`newLabel`}
                    label={t(
                      'globalManageLabels.renameModal.title',
                      'New Label'
                    )}
                    type="text"
                    min={0}
                  />
                </Form>
                {status && (
                  <Message error>
                    <p>{status}</p>
                  </Message>
                )}
                <Message
                  info
                  content={t(
                    'globalManageLabels.renameModal.info',
                    'Please ensure that your label name is unique and does not exactly match any existing labels. '
                  )}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  basic
                  content={t('common.cancel', 'Cancel')}
                  onClick={() => {
                    setIsModalOpen(false);
                    values.newLabel = '';
                  }}
                />
                <Button
                  as="button"
                  loading={isSubmitting}
                  role="button"
                  disabled={!writeAccess || isSubmitting || !isValid || !dirty}
                  primary
                  content={t('common.confirm', 'Confirm')}
                  onClick={handleSubmit}
                />
              </Modal.Actions>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}
